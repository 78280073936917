import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, AfterContentChecked, Renderer2, ViewChild, AfterViewInit, Input, SimpleChange, OnChanges } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatCalendar, MatDatepickerInputEvent } from '@angular/material';
import { ReportingService } from '../../reporting/reporting.service';
import { Moment } from 'moment';
import { DateProvider } from '../../_common/common-classes/date-provider';
import { Calendar } from '../../_common/common-classes/calendar';


/*
 * Aquí solo se trata de crear una solicitud de periodo vacacional/asuntos propios
 * Para el propio usuario.
 */
@Component({
  selector: 'app-calendario-vacaciones',
  templateUrl: './calendario-vacaciones.component.html',
  styleUrls: ['./calendario-vacaciones.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarioVacacionesComponent implements OnInit, AfterContentChecked, AfterViewInit {
  @Input() public cardClass; 
  @ViewChild('calendar') calendar: MatCalendar<Moment>;
  private selectedMonth: string;
  private selectedYear: number;
  public dateProvider = new DateProvider();
  public allDays: Calendar;
  public years: number[] = [];

  constructor(
    private _router: Router,
    private changeDetectionRef: ChangeDetectorRef,
    private _reportingService: ReportingService,
    private _renderer: Renderer2
  ) {
    this.dateProvider.setMinMaxDates()
    if (!this.cardClass)
      this.cardClass = 'inline-calendar-card'
  }

  ngOnInit() {
    this.getVacaciones();
  }

  ngAfterContentChecked(): void {
    this.changeDetectionRef.detectChanges();
  }

  ngAfterViewInit() {
    const monthPrevBtn = document.querySelectorAll('.mat-calendar-previous-button');
    const monthNextBtn = document.querySelectorAll('.mat-calendar-next-button');

    if (monthPrevBtn) {
      Array.from(monthPrevBtn).forEach((button) => {
        this._renderer.listen(button, 'click', (event) => {
          this.setCurrentMonthAndYear(null);
          this.updateCalendar();
        });
      });
    }

    if (monthNextBtn) {
      Array.from(monthNextBtn).forEach((button) => {
        this._renderer.listen(button, 'click', (event) => {
          this.setCurrentMonthAndYear(null);
          this.updateCalendar();
        });
      });
    }

    //this.updateCalendar();
  }

  public goHome() {
    this._router.navigateByUrl('/');
  }

  private setCurrentMonthAndYear(date) {
    if (date) {
      // Si viene desde handleMonthSelected, tenemos fecha.
      this.selectedMonth = `${date.getMonth() + 1}`.padStart(2, '0')
      this.selectedYear = date.getFullYear();

    } else {
      // Necesitamos actualizar mes y año para iterarlos.
      const matCalendarCard = document.querySelector('.mat-calendar .mat-calendar-controls .mat-calendar-period-button .mat-button-wrapper');
      if (matCalendarCard && matCalendarCard.textContent) {
        this.dateProvider.fromMonthAndYear(matCalendarCard.textContent);
        this.selectedYear = this.dateProvider.year;
        this.selectedMonth = this.dateProvider.month;
      }
    }
    this.getHolidaysFromSelectedYear();
  }

  public updateCalendar(date = null) {
    if (!this.allDays)
      return

    /* Iteramos los días del calendario para mostrar días/horas libres y festivos. */
    setTimeout(() => {
      const cells = Array.from(document.querySelectorAll<HTMLDivElement>('.mat-calendar .mat-calendar-body-cell-content'));
      cells.forEach(c => {
        let currentDay = c.innerText.padStart(2, '0')

        if (currentDay.length > 2)
          currentDay = currentDay.substring(0,2)
          //return;

        let data = this.allDays.calDates.find(f => f.date == `${this.selectedYear}-${this.selectedMonth}-${currentDay}`)
        let counter = data == null ? 0 : data.workers.length;
        let workers = data == null ? [] : data.workers.map(w => w);
        let workerList = data == null ? '' : workers.concat(workers.splice(-2, 2).join(' y ')).join(', ');

        if (workerList && workerList.includes('y I'))
          workerList = workerList.replace('y I', 'e I')

        c.innerText = currentDay;

        if (counter > 0)
          c.className += ' special-date';

        if (data && data.isHoliday)
          c.className += ' holiday-date';

        if (workerList)
          c.innerHTML = `<div class="row"><div class="col-12" style="font-size:1.5em;">${currentDay}</div><div class="col-12" title="${workerList}">${counter}</div></div>`;
        else
          c.innerHTML = `<div class="row"><div class="col-12" style="font-size:1.5em;">${currentDay}</div><div class="col-12">${counter}</div></div>`;
      });
    });
  }

  dateFilter = (moment: any): boolean => {
    const day = moment._d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  handleMonthSelected(evt) {
    this.setCurrentMonthAndYear(evt._d)
    this.updateCalendar(evt._d);
  }

  public goToDateInView(m) {
    this.calendar._goToDateInView(m, 'month')
  }

  private getHolidays(year: number, init: boolean = true) {
    if (year && !this.years.includes(year)) {
      this.years.push(year);
      let data = {
        fechaDesde: new Date(Date.UTC(year, 0, 1)),
        fechaHasta: new Date(Date.UTC(year, 11, 31, 23, 59, 59, 999))
      }
      this._reportingService.empleadosVacaciones(data).subscribe((data: any) => {
        if (init) {
          this.allDays = new Calendar(data);
          this.setCurrentMonthAndYear(null)
          this.updateCalendar();
        } else {
          this.allDays.addDates(data);
        }
      });
    }
  }

  private getVacaciones() {
    let today = new Date();
    this.getHolidays(today.getUTCFullYear())
  }

  private getHolidaysFromSelectedYear() {
    let year = this.selectedYear
    this.getHolidays(year, false)
  }
}
