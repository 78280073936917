import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup } from '@angular/forms';
import { TrabajadoresService } from '../trabajadores.service';

@Component({
  selector: 'app-departamentos-selector',
  templateUrl: './departamentos-selector.component.html',
  styleUrls: ['./departamentos-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DepartamentosSelectorComponent),
      multi: true
    }
  ]
})
export class DepartamentosSelectorComponent implements OnInit, ControlValueAccessor {
  public elementos;
  public _valorPropiedad;
  @Input() valorPropiedad: number;
  @Output() valorPropiedadChange = new EventEmitter();
  isDisabled: boolean;

  constructor(private _trabajadoresService : TrabajadoresService) { }

  ngOnInit() {
    this.getElementos();
  }

  getElementos() {
    this._trabajadoresService.getDepartamentos().subscribe((data) => {
      this.elementos = data;
    })
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.valorPropiedad = obj;
      this._valorPropiedad = obj;
    }
  }

  propagateChange = (_: any) => { };
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  onTouch = () => { }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  seleccionaValor() {
    this.valorPropiedadChange.emit(this.valorPropiedad); //Para bindar con ngModel y Output
    this.propagateChange(this.valorPropiedad);
    this.onTouch();
  }

  displayFn(elemento) {
    var rtn = '';
    if (this.elementos) {
      var el = this.elementos.find(x => x.id == elemento);
      if (el)
        rtn = el.nombre;
    }
    return rtn;
  }

}
