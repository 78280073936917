import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TenantService, TenantProvider } from '../_conf/tenant.service';

@Injectable({
  providedIn: 'root'
})
export class TrabajadoresService {
  readonly rootUrl = `${environment.apiUrl}api/empleados`;
  readonly rootUrlCalendario = `${environment.apiUrl}api/calendarios`;

  constructor(
    private _http: HttpClient,
    private _tenant: TenantProvider
  ) { }

  tenantUsesDocAsPass() {
    const tenant = this._tenant.getTenant()
    return environment.useDocAsPass.includes(tenant)
  }

  tenantUsesPinID() {
    const tenant = this._tenant.getTenant()
    return !environment.useDocAsPass.includes(tenant)
  }

  getWorkers() {
    let url = `${this.rootUrl}/trabajadores`;
    return this._http.get(url);
  }

  getAllWorkers() {
    let url = `${this.rootUrl}/todos`;
    return this._http.get(url);
  }

  getNewUserId() {
    let url = `${this.rootUrl}/last-user-id`
    return this._http.get(url);
  }

  getFicha(id) {
    var url = `${this.rootUrl}/ficha/${id}`;
    return this._http.get(url);
  }

  altaEmpleado(data) {
    var url = `${this.rootUrl}/alta`;
    return this._http.post(url, data);
  }

  altaEmpleados(data) {
    var url = `${this.rootUrl}/altas`;
    return this._http.post(url, data);
  }

  borrarEmpleado(data) {
    var url = `${this.rootUrl}/borrarEmpleado`;
    return this._http.post(url, data);
  }

  getSolicitudes(workerId: number) {
    // Si no viene con workerId nos traemos todas las solicitudes
    var tail = 'solicitudesEmpleado';
    if (workerId)
      tail += `/${workerId}`;

    return this._http.get(`${this.rootUrl}/${tail}`);
  }

  getSolicitudesPropias() {
    var url = `${this.rootUrl}/solicitudes-propias`
    return this._http.get(url);
  }

  obtenerSolicitudesDisponibles(empleado: any) {
    var url = `${this.rootUrl}/solicitudes-disponibles`
    return this._http.post(url, empleado);
  }

  obtenerTodasLasSolicitudesDisponibles() {
    var url = `${this.rootUrl}/solicitudes-disponibles-todas`
    return this._http.get(url);
  }

  obtenerSolicitudesDiasParciales(empleado: any) {
    var url = `${this.rootUrl}/solicitudes-dias-parciales`
    return this._http.post(url, empleado);
  }

  getSolicitud(id) {
    var url = `${this.rootUrl}/solicitudEmpleado/${id}`;
    return this._http.get(url);
  }

  getTipados(clave) {
    var url = `${this.rootUrl}/tipados/${clave}`;
    return this._http.get(url);
  }

  altaSolicitud(data) {
    var url = `${this.rootUrl}/altaSolicitud`;
    return this._http.post(url, data);
  }

  actualizarSolicitud(data) {
    var url = `${this.rootUrl}/actualizarSolicitud`;
    return this._http.post(url, data);
  }

  getMesEmpleado(anyo,mes) {
    var url = `${this.rootUrl}/mesEmpleado/${anyo}/${mes}`;
    return this._http.get(url);
  }

  getDepartamentos() {
    var url = `${this.rootUrl}/departamentos`;
    return this._http.get(url);
  }

  updateDepartamento(data) {
    var url = `${this.rootUrl}/updateDepartamento`;
    return this._http.post(url, data);
  }

  getRolesEmpleado(id) {
    var url = `${this.rootUrl}/roles-empleado/${id}`;
    return this._http.get(url);
  }

  actualizarRol(data) {
    var url = `${this.rootUrl}/asignaRol`;
    return this._http.post(url, data);
  }

  getHorarioDia(data) {
    var url = `${this.rootUrlCalendario}/getHorarioDia`;
    return this._http.post(url,data);
  }


  getDatosEmpresa() {
    var url = `${this.rootUrl}/getDatosEmpresa`;
    return this._http.get(url);
  }

  updateDatosEmpresa(data) {
    var url = `${this.rootUrl}/updateDatosEmpresa`;
    return this._http.post(url, data);
  }
  getCentrosTrabajo() {
    var url = `${this.rootUrl}/getCentrosTrabajo`;
    return this._http.get(url);
  }

  updateCentroTrabajo(data) {
    var url = `${this.rootUrl}/updateCentroTrabajo`;
    return this._http.post(url, data);
  }

  deleteCentroTrabajo(data) {
    var url = `${this.rootUrl}/deleteCentroTrabajo`;
    return this._http.post(url, data);
  }
}
