import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CalendariosService {
  readonly rootUrl = `${environment.apiUrl}api/calendarios`;

  constructor(private _http: HttpClient) { }

  getCalendarios() {
    let url = `${this.rootUrl}/calendarios`;
    return this._http.get(url);
  }

  getCalendario(id, fechaDesde, fechaHasta) {
    var url = `${this.rootUrl}/calendario`;
    var data = { id: id, fechaDesde: fechaDesde, fechaHasta: fechaHasta };
    return this._http.post(url, data);
  }

  getHorarios(id) {
    var url = `${this.rootUrl}/horarios/${id}`;
    return this._http.get(url);
  }

  updateHorario(data) {
    var url = `${this.rootUrl}/updateHorario`;
    return this._http.post(url, data);
  }

  borrarHorario(data) {
    var url = `${this.rootUrl}/borrarHorario`;
    return this._http.post(url, data);
  }

  updateFestivo(data) {
    var url = `${this.rootUrl}/updateFestivo`;
    return this._http.post(url, data);
  }

  borrarFestivo(data) {
    var url = `${this.rootUrl}/borrarFestivo`;
    return this._http.post(url, data);
  }

  copyCalendar(data) {
    var url = `${this.rootUrl}/clone-calendar`;
    return this._http.post(url, data);
  }

  updateCalendar(data) {
    var url = `${this.rootUrl}/update-calendar`;
    return this._http.post(url, data);
  }

  deleteCalendar(data) {
    var url = `${this.rootUrl}/delete-calendar`;
    return this._http.post(url, data);
  }

  toggleStatus(data) {
    var url = `${this.rootUrl}/toggle-calendar-status`;
    return this._http.post(url, data);
  }
}
