import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-projects-selector',
  templateUrl: './proyectos-selector.component.html',
  styleUrls: ['./proyectos-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectSelectorComponent),
      multi: true
    }
  ]
})
export class ProjectSelectorComponent implements OnInit, ControlValueAccessor {
  public projects;
  public projectFilter;
  private projectIds: number[];
  public _project;
  @Input() project: number;
  @Output() projectChange = new EventEmitter();
  public isDisabled: boolean;

  constructor(
    private _projectService: ProjectService
  ) { }

  ngOnInit() {
    this.projectIds = [];
    this.getProjects();
  }

  getProjects() {
    return this._projectService.getAvailableProjectList().subscribe((data: any) => {
      this.projects = data;
      this.projectFilter = data;
      data.forEach(project => this.projectIds.push(project.id))
    });
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.project = obj;
      this._project = obj;
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  onTouch = () => { }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  selectProject() {
    var selectedProject = null
    if (this.projectIds.includes(this.project)) {
      selectedProject = this.project
    } else if (this.project) {
      this.projectFilter = this.filter(`${this.project}`)
    } else {
      // this.project es cadena vacía
      this.project = null;
      this.projectFilter = this.projects;
    }
    this.projectChange.emit(selectedProject); //Para bindar con ngModel y Output
    this.propagateChange(this.project);
    this.onTouch();
  }

  private toLcTrim(worker: string): string {
    return worker.toLowerCase().trim();
  }

  private filter(value: string): string[] {
    const filterTerm = this.toLcTrim(value);

    return this.projects.filter(proj => this.toLcTrim(proj.nombre).includes(filterTerm));
  }

  displayFnProject(project) {
    var rtn = '';
    if (this.projects) {
      let proj = this.projects.find(x => x.id == project);
      if (proj)
        rtn = proj.nombre;
    }
    return rtn;
  }
}
