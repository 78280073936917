import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  readonly rootUrl = `${environment.apiUrl}api/import`;

  constructor(private _http: HttpClient) { }

  import(data) {
    var url = this.rootUrl;
    //var headers = { 'Content-Type': undefined };

    return this._http.post(url, data);
  }

}
