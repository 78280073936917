import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-test-excel-mat',
  templateUrl: './test-excel-mat.component.html',
  styleUrls: ['./test-excel-mat.component.css']
})
export class TestExcelMatComponent implements OnInit {

  public columns = ["name", "position", "description"];
  public ds: TableModel[];
  constructor() { }

  ngOnInit() {

    this.ds = [
      {
        name: "prueba",
        description: "prueba2",
        position : 2
      }, {
        name: "pruebalinea2",
        description: "pruebalinea3",
        position:3
      }
    ];

  }



}

export interface TableModel {
  name: string,
  position: number;
  description: string;
}
