import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { TrabajadoresService } from '../trabajadores.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-empleados-selector',
  templateUrl: './empleados-selector.component.html',
  styleUrls: ['./empleados-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmpleadosSelectorComponent),
      multi: true
    }
  ]
})
export class EmpleadosSelectorComponent implements OnInit,ControlValueAccessor {
  public workers;
  public filteredWorkers;
  private workerIds: number[] = [];
  @Input() public canModifyWorker: boolean;
  @Input() public workerId: number;
  @Input() public required: boolean;
  @Output() workerChange = new EventEmitter();
  private disableWorker: boolean = false;
  public isDisabled: boolean;

  constructor(private _trabajadoresService: TrabajadoresService) { }

  ngOnInit() {
    if (!this.canModifyWorker) {
      this.canModifyWorker = true
      this.required = false
    }
    this.getWorkerIds();
    this.disableWorker = this.workerId === undefined
  }

  getWorkerIds() {
    return this._trabajadoresService.getWorkers().subscribe((data: any) => {
      this.workers = data;
      this.filteredWorkers = data;
      data.forEach(worker => this.workerIds.push(worker.id))
    });
  }

  writeValue(obj: any): void {
    if (obj !== null) {
      this.workerId = obj;
      this.disableWorker = false
    } else {
      this.workerId = null
      this.disableWorker = false
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  onTouch = () => { }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  selectWorker() {
    var selectedWorker = null
    if (this.workerIds.includes(this.workerId)) {
      selectedWorker = this.workerId
    } else if (this.workerId) {
      this.filteredWorkers = this.filter(`${this.workerId}`)
    } else {
      // empleado está vacío
      this.workerId = null;
      this.filteredWorkers = this.workers;
    }

    this.workerChange.emit(selectedWorker); //Para bindar con ngModel y Output
    this.propagateChange(selectedWorker);
    this.onTouch();
  }

  private toLcTrim(worker: string): string {
    return worker.toLowerCase().trim();
  }

  private filter(value: string) {
    const filterTerm = this.toLcTrim(value);

    return this.workers.filter(w => `${this.toLcTrim(w.nombre)} ${this.toLcTrim(w.apellido1)} ${this.toLcTrim(w.apellido2)}`.includes(filterTerm));
  }

  public enableWorker() {
    this.disableWorker = false;
  }

  public checkWorker() {
    if (this.canModifyWorker)
      return
    if (this.workerId !== null && typeof this.workerId !== 'undefined') {
      this.disableWorker = false;
    }
  }

  displayFnWorker(workerId) {
    var rtn = '';
    if (this.workers) {
      let worker = this.workers.find(x => x.id == workerId);
      if (worker)
        rtn = `${worker.nombre} ${worker.apellido1} ${worker.apellido2}`;
    }
    return rtn;
  }
}
