import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  readonly rootUrl = `${environment.apiUrl}api/proyectos`;

  constructor(private _http: HttpClient) { }

  getCustomerList() {
    let url = `${this.rootUrl}/lista-clientes`;
    return this._http.get(url);
  }

  getAvailableCustomerList() {
    let url = `${this.rootUrl}/lista-clientes-disponibles`;
    return this._http.get(url);
  }

  getCustomerCard(id: number) {
    var url = `${this.rootUrl}/ficha-cliente/${id}`;
    return this._http.get(url);
  }

  addCustomer(cust: any) {
    var url = `${this.rootUrl}/alta-cliente`;
    return this._http.post(url, cust);
  }

  deleteCustomer(cust: any) {
    var url = `${this.rootUrl}/borrar-cliente`;
    return this._http.post(url, cust);
  }
}
