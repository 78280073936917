import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from '../configuracion.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-configuracion',
  templateUrl: './configuracion.component.html',
  styleUrls: ['./configuracion.component.css']
})
export class ConfiguracionComponent implements OnInit {
  public configuracion: any;
  public calendarios: any;
  public configuracionFormGroup: FormGroup;
  public camposConfiguracion: Array<any>;
  private horasJornadas: any;
  private _snackbarConfig: MatSnackBarConfig;

  constructor(
    private _configuracionService: ConfiguracionService,
    private _formBuilder: FormBuilder,
    private _matSnackBar: MatSnackBar,
    private _router: Router
  ) {
    this._snackbarConfig = new MatSnackBarConfig();
    this._snackbarConfig.horizontalPosition = 'right';
    this._snackbarConfig.verticalPosition = 'top';
    this._snackbarConfig.duration = 3000;
    this._snackbarConfig.panelClass = ['error-snackbar'];
  }

  ngOnInit() {

    this.configuracionFormGroup = this._formBuilder.group({
      calendario: new FormControl({ value: 0 }, Validators.required),
      diasCambioFichajes: new FormControl({ value: 0 }),
      horasJornadas: new FormControl({value: 0}),
      diasSolicitudesVacaciones: new FormControl({value: 0})
    });

    this._configuracionService.get().subscribe((data: any) => {
      this.configuracion = data;
      this.calendarios = data.calendarios;

      this.configuracionFormGroup.setValue({
        calendario: data.calendario,
        diasCambioFichajes: data.diasCambioFichajes,
        horasJornadas: data.horasJornadas,
        diasSolicitudesVacaciones: data.diasSolicitudesVacaciones
      });

      // this.configuracionFormGroup = new FormGroup({});
      var campos: Array<any> = data.camposConfiguracion;
      campos.forEach((element) => {
        this.configuracionFormGroup.addControl(element.nombre, new FormControl({ value: element.valor }));
      });

      this.camposConfiguracion = campos;

    });
  }

  displayFnCalendario(calendario) {
    if (this.calendarios) {
      var c = this.calendarios.filter(x => x.id == calendario)[0];
      return c.descripcion;
    }
    else {
      return "";
    }
  }

  onSubmit() {
    var data = this.configuracionFormGroup.getRawValue();

    this._configuracionService.updateConfiguracion(data).subscribe((data) => {
      this._matSnackBar.open("Configuración Actualizada", '');
      this._router.navigate(['']);
    }, (error) => {
      this.showError("Error al enviar la solicitud");
    });


  }
  cancelar() {
    this._router.navigate(['']);
  }

  private showError(msg: string) {
    this._matSnackBar.open(msg, 'Error', this._snackbarConfig);
  }

  public checkHours() {
    let hJorn = this.configuracionFormGroup.controls.horasJornadas;
    this.horasJornadas = hJorn.value;

    if (!hJorn.value || hJorn.value > 16) {
      this.showError('Horas Jornada debe ser un entero entre 1 y 16.');
    }
  }
}
