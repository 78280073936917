import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-as-confirm',
  templateUrl: './as-confirm.component.html',
  styleUrls: ['./as-confirm.component.css']
})
export class AsConfirmComponent implements OnInit {

  public titulo;
  public mensaje;
  constructor(@Inject(MAT_DIALOG_DATA) private data : any) { }

  ngOnInit() {
    this.titulo = this.data.titulo;
    this.mensaje = this.data.mensaje;
  }

}
