import { Component, OnInit ,ViewEncapsulation} from '@angular/core';
import { CalendariosService } from '../calendarios.service';
import { ActivatedRoute, Params } from '@angular/router';
import { CalendarEvent } from 'calendar-utils';
import { CalendarEventAction, CalendarView, DAYS_OF_WEEK, CalendarMonthViewBeforeRenderEvent, CalendarDateFormatter } from 'angular-calendar';

import { parseISO } from 'date-fns/esm';
import { CalendarEventActionsComponent } from 'angular-calendar/modules/common/calendar-event-actions.component';
import { Subject } from 'rxjs';
import { CustomDateFormatter } from 'src/app/_common/custom-date-formatter/custom-date-formatter.provider';
import { AsSpinnerService } from '../../_common/common-components/as-spinner/as-spinner.service';

@Component({
  selector: 'app-calendarios-ficha',
  encapsulation:ViewEncapsulation.None,
  templateUrl: './calendarios-ficha.component.html',
  styleUrls: ['./calendarios-ficha.component.css'],
  providers: [{provide : CalendarDateFormatter,useClass:CustomDateFormatter  }    ]
})
export class CalendariosFichaComponent implements OnInit {

  public calendarioFicha;
  public calendarioId;
  public calendarioHorarios;
  public calendarioTipos;
  public calendarioDias;
  public calendarioDiasEspeciales;
  public calendarioPeriodicidadesFestivos;
  public meses;
  public calendarioDetalle: Array<any>;
  public refresh: Subject<any> = new Subject();
  public mesesArray = [{
    mes: 'Enero',
    numero: 1,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 0, 1)
  }, {
    mes: 'Febrero',
      numero: 2,
      eventos: [],
      fecha: new Date(new Date().getFullYear(), 1, 1)
  },
  {
    mes: 'Marzo',
    numero: 3,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 2, 1)

    }, {
      mes: 'Abril',
      numero: 4,
      eventos: [],
      fecha : new Date(new Date().getFullYear(),3,1)

    },{
      mes: 'Mayo',
      numero: 5,
      eventos: [],
      fecha: new Date(new Date().getFullYear(), 4, 1)
    }, {
      mes: 'Junio',
      numero: 6,
      eventos: [],
      fecha: new Date(new Date().getFullYear(), 5, 1)
    },
    {
      mes: 'Julio',
      numero: 7,
      eventos: [],
      fecha: new Date(new Date().getFullYear(), 6, 1)
    }, {
      mes: 'Agosto',
      numero: 8,
      eventos: [],
      fecha: new Date(new Date().getFullYear(), 7, 1)
    }, {
      mes: 'Septiembre',
      numero: 9,
      eventos: [],
      fecha: new Date(new Date().getFullYear(), 8, 1)
    }, {
      mes: 'Octubre',
      numero: 10,
      eventos: [],
      fecha: new Date(new Date().getFullYear(),9, 1)
    },
    {
      mes: 'Noviembre',
      numero: 11,
      eventos: [],
      fecha: new Date(new Date().getFullYear(), 10, 1)
    }, {
      mes: 'Diciembre',
      numero: 12,
      eventos: [],
      fecha: new Date(new Date().getFullYear(),11, 1)
    }
  ];

  public starDayOfWeek: number = DAYS_OF_WEEK.MONDAY;
  public weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  public actions: CalendarEventAction[];

  constructor(
    private _calendariosService: CalendariosService,
    private _activatedRoute: ActivatedRoute,
    private _asSpinner: AsSpinnerService
  ) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.calendarioId = params.id;
      if (this.calendarioId) {
        this.getCalendario(this.calendarioId);
    
      }
    })
    
  }

  public clickDia(dia) {
    console.log(dia);
  }

  beforeViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach((day) => {
      var numEventos = day.events.length;
      if (numEventos > 0) {
        day.cssClass = 'colorRojo';
      }
    });
  }


  getCalendario(id) {
    this._asSpinner.enable()
    this._calendariosService.getCalendario(id, new Date(2022, 0, 1), new Date(2022, 11, 31)).subscribe((data: any) => {
      this.calendarioFicha = data.calendarioTree;
      this.calendarioHorarios = data.horarios;
      this.calendarioTipos = data.horariosTipos;
      this.calendarioDias = data.diasSemana;
      this.calendarioDetalle = data.calendarioDetalle;
      this.calendarioDiasEspeciales = data.diasEspeciales;
      this.calendarioPeriodicidadesFestivos = data.periodicidadesFestivos;

      this.mesesArray.forEach((value) => {
        var eventosMes = this.calendarioDetalle.filter((calendario) => {
          return calendario.mes === value.numero && calendario.festivo === true;
        });

        eventosMes.forEach((dia) => {
          let ev: CalendarEvent = {
            start: parseISO(dia.fecha),
            //end: val.fecha,
            title: dia.comentario,
            color: {
              primary: '#e3bc08',
              secondary: '#FDF1BA'
            },
            actions: this.actions,
            allDay: true
          };
          value.eventos.push(ev);
        });
      });
      this.refresh.next();
      this._asSpinner.disable()
    });
  }
  refreshFicha() {
    this.getCalendario(this.calendarioId);
  }
}
