import { Component, OnInit, Inject } from '@angular/core';
import { FichajesService } from '../fichajes.service';
import { Router } from '@angular/router';
import { AsSpinnerService } from '../../_common/common-components/as-spinner/as-spinner.service';
import { UserService } from '../../users/services/user.service'
import { MatDialog, MatSnackBar, MatDialogRef, MatSnackBarConfig } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FichajeContraProyectoYTareaComponent } from '../fichaje-contra-proyecto-y-tarea/fichaje-contra-proyecto-y-tarea.component';
import { ProjectService } from '../../proyectos/services/project.service';
import { Locator } from '../../_common/common-classes/common-punchings';


@Component({
  selector: 'app-fichaje',
  templateUrl: './fichaje.component.html',
  styleUrls: ['./fichaje.component.css']
})
export class FichajeComponent implements OnInit {
  public timeline: any;
  public fichajesDia: any;
  public ultimoFichaje;
  public mustLogIntoProject: boolean;
  public projects: any;
  public selectedProject: string;
  public selectedTask: string;
  public dialogRef: MatDialogRef<FichajeContraProyectoYTareaComponent>;
  public enablePunchingButton: boolean = false;
  private geoloc = new Locator();


  constructor(
    private _fichajesService: FichajesService,
    private _userService: UserService,
    private _projectService: ProjectService,
    private _spinnerService: AsSpinnerService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialog
  ) { }

  ngOnInit() {
    this.selectedProject = '';
    this.selectedTask = '';
    this._projectService.getAvailableProjectList().subscribe((projects: any) => {
      // projects = projects.filter(project => project.estado < 2)
      this.projects = projects
    })

    this._fichajesService.mustLoginIntoProject().subscribe((users: any) => {
      let bool = false
      let user = users.find(user => user.id)
      if (user.ficharContraProyectos)
        bool = user.ficharContraProyectos
      this.mustLogIntoProject = bool;
    })

    this._fichajesService.getUltimoFichaje().subscribe((data) => {
      this.ultimoFichaje = data;
      this.enablePunchingButton = true;
    });

    this._fichajesService.getFichajesDia().subscribe((data) => {
      this.fichajesDia = data;
    });

   }

  private showMsg(msg, type = '', panelClass = 'error-snackbar') {
    let sbConfig = new MatSnackBarConfig();
    sbConfig.horizontalPosition = 'right';
    sbConfig.verticalPosition = 'top';
    sbConfig.duration = 3000;
    sbConfig.panelClass = [panelClass];
    this._matSnackBar.open(msg, type, sbConfig);
  }

  private punch() {
    var outData = {
      lng: this.geoloc.longitude,
      lat: this.geoloc.latitude,
      device: this.geoloc.device,
      project: this.selectedProject,
      task: this.selectedTask
    }
    this._fichajesService.fichar(outData).subscribe(
      (inData: any) => {
        let prep = inData.entradaId == null ? 'in' : 'out';
        this.showMsg(`Fichaje de ${prep == 'in' ? 'Entrada' : 'Salida'} Registrado`, '', `${prep}coming-snackbar`)
        console.info("INDATA", inData)  
        this.ultimoFichaje = inData;
        this._userService.logout();
        this._router.navigateByUrl('/login');
      },
      (error) => {
          this.showMsg('Ocurrió un error', 'Error')
      },
      () => {
      }
    );
  }

  public fichar() {
    if (this.geoloc.error)
      this.showMsg(this.geoloc.message, this.geoloc.type);

    let era_entrada = this.ultimoFichaje && this.ultimoFichaje.tipo == 0
    let era_salida = this.ultimoFichaje && this.ultimoFichaje.tipo == 1

    if (this.mustLogIntoProject && era_salida) {
      // Vamos a fichar entrada, el usuario debe seleccionar contra qué proyecto ficha.
      this.openProjectDialog();

    } else if (this.mustLogIntoProject && era_entrada) {
      // Vamos a fichar salida, cogemos proyecto de fichaje de entrada.
      this.selectedProject = this.ultimoFichaje.proyecto;
      this.selectedTask = this.ultimoFichaje.tarea;
      this.punch();

    } else if (this.mustLogIntoProject && !this.hayUltimoFichaje) {
      // Se trata de su primer fichaje.
      this.openProjectDialog();

    } else {
      // El usuario no tiene que fichar contra proyectos.
      // Los valores por defecto de proyecto y tarea están
      // asignados en el servicio.
      this.checkProject();
      this.punch();
    }
  }

  private openProjectDialog() {
    let inData = { selectedProject: null, selectedTask: null, buttonName: 'FICHAR' }
    this.dialogRef = this._matDialog.open(FichajeContraProyectoYTareaComponent, {
      data: inData,
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      closeOnNavigation: true
    });

    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.selectedProject = data.selectedProject;
        this.selectedTask = data.selectedTask;
        this.punch()
      }
    })
  }

  public checkProject() {
    // Miramos si el proyecto tiene tarea principal para asignarla automáticamente.
    // Similar a método chooseProject de fichaje-contra-proyecto.component.ts
    if (!this.selectedProject) {
      this.selectedProject = '1';
    }
    let project = this.projects.find(proj => proj.id == parseInt(this.selectedProject))
    if (project)
      this.selectedTask = `${project.tareaPrincipal}`;
  }


  public get hayUltimoFichaje() {
      return this.ultimoFichaje != null;
  }

  public get verEntrada() {
    return this.ultimoFichaje == null || (this.ultimoFichaje && this.ultimoFichaje.tipo === 0);
  }

  public getProject() {
    let project = this.projects && this.ultimoFichaje ?
      this.projects.find(proj => proj.id == this.ultimoFichaje.proyecto) :
      this.mustLogIntoProject ? 'proyecto no disponible' : '';

    return project && typeof (project) != 'string' && project.nombre ? ` • ${project.nombre}` : ''
  }

  public getButtonContent(): string {
    if (!this.enablePunchingButton)
      return 'CARGANDO DATOS...'
    return 'FICHAR ENTRADA'
  }

  public getClass(): string {
    if (this.enablePunchingButton)
      return 'orange-500-bg'
    return 'red-400-bg'
  }

 }
