import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-empleados-importar',
  templateUrl: './empleados-importar.component.html',
  styleUrls: ['./empleados-importar.component.css']
})
export class EmpleadosImportarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  handleFileInput(files: FileList) {
    debugger;
  }
}
