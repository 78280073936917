import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';
import { MatTableExporterModule } from 'mat-table-exporter';

import { ReportEmpleadosHorasComponent } from './report-empleados-horas/report-empleados-horas.component';
import { Routes, RouterModule } from '@angular/router';
import { TrabajadoresModule } from '../trabajadores/trabajadores.module';
import { ProyectosModule } from '../proyectos/proyectos.module';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { ReportEmpleadosVacacionesComponent } from './report-empleados-vacaciones/report-empleados-vacaciones.component';
import { SharedModule } from '../shared.module';
import { MAT_DATE_LOCALE } from '@angular/material';



const routes: Routes = [
  {
    path: 'empleados-horas-filtros',
    component: ReportEmpleadosHorasComponent
  }, {
    path: 'empleados-vacaciones',
    component: ReportEmpleadosVacacionesComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    CommonFormsModule,
    RouterModule.forChild(routes),
    TrabajadoresModule,
    ProyectosModule,
    FuseModule,
    FuseSharedModule,
    MatTableExporterModule,
    SharedModule
  ],
  exports: [
    TrabajadoresModule,
    SharedModule
  ],
  declarations: [ReportEmpleadosVacacionesComponent, ReportEmpleadosHorasComponent],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
  ]
})
export class ReportingModule { }
