import { Component, OnInit } from '@angular/core';

import { Router} from '@angular/router';

import { UserService } from '../users/services/user.service';
import { User } from '../users/model/user.model';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  constructor(
    private _router: Router,
    private _userService: UserService
  ) { }

  ngOnInit() { 
  }

  public gotoModulo1() {     
      this._router.navigateByUrl('/creacion');
  }
  public gotoModulo2() {
      this._router.navigateByUrl('/transferencia');
  }
  public gotoModulo3() {
      this._router.navigateByUrl('/recepcion');
  }
}
