import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatSnackBar, MatDialogRef, MatSnackBarConfig } from '@angular/material';
import { FormGroup, FormBuilder,FormControl, Validators } from '@angular/forms';
import { TrabajadoresService } from '../trabajadores.service';
import { CalendariosService } from '../../calendarios/calendarios.service';


@Component({
  selector: 'app-departamentos-ficha',
  templateUrl: './departamentos-ficha.component.html',
  styleUrls: ['./departamentos-ficha.component.css']
})
export class DepartamentosFichaComponent implements OnInit {
  public departamentoFormGroup: FormGroup;
  public calendarios: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public _formBuilder: FormBuilder,
    public _trabajadoresService: TrabajadoresService,
    public _matSnackBar: MatSnackBar,
    public _dialogRef: MatDialogRef<DepartamentosFichaComponent>,
    private _calendariosService: CalendariosService
  ) { }

  ngOnInit() {
    this.getCalendarios()
    this.departamentoFormGroup = this._formBuilder.group({
      id: new FormControl({ value: 0, disabled: true }, Validators.required),
      nombre: new FormControl('', Validators.required),
      descripcion: new FormControl(''),
      calendarioId: new FormControl('')
    });

    if (this.data && this.data.id) {
      this.departamentoFormGroup.setValue({
        id: this.data.id,
        nombre: this.data.nombre,
        descripcion: this.data.descripcion,
        calendarioId: this.data.calendarioId
      });
    }
  }

  getCalendarios() {
    return this._calendariosService.getCalendarios().subscribe((data) => {
      this.calendarios = data;
      this.departamentoFormGroup.patchValue({calendarioId: this.data.calendarioId})
    });
  }

  onSubmit() {
    var outData = this.departamentoFormGroup.getRawValue();
    this._trabajadoresService.updateDepartamento(outData).subscribe(
      (inData) => {
        this._matSnackBar.open("Departamento actualizado", '');
        this._dialogRef.close();
      }, (error) => {
          let sbConfig = new MatSnackBarConfig();
          sbConfig.horizontalPosition = 'right';
          sbConfig.verticalPosition = 'top';
          sbConfig.duration = 3000;
          sbConfig.panelClass = ['error-snackbar'];
          this._matSnackBar.open(error.error.message, 'Error', sbConfig);
      }
    );
  }

  displayFn(calendarioId) {
    if (this.calendarios) {
      var cal = this.calendarios.find(cal => cal.id == calendarioId);
      if (cal)
        return cal.nombre;
    }
    else
      return '';
  }
}
