import { Component, OnInit, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CalendariosService } from '../calendarios.service';

@Component({
  selector: 'app-calendarios-selector',
  templateUrl: './calendarios-selector.component.html',
  styleUrls: ['./calendarios-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CalendariosSelectorComponent),
      multi: true
    }
  ]
})
export class CalendariosSelectorComponent implements OnInit, ControlValueAccessor {

  public calendarios;
  public _calendario;
  @Input() calendario: number;
  @Output() calendarioChange = new EventEmitter();
  constructor(private _calendariosService : CalendariosService) { }

  ngOnInit() {
    this.getCalendarios();
  }
  getCalendarios() {
    return this._calendariosService.getCalendarios().subscribe((data) => {
      this.calendarios = data;

    });
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.calendario = obj;
      this._calendario = obj;
    }
  }
  propagateChange = (_: any) => { };
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  onTouch = () => { }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  isDisabled: boolean;
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }


  seleccionarCalendario() {
    this.calendarioChange.emit(this.calendario); //Para bindar con ngModel y Output
    this.propagateChange(this.calendario);
    this.onTouch();
  }

  displayFn(calendario) {
    if (this.calendarios) {
      var cal = this.calendarios.filter(x => x.id == calendario)[0];
      if (cal)
        return cal.descripcion;
    }
    else {
      return "";
    }
  }
}
