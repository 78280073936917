import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER,LOCALE_ID } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { AppComponent } from './app.component';
import { TranslateModule } from '@ngx-translate/core';

/** FUSE THEME **/
import { FuseModule } from '@fuse/fuse.module'; 
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from './_conf/index';
import { FuseLayoutModule } from './_layouts-fuse/layout.module';
import 'hammerjs';
/** SHARED MODULES **/
import { CommonFormsModule } from './_common/common-forms/common-forms.module';
import { AsSpinnerComponent } from './_common/common-components/as-spinner/as-spinner.component';
/** ROUTING **/
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

/** AUTENTICACION **/
import { AuthGuard } from './_auth/auth.guard';
import { AuthInterceptor } from './_auth/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

/**  LAYOUTS **/
import { LayoutsModule } from './_layouts/layouts.module';

/** TERCEROS **/
import { CalendarModule, DateAdapter} from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';


/** LOCALE **/
import localeEs from '@angular/common/locales/es';
import { registerLocaleData, DatePipe, DecimalPipe } from '@angular/common';
registerLocaleData(localeEs);
/**  MODULOS FUNCIONALIDAD**/
import { UsersModule } from './users/users.module';
import { ProyectosModule } from './proyectos/proyectos.module';
import { IndexComponent } from './index/index.component';
import { FichajesModule } from './fichajes/fichajes.module';
import { TenantProvider, tenantProviderFactory } from './_conf/tenant.service';
import { AccountProvider, accountProviderFactory } from './users/services/user.service';
import { CalendariosModule } from './calendarios/calendarios.module';
import { TrabajadoresModule } from './trabajadores/trabajadores.module';
import { ConfiguracionModule } from './configuracion/configuracion.module';
import { AsConfirmComponent } from './_common/common-components/as-confirm/as-confirm.component';
import { MAT_DATE_LOCALE, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ReportingModule } from './reporting/reporting.module';
import { TestModule } from './test/test.module';

@NgModule({
    declarations: [
        AppComponent,
        IndexComponent,
        AsSpinnerComponent,
        AsConfirmComponent
    ],
    imports: [
        BrowserModule, 
        BrowserAnimationsModule,
        HttpClientModule,
        LayoutModule,
        AppRoutingModule,
        TranslateModule.forRoot(),
        CommonFormsModule,
        FuseModule.forRoot(fuseConfig),
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FuseLayoutModule,
        UsersModule,
        LayoutsModule,
        ProyectosModule,
        FichajesModule,
        CalendariosModule,
        TrabajadoresModule,
        ConfiguracionModule,
        CalendarModule.forRoot({
          provide: DateAdapter,
          useFactory :adapterFactory
        }),
        TestModule
  ],
  exports: [
    FuseModule,
    FuseSharedModule,
    CalendarModule,
    ReportingModule
  ],
  providers: [         
    { provide: "AuthGuard", useValue: AuthGuard },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    TenantProvider,
    { provide: APP_INITIALIZER, useFactory: tenantProviderFactory, deps: [TenantProvider], multi: true },
    { provide: APP_INITIALIZER, useFactory: accountProviderFactory, deps: [AccountProvider], multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    { provide: LOCALE_ID, useValue: 'es' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    DecimalPipe
  ], 
  bootstrap: [AppComponent],
  entryComponents: [AsConfirmComponent]
  
})
export class AppModule { }


