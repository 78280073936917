import { Component, OnInit } from '@angular/core';
import { CalendariosService } from '../calendarios.service';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { AsConfirmComponent } from '../../_common/common-components/as-confirm/as-confirm.component';
import { CalendarioFichaComponent } from '../calendario-ficha/calendario-ficha.component'
import { AsSpinnerService } from '../../_common/common-components/as-spinner/as-spinner.service';
import { Calendar } from '../../_common/common-classes/calendar';

@Component({
  selector: 'app-calendarios-lista',
  templateUrl: './calendarios-lista.component.html',
  styleUrls: ['./calendarios-lista.component.css']
})
export class CalendariosListaComponent implements OnInit {
  private _sbConfig = new MatSnackBarConfig()
  public calendarios;
 

  constructor(
    private _calendarService: CalendariosService,
    private _router: Router,
    private _matDialog: MatDialog,
    private _matDialogRef: MatDialogRef<AsConfirmComponent|CalendarioFichaComponent>,
    private _matSnackBar: MatSnackBar,
    private _asSpinnerService: AsSpinnerService
  ) { }

  ngOnInit() {
    this.loadCalendars()
  }

  private loadCalendars() {
    this._calendarService.getCalendarios().subscribe((data) => {
      this.calendarios = data
    });
  }

  public columnas = ["Nombre", "Descripcion", "Activo", "Acciones"];

  public verCalendario(row) {
    this._router.navigateByUrl(`/calendario-ficha/${row.id}`);
  }

  private showMsg(msg, type = '', panelClass = 'error-snackbar', duration=3000) {
    this._sbConfig.horizontalPosition = 'right'
    this._sbConfig.verticalPosition = 'top'
    this._sbConfig.duration = duration
    this._sbConfig.panelClass = [panelClass]
    this._matSnackBar.open(msg, type, this._sbConfig)
  }

  public deleteCalendar(item: any) {
    console.log('Del ITEM', item)
    if (item.id == 1) {
      this.showMsg('Acción no permitida', 'Error')
      return;
    }
    // Aviso
    this._matDialogRef = this._matDialog.open(AsConfirmComponent,
      {
        data:
        {
          titulo: 'Borrar horario',
          mensaje: '¿Está seguro que desea borrar este calendario?'
        }
      }
    );
    this._matDialogRef.afterClosed().subscribe((data) => {
      if (data) {
        // Servicio. ¡Borramos!
        this._calendarService.deleteCalendar({ id: item.id }).subscribe((incommingData: any) => {
          this.showMsg('Calendario borrado', 'Notificación', 'incoming-snackbar');
          this._matDialogRef.close(true)
        });
      }
    });
  }

  public toggleStatus(item: any, checked: boolean) {
    this._asSpinnerService.enable();

    let before = !checked ? 'Activo' : 'Inactivo'
    item.activo = !item.activo
    let after = checked ? 'Activo' : 'Inactivo'

    let calendar = new EditCalendarModel(item, checked)

    // Actualizamos calendario para el tooltip
    let foundCal = this.calendarios.find(cal => { cal.id == item.id })
    if (foundCal)
      foundCal.active = item.activo

    this._calendarService.toggleStatus(calendar).subscribe((data) => {
      this._asSpinnerService.disable();
      this.showMsg(`Calendario actualizado correctamente de ${before} a ${after}`, '', 'incoming-snackbar')

    }, (error) => {
      this._sbConfig.panelClass = ['error-snackbar']
      this._asSpinnerService.disable()
        this.showMsg("Error al actualizar estado del calendario", 'Error', 'error-snackbar')
    });
  }

  public copyCalendar(item: any) {
    item.action = 'copy'
    this.openCalendarDialog(item)
  }

  public editCalendar(item: any) {
    item.action = 'edit'
    this.openCalendarDialog(item)
  }

  private openCalendarDialog(inData) {

    this._matDialogRef = this._matDialog.open(CalendarioFichaComponent, {
      data: inData,
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      closeOnNavigation: true
    })

    this._matDialogRef.afterClosed().subscribe(calData => {
      if (calData) {
        if (calData.action == 'copy') {
          let calendar = new NewCalendarModel(calData)
          this._calendarService.copyCalendar(calendar).subscribe((resp: any) => {
            if (resp.ok) {
              this.showMsg(`Calendario copiado correctamente`, '', 'outcoming-snackbar')
            }
          })
        } else if (calData.action == 'edit') {
          let calendar = new EditCalendarModel(calData, calData.active)
          this._calendarService.updateCalendar(calendar).subscribe((resp: any) => {
            if (resp.ok) {
              this.showMsg(`Calendario modificado correctamente`, '', 'outcoming-snackbar')
            }
          })
        }
        this.loadCalendars()
      }
      return calData
    })
    return null
  }


}

export class NewCalendarModel {

  public oldId: number
  public name: string
  public description: string
  public active: boolean

  constructor(cal: any) {
    this.oldId = cal.id
    this.name = cal.name
    this.description = cal.description
    this.active = true
  }
}

export class EditCalendarModel {

  public id: number
  public name: string
  public description: string
  public active: boolean

  constructor(cal: any, checked: boolean) {
    this.id = cal.id
    this.name = cal.nombre
    this.description = cal.descripcion
    this.active = checked
  }
}
