import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';



import { LoginLayoutComponent } from './login-layout/login-layout.component';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { NavigationBarComponent } from './navegacion/navigation-bar/navigation-bar.component';
import { DashboardComponent } from './navegacion/dashboard/dashboard.component';

import { ProyectosModule } from '../proyectos/proyectos.module';


export { LoginLayoutComponent } from './login-layout/login-layout.component';
export { HomeLayoutComponent } from './home-layout/home-layout.component';
export { NavigationBarComponent } from './navegacion/navigation-bar/navigation-bar.component';
export { DashboardComponent } from './navegacion/dashboard/dashboard.component';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        CommonFormsModule,
        ProyectosModule
    ],
    declarations: [
        LoginLayoutComponent,
        HomeLayoutComponent,
        NavigationBarComponent,
        DashboardComponent,
    ],
    exports: [LoginLayoutComponent,
        HomeLayoutComponent,
        NavigationBarComponent,
        DashboardComponent]
})
export class LayoutsModule { }
