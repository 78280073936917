export class VacacionesEmpleado {
  idEmpleado: number
  empleado: string
  horasJornada: number
  tiempos: VacacionesTiempos[] = []
  desgloseDias = []
  desgloseHoras = []
  periodos = []
  days: number
  accDays: number
  accHours: number
  pendingDays: number
  pendingAccHours: number
  pendingAccDays: number
  holidays: number

  constructor(data: any) {
    this.idEmpleado = data.idEmpleado
    this.empleado = data.nombreEmpleado ? `${data.nombreEmpleado} ${data.apellidosEmpleado}`.trim() : ''

    this.horasJornada = data.horasJornada
  }

  append(data: any): void {
    const item = new VacacionesTiempos(data)
    this.tiempos.push(item)
  }

  calcDays(): void {
    this.days = 0
    this.accDays = 0
    this.accHours = 0
    this.pendingDays = 0
    this.pendingAccDays = 0
    this.pendingAccHours = 0
    this.holidays = 22
     
    const today = new Date()
    this.tiempos.forEach(t => {
      const day = new Date(t.fecha)
      this.days += (t.diaCompleto && day <= today) ? 1 : 0
      this.pendingDays += (t.diaCompleto && day > today) ? 1 : 0
      this.accHours += (!t.diaCompleto && day <= today) ? t.horas : 0
      this.pendingAccHours += (!t.diaCompleto && day > today) ? t.horas : 0
      if (t.diaCompleto)
        this.desgloseDias.push(t)
      else
        this.desgloseHoras.push(t)
    })

    // dias accumulados de horas libres consumidos
    this.accDays = Math.floor(this.accHours / this.horasJornada)
    let decimal = (this.accHours / this.horasJornada) - this.accDays
    this.accHours = (decimal * this.horasJornada) / 24
    this.accDays += this.accHours

    // dias accumulados de horas libres pendientes
    this.pendingAccDays = Math.floor(this.pendingAccHours / this.horasJornada)
    decimal = (this.pendingAccHours / this.horasJornada) - this.pendingAccDays
    this.pendingAccHours = (decimal * this.horasJornada) / 24
    this.pendingAccDays += this.pendingAccHours

    this.setPeriods()

    this.holidays -= (this.days + this.pendingDays + this.accDays + this.pendingAccDays)
  }

  private setPeriods() {
    this.desgloseDias.forEach(d => {
      const fecha = new Date(d.fecha)
      const foundPeriod = this.periodos.find(p => p.periodo === d.periodo)
      if (foundPeriod) {
        foundPeriod.fechas.push(fecha)
      } else {
        const period = new PeriodoVacaciones()
        period.fechas.push(fecha)
        period.periodo = d.periodo
        this.periodos.push(period)
      }
    })
    delete this.desgloseDias
  }
}

export class PeriodoVacaciones {
  public periodo: string
  public fechas: Date[] = []
  public get dias() { return this.fechas.length }
}

export class VacacionesTiempos {
  fecha: Date
  periodo: string
  diaCompleto: boolean
  horaInicio: string
  horaFin: string
  minutos: number
  horas: number

  constructor(data: any) {
    this.fecha = new Date(data.fecha)
    this.periodo = data.periodo
    this.diaCompleto = data.diaCompleto
    this.horaInicio = data.diaCompleto ? null : data.horaInicio
    this.horaFin = data.diaCompleto ? null : data.horaFin
    this.minutos = data.minutos
    this.horas = data.minutos / 60
  }
}
