import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  readonly rootUrl = `${environment.apiUrl}api/proyectos`;

  constructor(private _http: HttpClient) { }

  getProjectList() {
    let url = `${this.rootUrl}/lista-proyectos`;
    return this._http.get(url);
  }

  getAvailableProjectList() {
    let url = `${this.rootUrl}/lista-proyectos-disponibles`;
    return this._http.get(url);
  }

  getActiveProjectList() {
    let url = `${this.rootUrl}/lista-proyectos-activos`;
    return this._http.get(url);
  }

  public getProjectCard(id: number): any {
    let url = `${this.rootUrl}/ficha-proyecto/${id}`;
    return this._http.get(url);
  }

  public addProject(task: any) {
    let url = `${this.rootUrl}/alta-proyecto`;
    return this._http.post(url, task);
  }

  public deleteProject(project: any) {
    let url = `${this.rootUrl}/borrar-proyecto`;
    return this._http.post(url, project);
  }

}
