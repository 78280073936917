import { Component, OnInit } from '@angular/core';
import { TrabajadoresService } from '../trabajadores.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-trabajadores-todos',
  templateUrl: '../trabajadores-lista/trabajadores-lista.component.html',
  styleUrls: ['../trabajadores-lista/trabajadores-lista.component.scss']
})
export class TrabajadoresTodosComponent implements OnInit {

  public isMobile: boolean;
  public columnasTest = ["NIF", "Nombre"];
  public empleados;
  public columnas = ["NIF", 'Nombre', 'Mail', 'Telefono', "Cargo", "Departamento", 'UsuarioWeb', 'Activo', 'FicharContraProyectos'];
  // public columnas = ['Nombre', 'UsuarioWeb', 'Activo'];

  constructor(
    private _trabajadoresService: TrabajadoresService,
    private _router: Router
  ) {

  }

  ngOnInit() {
    this._trabajadoresService.getAllWorkers().subscribe((data) => {
      this.empleados = data
    })
  }

  verEmpleado(empleado:any) {
    this._router.navigateByUrl(`/empleado/${empleado.id}`);
  }

  getWorker(worker) {
    return `${worker.nombre} ${worker.apellido1} ${worker.apellido2}`;
  }
}
