export class DateDiff {
  private days: number;
  private hours: number;
  private minutes: number;
  private seconds: number;
  private dict: any = { d: 0, h: 0, m: 0, s: 0 };
  private diffArray = [];
  private showDays: boolean;
  private showMinutes: boolean;

  constructor(diff: number, showDays: boolean = true, showMinutes: boolean = true) {
    this.days = diff / (24 * 60 * 60 * 1000);
    this.hours = (this.days % 1) * 24;
    this.minutes = (this.hours % 1) * 60;
    this.seconds = (this.minutes % 1) * 60;
    this.showDays = showDays;
    this.showMinutes = showMinutes;

    this.dispatch();
  }

  private dispatch() {
  /* Función para obtener sumatorio de días, horas, minutos y segundos
   * Si showDays es false, convertimos los días a horas.
   * Si showMinutes es false escondemos los minutos.
   * En principio no mostramos los segundos.
   */

    if (!this.showDays) {
      this.days = Math.floor(this.days)
      this.hours += this.days * 24;
      this.days = 0;
    }
    if (!this.showMinutes) {
      this.minutes = 0
    }
    this.dict.d = Math.floor(this.days)
    this.dict.h = Math.floor(this.hours)
    this.dict.m = Math.floor(this.minutes)
    this.dict.s = 0 /* Math.floor(secs) */ 
    if (this.dict.d == 0 && this.dict.h == 0 && this.dict.m == 0)
      this.dict.s = Math.floor(this.seconds)
    Object.entries(this.dict).forEach(([key, val]) => { if (val > 0) { this.diffArray.push(val + '' + key) } })
  }

  public toString(): string {
    return this.diffArray.join(' ');
  }

  public get dayDelay() {
    return Math.floor(this.days);
  }
}
