import { Component, OnInit } from '@angular/core';
import { TrabajadoresService } from '../trabajadores.service';
import { EmpleadosSolicitudesFichaComponent } from '../empleados-solicitudes-ficha/empleados-solicitudes-ficha.component';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Params } from '@angular/router';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-empleados-solicitudes',
  templateUrl: './empleados-solicitudes.component.html',
  styleUrls: ['./empleados-solicitudes.component.css']
})
export class EmpleadosSolicitudesComponent implements OnInit {
  public _allRequests: any;
  public pendingRequests: any;
  public _pendingRequests: any;
  public resolvedRequests: any;
  public _resolvedRequests: any;
  public statuses: RequestStatus[] = []
  public timeAgoList = [
    new TimeAgo(0, 'esta semana', 7),
    new TimeAgo(1, 'hace dos semanas', 14),
    new TimeAgo(2, 'hace un mes', 30),
    new TimeAgo(3, 'hace un año', 365),
    new TimeAgo(4, 'hace tres años', 365 * 3),
  ]

  public columnas = ['Estado', 'FechaSolicitud', 'Motivo', 'Empleado', 'Periodo', 'Responsable', 'MotivoRechazo', 'ComentarioResponsable', 'Acciones']
  public columnasPendientes = ['FechaSolicitud', 'Motivo', 'Empleado', 'Periodo', 'Responsable', 'MotivoRechazo', 'ComentarioResponsable', 'Acciones']
  public dialogRef;
  private dialogRefConfig: MatDialogConfig;
  private ascending: boolean;
  private empleadoId: number;
  private diasSolicitudes: number;
  public pendingReqEmployees: Employee[] = []
  public pendingRequestsFormGroup: FormGroup
  public resolvedReqEmployees: Employee[] = []
  public resolvedRequestsFormGroup: FormGroup

  constructor(
    private _trabajadoresService: TrabajadoresService,
    private _configuracionService: ConfiguracionService,
    private _matDialog: MatDialog,
    private _datePipe: DatePipe,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder
  ) {
    this.dialogRefConfig = {
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      closeOnNavigation: true
    }
    this.ascending = true;
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.empleadoId = params.workerId;
      this._configuracionService.getDaysAgo().subscribe((data: number) => {
        this.diasSolicitudes = data;
        this.getSolicitudes();
      })
    });

    // Inicialización filtros
    this.pendingRequestsFormGroup = this._formBuilder.group({
      employeeId: new FormControl({ value: 0, disabled: false }, Validators.required),
      timeAgoId: new FormControl({ value: 0, disabled: false }, Validators.required)
    })

    this.resolvedRequestsFormGroup = this._formBuilder.group({
      employeeId: new FormControl({ value: 0, disabled: false }, Validators.required),
      requestStatusId: new FormControl({ value: 0, disabled: false }, Validators.required),
      timeAgoId: new FormControl({ value: 0, disabled: false }, Validators.required)
    })

    // Escuchadores filtros
    this.pendingRequestsFormGroup.valueChanges.subscribe((formData: any) => {
      this.filterPendingRequests(formData)
    });

    this.resolvedRequestsFormGroup.valueChanges.subscribe((formData: any) => {
      this.filterResolvedRequests(formData)
    });
  }

  private filterPendingRequests(formData: any) {
    if (formData.employeeId === 0)
      this.pendingRequests = this._pendingRequests
    else
      this.pendingRequests = this._pendingRequests.filter(s => s.empleadoId === formData.employeeId)

    // Hace x días...
    const delorean = this.timeAgoList.find(t => t.id === formData.timeAgoId)
    const targetDate = new Date()
    targetDate.setDate(targetDate.getDate() - delorean.daysAgo)

    this.pendingRequests = this.pendingRequests.filter(s => s.fechaDesde >= targetDate.toJSON())
  }

  private filterResolvedRequests(formData: any) {
    let resolvedRequests = this._resolvedRequests
    // Empleado
    if (formData.employeeId > 0)
      resolvedRequests = resolvedRequests.filter(s => s.empleadoId === formData.employeeId)

    // Estado
    if (formData.requestStatusId > 0)
      resolvedRequests = resolvedRequests.filter(s => s.estado === formData.requestStatusId)

    // Hace x días...
    const delorean = this.timeAgoList.find(t => t.id === formData.timeAgoId)
    const targetDate = new Date()
    targetDate.setDate(targetDate.getDate() - delorean.daysAgo)

    resolvedRequests = resolvedRequests.filter(s => s.fechaDesde >= targetDate.toJSON())
    this.resolvedRequests = resolvedRequests
  }

  private getSolicitudes(filter:boolean=false) {
    const today = new Date();
    const max_time_ago = new Date(today)
    const last_week = new Date(today)
    max_time_ago.setDate(today.getDate() - this.diasSolicitudes)
    last_week.setDate(today.getDate() - 7)
    const max_date_str = max_time_ago.toJSON()
    const default_date_str = last_week.toJSON()
    const empleadoId = this.empleadoId || null;
    this._trabajadoresService.getSolicitudes(empleadoId).subscribe((data: any) => {
      // Ordre descendent.
      data = data.sort((a, b) => (a.fechaDesde > b.fechaDesde) ? -1 : ((b.fechaDesde > a.fechaDesde) ? 1 : 0))
      this._allRequests = data;
      // Separem per pendents/resoltes.
      this._pendingRequests = data.filter(sol => sol.estado == 0 && sol.fechaDesde > max_date_str);
      this.pendingRequests = data.filter(sol => sol.estado == 0 && sol.fechaDesde > default_date_str);
      this._resolvedRequests = data.filter(sol => sol.estado != 0 && sol.fechaDesde > max_date_str);
      this.resolvedRequests = data.filter(sol => sol.estado != 0 && sol.fechaDesde > default_date_str);
      this.setEmployees()
      this.setStatuses()
      if (filter) {
        this.filterPendingRequests(this.pendingRequestsFormGroup.value)
        this.filterResolvedRequests(this.resolvedRequestsFormGroup.value)
      }
    });
  }

  private setEmployees() {
    // Insertamos los empleados de las solicitudes en una lista para mostrar.
    this.pendingReqEmployees = []
    this._pendingRequests.forEach(s => {
      const employee = s.empleado
      const foundEmployee = this.pendingReqEmployees.find(e => e.id === employee.id)
      if (!foundEmployee) {
        this.pendingReqEmployees.push(new Employee(employee))
      }
    })

    // Insertamos el valor por defecto.
    this.pendingReqEmployees = this.pendingReqEmployees.sort((a, b) => a.name < b.name ? -1 : 1)
    const allWorkers = new Employee({ id: 0, nombre: 'Todos', apellido1: '', apellido2: '' })
    if (!this.pendingReqEmployees.find(p => p.id === 0))
      this.pendingReqEmployees.unshift(allWorkers)

    // Insertamos los empleados de las solicitudes en una lista para mostrar.
    this.resolvedReqEmployees = []
    this._resolvedRequests.forEach(s => {
      const employee = s.empleado
      const foundEmployee = this.resolvedReqEmployees.find(e => e.id === employee.id)
      if (!foundEmployee) {
        this.resolvedReqEmployees.push(new Employee(employee))
      }
    })
    // Insertamos el valor por defecto.
    this.resolvedReqEmployees = this.resolvedReqEmployees.sort((a, b) => a.name < b.name ? -1 : 1)
    if (!this.resolvedReqEmployees.find(r => r.id === 0))
      this.resolvedReqEmployees.unshift(allWorkers)
  }

  private setStatuses() {
    this.statuses = []
    this._resolvedRequests.forEach(r => {
      const status = { id: r.estado, description: r.estadoDescripcion }
      const foundStatus = this.statuses.find(s => s.id === status.id)
      if (!foundStatus) {
        this.statuses.push(status as RequestStatus)
      }
    })
    this.statuses = this.statuses.sort((a, b) => a.id < b.id ? -1 : 1)
    if (!this.statuses.find(s => s.id === 0)) {
      const both = { id: 0, description: 'Ambos' } as RequestStatus
      this.statuses.unshift(both)
    }
  }

  public nuevaSolicitud() {
    //this._router.navigate(['nueva-solicitud']);
    let config = {
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      closeOnNavigation: true
    }
    this.dialogRef = this._matDialog.open(EmpleadosSolicitudesFichaComponent, config);
    this.dialogRef.afterClosed().subscribe((data: boolean) => {
      if (data) {
        this.cleanFilters(FormTarget.PENDING)
        this.getSolicitudes();
      }
    });
  }

  private cleanFilters(target: FormTarget) {
    if (target == FormTarget.BOTH || target == FormTarget.PENDING)
      this.pendingRequestsFormGroup.setValue({
        employeeId: 0,
        timeAgoId: 0
      })

    if (target == FormTarget.BOTH || target == FormTarget.RESOLVED)
      this.resolvedRequestsFormGroup.setValue({
        employeeId: 0,
        requestStatusId: 0,
        timeAgoId: 0
      })
  }

  public editarSolicitud(row) {
    let config = this.dialogRefConfig;
    config.data = {
        idSolicitud: row.id,
        modoAprobacion: false
    }
    config.minWidth = 600,
    this.dialogRef = this._matDialog.open(EmpleadosSolicitudesFichaComponent, config);
    this.dialogRef.afterClosed().subscribe((data: boolean) => {
      if (data) {
        // cargamos nuevos datos
        this.getSolicitudes(true);
      }
    });
  }

  public aprobarSolicitud(item) {
    let config = this.dialogRefConfig;
    config.data = {
      idSolicitud: item.id,
      modoAprobacion: true,
      empleadoId: item.empleadoId
    }

    this.dialogRef = this._matDialog.open(EmpleadosSolicitudesFichaComponent, config);
    this.dialogRef.afterClosed().subscribe((data: boolean) => {
      if (data) {
        this.getSolicitudes(true);
      }
    });
  }

  private transformDate(item) {
    return this._datePipe.transform(item, 'dd/MM/yy')
  }

  private toHHMM(time: string) {
    return time.substring(0,5)
  }

  public getPeriodo(item) {
    let rtn = '';
    if (item.diaCompleto) {
      rtn = `Del ${this.transformDate(item.fechaDesde)} al ${this.transformDate(item.fechaHasta)}`;
    } else {
      rtn = `El ${this.transformDate(item.fechaDesde)}, desde las ${this.toHHMM(item.horaDesde)} hasta las ${this.toHHMM(item.horaHasta)}`;
    }
    return rtn;
  }
}

class Employee {
  id: number
  name: string
  surname: string

  constructor(employee: any) {
    this.id = employee.id
    this.name = employee.nombre
    this.surname = `${employee.apellido1} ${employee.apellido2}`.trim()
  }

  get fullName() {
    return `${this.name} ${this.surname}`.trim()
  }
}

class RequestStatus {
  id: number
  description: string
}

class TimeAgo {
  id: number
  description: string
  daysAgo: number

  constructor(id: number, description: string, daysAgo: number) {
    this.id = id
    this.description = description
    this.daysAgo = daysAgo
  }
}

enum FormTarget {
  BOTH, PENDING, RESOLVED, NONE
}
