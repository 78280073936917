import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';
import { RouterModule, Routes } from '@angular/router';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseWidgetModule } from '@fuse/components/widget/widget.module';
import { ProyectosListaComponent } from './proyectos-lista/proyectos-lista.component';
import { ProyectosListaViewComponent } from './lista/lista.component';
import { ClientesListaComponent } from './clientes-lista/clientes-lista.component';
import { TareasListaComponent } from './tareas-lista/tareas-lista.component';
import { CustomerCardComponent } from './clientes-ficha/clientes-ficha.component';
import { TaskCardComponent } from './tareas-ficha/tareas-ficha.component';
import { TaskService } from './services/task.service';
import { ProjectService } from './services/project.service';
import { CustomerService } from './services/customer.service';
import { ProjectCardComponent } from './proyectos-ficha/proyectos-ficha.component';
import { AdminOrManagerGuardService } from '../users/services/admin-or-manager-guard.service';
import { ProjectSelectorComponent } from './proyectos-selector/proyectos-selector.component';
export { ProjectSelectorComponent } from './proyectos-selector/proyectos-selector.component';

const routes: Routes = [
  {
    path: 'lista-proyectos',
    component: ProyectosListaComponent,
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'ficha-proyecto/:id',
    component: ProjectCardComponent,
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'alta-proyecto',
    component: ProjectCardComponent,
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'lista-tareas',
    component: TareasListaComponent,
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'ficha-tarea/:id',
    component: TaskCardComponent,
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'alta-tarea',
    component: TaskCardComponent,
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'lista-clientes',
    component: ClientesListaComponent,
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'alta-cliente',
    component: CustomerCardComponent,
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'ficha-cliente/:id',
    component: CustomerCardComponent,
    canActivate: [AdminOrManagerGuardService]
  }
];

@NgModule({
  imports: [
      CommonModule,
      CommonFormsModule,
      RouterModule.forChild(routes),
      FuseModule,
      FuseSharedModule,
      FuseWidgetModule
  ],
  declarations: [
    ProyectosListaComponent, ProjectCardComponent, ClientesListaComponent, CustomerCardComponent,
    TareasListaComponent, TaskCardComponent, ProjectSelectorComponent, ProyectosListaViewComponent
  ],
  providers: [ProjectService, CustomerService, TaskService],
  exports: [
    ProyectosListaComponent, ProjectCardComponent, ClientesListaComponent, CustomerCardComponent,
    TareasListaComponent, RouterModule, ProjectSelectorComponent
  ]
})
export class ProyectosModule { }
