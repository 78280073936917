import { NgModule } from '@angular/core';
import { MatButtonModule, MatIconModule } from '@angular/material';


import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarComponent } from './navbar.component';
import { CommonFormsModule } from '../../../_common/common-forms/common-forms.module';

@NgModule({
    declarations: [
        NavbarComponent
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,

        FuseSharedModule,
      FuseNavigationModule,
        CommonFormsModule
    ],
    exports     : [
        NavbarComponent
    ]
})
export class NavbarModule
{
}
