import { Component, OnInit,ViewChild,TemplateRef, ViewEncapsulation } from '@angular/core';
import { CalendarEvent } from 'calendar-utils';
import { CalendarEventAction, CalendarView, DAYS_OF_WEEK, CalendarDateFormatter } from 'angular-calendar';
import { Subject } from 'rxjs';
import { CustomDateFormatter } from '../../_common/custom-date-formatter/custom-date-formatter.provider';
import { TrabajadoresService } from '../trabajadores.service';
import { forEach } from '@angular/router/src/utils/collection';
import { debug } from 'util';
import { parseISO } from 'date-fns'

@Component({
  selector: 'app-empleado-calendario',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './empleado-calendario.component.html',
  styleUrls: ['./empleado-calendario.component.css'],
  providers: [{ provide: CalendarDateFormatter, useClass: CustomDateFormatter }]

})
export class EmpleadoCalendarioComponent implements OnInit {
  public i: CustomDateFormatter
  public view: CalendarView = CalendarView.Month;
  public CalendarView: CalendarView;
  public locale: string = "es";
  public viewDate: Date = new Date();
  public events: CalendarEvent[] = [];
  public actions: CalendarEventAction[];
  public refresh: Subject<any> = new Subject();
  public starDayOfWeek: number = DAYS_OF_WEEK.MONDAY;
  public weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];

  public month: number;


  public timeline: any;


  public mesesArray = [{
    mes: 'Enero',
    numero: 1,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 0, 1)
  }, {
    mes: 'Febrero',
    numero: 2,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 1, 1)
  },
  {
    mes: 'Marzo',
    numero: 3,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 2, 1)

  }, {
    mes: 'Abril',
    numero: 4,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 3, 1)

  }, {
    mes: 'Mayo',
    numero: 5,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 4, 1)
  }, {
    mes: 'Junio',
    numero: 6,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 5, 1)
  },
  {
    mes: 'Julio',
    numero: 7,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 6, 1)
  }, {
    mes: 'Agosto',
    numero: 8,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 7, 1)
  }, {
    mes: 'Septiembre',
    numero: 9,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 8, 1)
  }, {
    mes: 'Octubre',
    numero: 10,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 9, 1)
  },
  {
    mes: 'Noviembre',
    numero: 11,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 10, 1)
  }, {
    mes: 'Diciembre',
    numero: 12,
    eventos: [],
    fecha: new Date(new Date().getFullYear(), 11, 1)
  }
  ];
  public mesSeleccionado;
  public anyoSeleccionado;

  constructor(private _trabajadoresService: TrabajadoresService) { }

  tiposEventos: any = {
    festivoEmpresa: {
      primary: '#ad2121',
      secondary: '#FAE3E3',
    },
    festivoEmpleado: {
      primary: '#e3bc08',
      secondary: '#FDF1BA',
    }
  };


  ngOnInit() {
    this.month = new Date().getMonth() + 1;
    this.anyoSeleccionado = new Date().getFullYear();
    this.getMesEmpleado(this.anyoSeleccionado, this.month);
    this.getHorarioDia(new Date());
  }

  getMesEmpleado(anyo, mes) {
    this.mesSeleccionado = this.mesesArray.filter(f => f.numero == this.month)[0];
    this._trabajadoresService.getMesEmpleado(anyo, mes).subscribe((data: Array<any>) => {

      this.viewDate = new Date(anyo, mes - 1, 1);
      var festivos = data.filter(f => f.festivo);
      festivos.forEach((val, index, arr) => {
        let ev: CalendarEvent = {
          start: parseISO(val.fecha),
          //end: val.fecha,
          title: val.comentario,
          color: val.festivoEmpleado === true ? this.tiposEventos.festivoEmpleado : this.tiposEventos.festivoEmpresa,
          actions: this.actions,
          allDay: true
        };
        this.events.push(ev);
      })
      this.refresh.next();
    });
  }

  dayClicked($event) {
    this.getHorarioDia($event.date);
  }

  getHorarioDia(dia) {
    dia.setHours(dia.getHours() + 2);
    this._trabajadoresService.getHorarioDia({ dia: dia }).subscribe((data) => {
      this.timeline = data;
    });
  }

  mesAnterior() {
    if (this.month == 1) {
      this.month = 12;
      this.anyoSeleccionado -= 1;
    } else {
      this.month -= 1;
    }
    this.getMesEmpleado(this.anyoSeleccionado, this.month);

  }

  mesSiguiente() {
    if (this.month == 12) {
      this.month = 1;
      this.anyoSeleccionado += 1;
    
    } else {
      this.month += 1;
    }
    this.getMesEmpleado(this.anyoSeleccionado, this.month);
  }
}
