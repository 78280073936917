import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
  readonly rootUrl = `${environment.apiUrl}api/reports`;  

  constructor(private _http: HttpClient) { }

  getFirstAndLastDatesOfPreviousMonth() {
    var url = `${this.rootUrl}/dates-of-previous-month`;
    return this._http.get(url);
  }

  getFirstAndLastDatesOfCurrentMonth() {
    var url = `${this.rootUrl}/dates-of-current-month`;
    return this._http.get(url);
  }

  empleadosHoras(data) {
    var url = `${this.rootUrl}/empleadosHoras`;
    return this._http.post(url, data);
  }  

  empleadosVacaciones(data) {
    var url = `${this.rootUrl}/empleadosVacaciones`;
    return this._http.post(url, data);
  }

  vacacionesEmpleados(data) {
    var url = `${this.rootUrl}/vacaciones-empleados`
    return this._http.post(url, data)
  }

  vacacionesEmpleado(data) {
    var url = `${this.rootUrl}/vacaciones-empleado`
    return this._http.post(url, data)
  }
}
