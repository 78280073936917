// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const version = '4.8.0.0'
const useDocAsPass = ['bio', 'c1'] // ] // 

export const environment = {
  production: false,
  //apiUrl: 'http://localhost:49751/',
  apiUrl: 'http://localhost:56883/',
  appUrlSubdomain: 'https://{subdominio}ascp.com:44365/',
  appBaseHref: '',
  appDomain: 'ascp.com',
  app_version: version,
  useDocAsPass: useDocAsPass
};

/*
var environment = {
  production: true,
  apiUrl: 'https://api.ascontrol.es/',
  appUrlSubdomain: '/app/',
  appBaseHref: '',
  appDomain: 'ascontrol.es',
  app_version: version,
  useDocAsPass: useDocAsPass
};
*/

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
