import { Component, OnInit } from '@angular/core';
import { TrabajadoresService } from '../trabajadores.service';
import { MatDialog } from '@angular/material';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-empleado-solicitudes-propias',
  templateUrl: './empleado-solicitudes-propias.component.html',
  styleUrls: ['./empleado-solicitudes-propias.component.css']
})
export class EmpleadoSolicitudesPropiasComponent implements OnInit {
  public empleadosSolicitudes: any;
  public solicitudesPendientes: any;
  public solicitudesResueltas: any;

  public columnas = ['Estado', 'FechaSolicitud', 'Motivo', 'Empleado', 'Periodo', 'Responsable', 'MotivoRechazo', 'ComentarioResponsable']
  public dialogRef;
  private ascending: boolean;

  constructor(
    private _trabajadoresService: TrabajadoresService,
    private _matDialog: MatDialog,
    private _datePipe: DatePipe
  ) {
    this.ascending = true;
  }

  ngOnInit() {
    this.getSolicitudes();
  }

  private sortDataByDate(data: any) {
    const today = new Date()
    let copy = data.filter(s => new Date(s.fechaDesde).getFullYear() >= today.getFullYear())
    return copy.sort((a, b) => (a.fechaDesde > b.fechaDesde) ? -1 : ((b.fechaDesde > a.fechaDesde) ? 1 : 0))
  }

  private getSolicitudes() {
    this._trabajadoresService.getSolicitudesPropias().subscribe((data: any) => {
      data = this.sortDataByDate(data)
      this.empleadosSolicitudes = data;
      this.solicitudesPendientes = data.filter(sol => sol.estado == 0)
      this.solicitudesResueltas = data.filter(sol => sol.estado != 0)
    });
  }

  private transformDate(item) {
    return this._datePipe.transform(item, 'dd/MM/yy')
  }

  public getPeriodo(item) {
    var rtn = '';
    if (item.diaCompleto) {
      rtn = `Del ${this.transformDate(item.fechaDesde)} al ${this.transformDate(item.fechaHasta)}`;
    } else {
      rtn = `El ${this.transformDate(item.fechaDesde)}, desde las ${item.horaDesde.substring(0,5)} hasta las ${item.horaHasta.substring(0,5)}`;
    }
    return rtn;
  }
}
