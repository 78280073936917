import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { CustomerService } from '../services/customer.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatDialogRef, MatDialog } from '@angular/material';
import { AsSpinnerService } from '../../_common/common-components/as-spinner/as-spinner.service';
import { AccountProvider } from '../../users/services/user.service';
import { User } from '../../users/model/user.model';
import { AsConfirmComponent } from '../../_common/common-components/as-confirm/as-confirm.component';

@Component({
  selector: 'app-clientes-ficha',
  templateUrl: './clientes-ficha.component.html',
  styleUrls: ['./clientes-ficha.component.scss']
})
export class CustomerCardComponent implements OnInit {
  public errorMessage = '';
  private endpoint = "/lista-clientes";
  public customerFormGroup: FormGroup;
  public customerId: number;
  public customer: any;
  public errorMsg: string;
  public user: User;
  private snackbarConfig: MatSnackBarConfig;
  private deleteCustBool: boolean;
  private confirmDialogRef: MatDialogRef<AsConfirmComponent>;
  public isDisabled: boolean;
  public loading = false;

  constructor(
    private confirmDialog: MatDialog,
    private _formBuilder: FormBuilder,  
    private _activatedRoute: ActivatedRoute,
    private _customersService: CustomerService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _asSpinner: AsSpinnerService,
    private _accountProvider: AccountProvider
  ) {
    this.deleteCustBool = false;
    this.setSnackbarConfig();
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.panelClass = ['error-snackbar'];
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.customerId = params['id'];
      if (this.customerId)
        this.getCustomer(this.customerId);
    });

    this.user = this._accountProvider.getAccount();

    this.customerFormGroup = this._formBuilder.group({
        id: new FormControl({value: 0, disabled: false}),
        nombre: new FormControl({ value: null, disabled: false }, Validators.required),
        cif: new FormControl({ value: null, disabled: false }),
        direccion: new FormControl({value: null, disabled: false}),
        codigoPostal: new FormControl({ value: null, disabled: false }),
        localidad: new FormControl({ value: null, disabled: false }),
        provincia: new FormControl({ value: null, disabled: false }),
        nombreContacto1: new FormControl({ value: null, disabled: false }),
        cargoContacto1: new FormControl({ value: null, disabled: false }),
        telefonoContacto1: new FormControl({ value: null, disabled: false }),
        emailContacto1: new FormControl({ value: null, disabled: false }),
        nombreContacto2: new FormControl({ value: null, disabled: false }),
        cargoContacto2: new FormControl({ value: null, disabled: false }),
        telefonoContacto2: new FormControl({ value: null, disabled: false }),
        emailContacto2: new FormControl({ value: null, disabled: false }),
        observaciones: new FormControl({ value: null, disabled: false })
     });
    this.isDisabled = true;
  }

  public getCustomer(id: number): void {
    this._customersService.getCustomerCard(id).subscribe((cust: any) => {
      cust = cust[0];
      this.customer = cust;
      this.customerFormGroup.setValue({
        id: cust.id,
        nombre: cust.nombre,
        cif: cust.cif,
        direccion: cust.direccion,
        codigoPostal: cust.codigoPostal,
        localidad: cust.localidad,
        provincia: cust.provincia,
        nombreContacto1: cust.nombreContacto1,
        cargoContacto1: cust.cargoContacto1,
        telefonoContacto1: cust.telefonoContacto1,
        emailContacto1: cust.emailContacto1,
        nombreContacto2: cust.nombreContacto2,
        cargoContacto2: cust.cargoContacto2,
        telefonoContacto2: cust.telefonoContacto2,
        emailContacto2: cust.emailContacto2,
        observaciones: cust.observaciones
      });
    })
  }

  private validateData() {
    if (this.deleteCustBool) {
      this.deleteCustBool = false;
      return false;
    }

    // Es una creación de un nuevo cliente.
    if (!this.customer)
      return true;

    let ctrl = this.customerFormGroup.controls
    let changes = [
      this.customer.nombre == ctrl.nombre.value,
      this.customer.cif == ctrl.cif.value,
      this.customer.direccion == ctrl.direccion.value,
      this.customer.codigoPostal == ctrl.codigoPostal.value,
      this.customer.localidad == ctrl.localidad.value,
      this.customer.provincia == ctrl.provincia.value,
      this.customer.nombreContacto1 == ctrl.nombreContacto1.value,
      this.customer.cargoContacto1 == ctrl.cargoContacto1.value,
      this.customer.telefonoContacto1 == ctrl.telefonoContacto1.value,
      this.customer.emailContacto1 == ctrl.emailContacto1.value,
      this.customer.nombreContacto2 == ctrl.nombreContacto2.value,
      this.customer.cargoContacto2 == ctrl.cargoContacto2.value,
      this.customer.telefonoContacto2 == ctrl.telefonoContacto2.value,
      this.customer.emailContacto2 == ctrl.emailContacto2.value,
      this.customer.observaciones == ctrl.observaciones.value
    ]

    // El formulario no se ha modificado
    if (changes.every(Boolean)) {
      this._matSnackBar.open('No ha habido cambios', '');
      return false;
    }

    return true;
  }

  public onSubmit(): void {
    if (!this.validateData()) {
      return;
    }
    this.loading = true;

    this._asSpinner.enable();
    var data = this.customerFormGroup.getRawValue();
    this._customersService.addCustomer(data).subscribe((data) => {
      this._asSpinner.disable();
      this.loading = false;
      this._matSnackBar.open('Cliente actualizado', '');
      if(this.user.isAdminWeb)
        this._router.navigateByUrl(this.endpoint);
    }, (error) => {
      this.loading = false;
      this._asSpinner.disable();
      this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
    });
  }

  public confirmDeleteCustomer(): void {
    this.deleteCustBool = true;
    let action = { title: 'Borrar Cliente', question: 'Confirme que desea borrar el cliente.' }
    let config = { data: { titulo: action.title, mensaje: action.question, } }
    this.confirmDialogRef = this.confirmDialog.open(AsConfirmComponent, config);
    this.confirmDialogRef.afterClosed().subscribe((resp) => {
      if (resp)
        this.deleteCustomer();
    });
  }

  private deleteCustomer(): void {
    this._asSpinner.enable();
    this.loading = true
    var formData = this.customerFormGroup.getRawValue();    
    this._customersService.deleteCustomer(formData).subscribe((data) => {
      this.loading = false
      this._asSpinner.disable();
      this._matSnackBar.open('Cliente eliminado', '');
      this.back();
    }, (error) => {
        this.loading = false
        this._asSpinner.disable();
        this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
    });
  }

  cancel(): void {
    this.back();
  }

  back(): void {
    this.loading = false
    this._router.navigateByUrl(this.user.isAdminWeb ? this.endpoint : '/');
  }
}
