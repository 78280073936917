import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-empleados-fichajes',
  templateUrl: './empleados-fichajes.component.html',
  styleUrls: ['./empleados-fichajes.component.css']
})
export class EmpleadosFichajesComponent implements OnInit {
  fichajes;
  columnas;
  constructor() { }

  ngOnInit() {
  }

}
