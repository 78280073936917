import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { CalendariosService } from '../calendarios.service';
import { MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatDialogRef, MatSnackBarConfig } from '@angular/material';
import { CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { AsConfirmComponent } from 'src/app/_common/common-components/as-confirm/as-confirm.component';

@Component({
  selector: 'app-calendarios-festivos-ficha',
  templateUrl: './calendarios-festivos-ficha.component.html',
  styleUrls: ['./calendarios-festivos-ficha.component.css']
})
export class CalendariosFestivosFichaComponent implements OnInit {

  periodicidades: any;
  dias: any;
  showDia: boolean = false;
  idFestivo: number;
  festivoFormGroup: FormGroup;
  private snackbarConfig: MatSnackBarConfig;

  constructor(private _formBuilder: FormBuilder,
    private _calendariosService: CalendariosService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialog,
    private _matSnackBar: MatSnackBar,
    private _matDialogRef: MatDialogRef<CalendariosFestivosFichaComponent>) { }
    private dialogRef;

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.panelClass = ['error-snackbar'];
  }

  ngOnInit() {
    this.setSnackbarConfig();
    this.festivoFormGroup = this._formBuilder.group({
      id: new FormControl({ value: 0, disabled: true }, Validators.required),
      periodicidad: new FormControl(0, Validators.required),
      fecha: new FormControl({}),
      numero: new FormControl({}),
      festivo: new FormControl({ value: true }),
      calendarioId: new FormControl({ value: '', disabled: true })
    });

    this.periodicidades = this.data.periodicidades;
    this.dias = this.data.dias;

    if (this.data && this.data.festivo && this.data.festivo.id != 0) {
      var festivo = this.data.festivo;
      this.idFestivo = festivo.id;

      this.festivoFormGroup.patchValue({
        id: festivo.id,
        periodicidad: festivo.periodicidad,
        fecha: festivo.fecha,
        numero: festivo.numero,
        calendarioId: festivo.calendarioId
      });
      this.setShowDia(festivo.periodicidad);
    } else if (this.data && this.data.festivo && this.data.festivo.calendarioId) {
      this.festivoFormGroup.patchValue({
        calendarioId: this.data.festivo.calendarioId
      });
      this.setShowDia(0);
    } else {
      this.setShowDia(0);
    }

    this.festivoFormGroup.controls["periodicidad"].valueChanges.subscribe((data) => {
      this.setShowDia(data);
      var esFestivo = true;
      if (data === 3)
        esFestivo = false;

      this.festivoFormGroup.patchValue({ festivo: esFestivo });
      console.log(data);
    });
  }

  setShowDia(periodicidad) {
    this.showDia = false;
    if (periodicidad == 1) {
      this.showDia = true;
    }
  }

  displayFnPeriodicidad(data) {
    var rtn = "";
    if (this.periodicidades) {
      let c = this.periodicidades.find(x => x.id == data);
      if (c)
         rtn = c.descripcion;
    }
    return rtn;
  }

  displayFnDia(data) {
    var rtn = '';
    if (this.dias) {
      let c = this.dias.find(x => x.id == data);
      if (c)
        rtn = c.descripcion;
    }
    return rtn;
  }

  borrar() {
    let row = this.festivoFormGroup.getRawValue();
    let config = { data: { titulo: "Borrar Festivo", mensaje: '¿Desea borrar el festivo?, esto afectará al calendario' } }
    this.dialogRef = this._matDialog.open(AsConfirmComponent, config);
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this._calendariosService.borrarFestivo(row).subscribe((data) => {
          this._matSnackBar.open('Festivo eliminado', '');
          this._matDialogRef.close(true);
        }, (error) => {
          this._matSnackBar.open(error.error.mesage, 'Error', this.snackbarConfig)
        });
      }
    })
  }

  onSubmit() {
    var row = this.festivoFormGroup.getRawValue();
    this._calendariosService.updateFestivo(row).subscribe((data) => {
      this._matDialogRef.close(true);
      this._matSnackBar.open('Festivo actualizado', '');

    }, (error) => {
      this._matSnackBar.open('Error al actualizar el festivo', 'Error', this.snackbarConfig);
    });
  }

}
