import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TrabajadoresService } from '../trabajadores.service';
import { AsSpinnerService } from 'src/app/_common/common-components/as-spinner/as-spinner.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Component({
  selector: 'app-empleados-roles',
  templateUrl: './empleados-roles.component.html',
  styleUrls: ['./empleados-roles.component.css']
})
export class EmpleadosRolesComponent implements OnInit,OnChanges {
  public roles;
  public columnas = ["Rol","Descripcion", "Activo"];
  @Input() empleado: any;
  constructor(private _trabajadoresService: TrabajadoresService,
      private _asSpinnerService: AsSpinnerService,
      private _matSnackBar : MatSnackBar
    ) { }

  ngOnInit() {
   
  }

  ngOnChanges(changes : SimpleChanges) {
    if (changes['empleado'] && this.empleado) {
      this.getRoles(this.empleado.id);
    }
  }
  getRoles(id) {
    this._trabajadoresService.getRolesEmpleado(id).subscribe((data) => {
      this.roles = data;
    });
  }

  actualizaRol(row, checked) {
    this._asSpinnerService.enable();
    var rol = {
      roleId: row.roleId,
      empleadoId: row.empleadoId,
      usuarioWebId: row.usuarioWebId,
      activo: checked
    };
    this._trabajadoresService.actualizarRol(rol).subscribe((data) => {
      this._asSpinnerService.disable();
      this._matSnackBar.open("Rol actualizado correctamente", '');
    }, (error) => {
      let sbConfig = new MatSnackBarConfig();
      sbConfig.horizontalPosition = 'right';
        sbConfig.verticalPosition = 'top';
        sbConfig.duration = 3000;
      sbConfig.panelClass = ['error-snackbar']

      this._asSpinnerService.disable();
      this._matSnackBar.open("Error al actualizar rol", 'Error', sbConfig);
    });
  }
}
