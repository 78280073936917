import { Component, ViewChild, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms'
import { ReportingService } from '../reporting.service'
import { Router } from '@angular/router'
import { MatTableDataSource, MatTable, MatSnackBar, MatSnackBarConfig, MatDatepickerInputEvent } from '@angular/material'
import { FichajesService } from '../../fichajes/fichajes.service'
import { ProjectService } from '../../proyectos/services/project.service'
import { TaskService } from '../../proyectos/services/task.service'
import { TrabajadoresService } from '../../trabajadores/trabajadores.service'
import { DateProvider } from '../../_common/common-classes/date-provider'
import { DateDiff } from '../../_common/common-classes/date-diff'
import * as XLSX from 'xlsx'
import * as moment from 'moment'
import { TenantProvider } from '../../_conf/tenant.service'
import { ProgressSpinnerMode, MatProgressSpinnerModule } from '@angular/material/progress-spinner'

@Component({
  selector: 'app-report-empleados-horas',
  templateUrl: './report-empleados-horas.component.html',
  styleUrls: ['./report-empleados-horas.component.css']
})
export class ReportEmpleadosHorasComponent implements OnInit {
  public filtrosFormGroup: FormGroup
  public DateLimits: any
  public horarios: any
  public fichajes: any
  public punchingList: any[]
  private projects: any
  private tasks: any
  private _workers: any
  public workers: any
  public chartVisible: boolean = false
  public columns = ['Empleado', 'Proyecto', 'FechaEntrada', 'HoraEntrada', 'FechaSalida', 'HoraSalida', 'TiempoFichaje']
  public empleado_cache = ''
  public filterByProject = true

  public agrupaciones = ['Día', 'Semana', 'Mes', 'Año']
  public empleados
  private snackbarConfig: MatSnackBarConfig
  public dateProvider = new DateProvider()
  private fromDate: Date
  private toDate: Date
  public minDate: Date
  public maxDate: Date
  public loading = false
  
  //#region Punching summaries
  @ViewChild('dataTable') public dataTable: MatTable<any>
  public projectDataSource: MatTableDataSource<ItemModel>
  public workerDataSource: MatTableDataSource<ItemModel>
  public workCenterDataSource: MatTableDataSource<ItemModel>
  public projectPunchingSums = {}
  public workerPunchingSums: any = {}
  public workCenterPunchingSums: any = {}
  public workerExpectedTime = []
  public projectSummaryColumns = ['Proyecto', 'Horas', 'Periodo']
  public workCenterSummaryColumns = ['Centro de Trabajo', 'Nº de empleados', 'Horas', 'Periodo']
  public workerSummaryColumns = ['Empleado', 'HorasFichadas', 'HorasEstipuladas', 'Periodo']
  public workCenters = []
  //#endregion

  private colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  }

  constructor(
    private _fichajesService: FichajesService,
    private _projectService: ProjectService,
    private _taskService: TaskService,
    private _workerService: TrabajadoresService,
    private _matSnakbar: MatSnackBar,
    private _formBuilder: FormBuilder,
    private _reportingService: ReportingService,
    private _router: Router
  ) {
    this.dateProvider.setMinMaxDates()
    this.minDate = this.dateProvider.minDate
    this.maxDate = this.dateProvider.maxDate
  }

  ngOnInit() {
    this._reportingService.getFirstAndLastDatesOfPreviousMonth().subscribe((data: any) => {
      this.DateLimits = data
      this.fillForm()
    })
    this.setSnackbarConfig()
    this._projectService.getAvailableProjectList().subscribe((projects: any) => {
      this.projects = projects
    })
    this._taskService.getAvailableTaskList().subscribe((tasks: any) => {
      this.tasks = tasks
    })
    this._workerService.getWorkers().subscribe((data: any) => {
      this._workers = data
      this.workers = data
    })
    this._workerService.getCentrosTrabajo().subscribe((data: any) => {
      this.workCenters = data
    })
    this.filterByProject =  this._workerService.tenantUsesPinID()

    this.filtrosFormGroup = this._formBuilder.group({
      empleado: new FormControl(),
      project: new FormControl(),
      workCenter: new FormControl(),
      fechaDesde: new FormControl(),
      fechaHasta: new FormControl(),
      agrupacion: new FormControl({}, Validators.required)
    })

    this.filtrosFormGroup.valueChanges.subscribe((formData: any) => {
      if (this.workCenters.find(w => w.id === formData.workCenter)) {
        this.setWorkers(formData.workCenter)
      }
    })
  }

  private setWorkers(workCenterId) {
    this.workers = this._workers.filter(w => w.centroTrabajo === workCenterId)
    if (this.filtrosFormGroup.controls.empleado.value) {
      this.filtrosFormGroup.patchValue({empleado: null})
    }
  }

  private fillForm() {
    this.filtrosFormGroup.setValue({
      empleado: null,
      project: null,
      workCenter: null,
      fechaDesde: this.DateLimits.fromDate,
      fechaHasta: this.DateLimits.toDate,
      agrupacion: 'Día'
    })
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig()
    this.snackbarConfig.horizontalPosition = 'right'
    this.snackbarConfig.duration = 3000
    this.snackbarConfig.verticalPosition = 'top'
    this.snackbarConfig.panelClass = ['error-snackbar']
  }

  onSelect(event) {
    console.log('onSelect', event)
  }

  private sortDataByDate(data: any) {
    return data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
  }

  private sortDataById(data: any) {
    if (!this.filterByProject ) {
      data = this.filterByWorkCenter(data)
    }
    return data.sort((a, b) => a.entradaId - b.entradaId)
  }

  private filterByWorkCenter(data: any) {
    if (!this.filtrosFormGroup.value.workCenter)
      return data
    const oldData = data.length
    const workCenterId = this.filtrosFormGroup.value.workCenter
    const workerIds = this._workers.filter(w => w.centroTrabajo === workCenterId).map(w => w.id)
    data = data.filter((it) => workerIds.includes(it.empleadoId))
    return data

  }

  private showGraph(formData) {
    this._reportingService.empleadosHoras(formData).subscribe((filterData: any) => {
      let h = filterData.find(x => x.nombre == "Horario")
      let f = filterData.find(x => x.nombre == "Fichajes")
      this.horarios = [{
        name: 'Horas estipuladas',
        series: this.sortDataByDate(h.datos)
      }]
      this.fichajes = this.sortDataByDate(f.datos)

      var minimo = h.min
      if (f.min < minimo) {
        minimo = f.min
      }
      var maximo = h.max
      if (f.max > maximo)
        maximo = f.max
      console.log(`min: ${minimo} - max: ${maximo}`)
      this.chartVisible = true

    /*
      this.yLeftScaleFactor = function (minimo, maximo) {
        return { min: `${0}`, max: `${100}` }
      }
      this.yRightScaleFactor = function (minimo, maximo) {
        return { min: `${0}`, max: `${100}` }
      }
    */
    })
  }

  public getFilename() {
    let filename = 'informe_horas'
    var formData = this.filtrosFormGroup.getRawValue()
    let project = this.getProject(formData.project)
    let worker = this.getWorker(formData.empleado)
    let startDate = new Date(formData.fechaDesde)
    let month = `${startDate.getMonth()+1}`.padStart(2, '0')
    filename += `_${startDate.getFullYear()}_${month}`
    if (project)
      filename = `${filename}_${project.toLowerCase().replace(/ +/g, '_')}`
    if (!project && worker)
      filename = `${filename}_${worker.toLowerCase().replace(/ +/g, '_')}`
    // TODO: reemplazar letras acentuadas por simples y caracteres no ascii por '_' o cadena vacía ''


    return filename
  }

  public getProject(projId: number) {
    if (!projId)
      return
    let proj = this.projects.find(proj => proj.id == projId)
    if (proj)
      return proj.nombre
    else
      return 'PROYECTO FINALIZADO O BORRADO'
  }

  getWorkCenter(item: any) {
    const workCenter = this.workCenters.find(w => w.id === parseInt(item.id))
    if (workCenter)
      return workCenter.nombre
    return ''
  }

  getProjectOrWorkCenter(item: any) {
    if (this.filterByProject)
      return this.getProject(item.id)
    else {
      const workCenter = this.workCenters.find(w => w.id === item.id)
      if (workCenter)
        return workCenter.nombre
    }
    return ''
  }

  public getTask(taskId: number) {
    if (!taskId)
        return
    let task = this.tasks.find(task => task.id == taskId)
    if (task)
      return task.descripcion
    else
      return 'TAREA FINALIZADA O BORRADA'
  }

  public getKey(evt) {
    if (evt.key === 'Escape') {
      evt.target.value = null
      if (evt.target.id === 'mat-select-0')
        this.filtrosFormGroup.patchValue({ empleado: null })
      else
        this.filtrosFormGroup.patchValue({ workCenter: null })

    }
  }

  public getWorker(workerId: number) {
    if (!workerId)
      return
    let worker = this._workers.find(worker => worker.id == workerId)
    if (worker)
      return `${worker.nombre} ${worker.apellido1} ${worker.apellido2}`.trim()
    else
      return 'EMPLEADO BORRADO'
  }

  public dateChanges(evt: MatDatepickerInputEvent<Date>) {
    let dateProvider = new DateProvider()
    dateProvider.fromEvent(evt)
    this.filtrosFormGroup.controls.fechaHasta.setValue(dateProvider.toDate)
  }

  private getTimeDiff(item: any) {
    let entry = new Date(item.fechaRegistroSalida)
    let exit = new Date(item.fechaRegistroEntrada)
    return entry.getTime() - exit.getTime()
  }

  public getTimeDiffToString(diff: number, showDays: boolean = true, showMinutes: boolean = true) {
    let dateDiff = new DateDiff(diff, showDays, showMinutes)
    return dateDiff.toString()
  }

  public getPunchingTime(item, showDays=true, showMinutes=true) {
    let diff = this.getTimeDiff(item)
    return this.getTimeDiffToString(diff, showDays, showMinutes)
  }

  private getPunchings(formData: any) {
    const punchingFilter = {
      workerId: formData.empleado,
      projectId: formData.project,
      fromDate: formData.fechaDesde,
      toDate: formData.fechaHasta
    }
    this.loading = true

    this._fichajesService.getPunchingFilter(punchingFilter).subscribe(
      (filterData: any) => {
        this.loading = false
        this.workerPunchingSums = {}
        this.projectPunchingSums = {}
        this.punchingList = this.sortDataById(filterData.punchingList)
        this.workerExpectedTime = filterData.employeeExpectedTimeList
        for (const item of this.filterByWorkCenter(filterData.punchingList)) {
          const diff = this.getTimeDiff(item)

          if (this.filterByProject) {
            // Fichajes por proyectos.
            let projId = item.proyectoId
            if (!this.projectPunchingSums[projId])
              this.projectPunchingSums[projId] = 0
            this.projectPunchingSums[projId] += diff
          }

          // Fichajes por trabajadores.
          const workerId = item.empleadoId
          if (!this.workerPunchingSums[workerId])
            this.workerPunchingSums[workerId] = 0
          this.workerPunchingSums[workerId] += diff

          // Fichajes por centros de trabajo
          if (!this.filterByProject) {
            let worker = this._workers.find(e => e.id === workerId)
            if (worker) {
              const workCenter = this.workCenters.find(w => w.id === worker.centroTrabajo)
              if (!this.workCenterPunchingSums[workCenter.id])
                this.workCenterPunchingSums[workCenter.id] = 0
              this.workCenterPunchingSums[workCenter.id] += diff
            }
          }
        }
        // Fichajes por trabajadores para datos de la MatTable
        let data: ItemModel[] = []
        for (let [key, val] of Object.entries(this.workerPunchingSums)) {
          const itemModel = new ItemModel()
          const intkey = parseInt(key)
          itemModel.id = key
          itemModel.time = val
          if (this.workerPunchingSums[intkey]) {
            const found = this.workerExpectedTime.find(it => it.id == intkey)
            itemModel.expected_time = found.expectedTime
          }
          data.push(itemModel)
        }
        this.workerDataSource = new MatTableDataSource<ItemModel>(data)

        // Fichajes por proyectos para datos de la MatTable
        data = []
        for (let [key, val] of Object.entries(this.projectPunchingSums)) {
          const itemModel = new ItemModel()
          itemModel.id = key
          itemModel.time = val
          data.push(itemModel)
        }
        this.projectDataSource = new MatTableDataSource<ItemModel>(data)

        // Fichajes por centros de trabajo para datos de la MatTable
        data = []
        for (let [key, val] of Object.entries(this.workCenterPunchingSums)) {
          const itemModel = new ItemModel()
          itemModel.id = key
          itemModel.time = val
          itemModel.employees = this._workers.filter(w => w.centroTrabajo === parseInt(key)).length
          data.push(itemModel)
        }
        this.workCenterDataSource = new MatTableDataSource<ItemModel>(data)
      },
      (error) => {
        this.loading = false
      })

  }

  private checkDates(): boolean {
    let d1 = new Date(this.fromDate)
    let d2 = new Date(this.toDate)
    if (d2 < d1) {
      this._matSnakbar.open('El campo Fecha Hasta debe ser mayor que Fecha Desde.', 'Error', this.snackbarConfig)
      return false
    }
    return true
  }

  onSubmit() {
    var formData = this.filtrosFormGroup.getRawValue()
    this.fromDate = formData.fechaDesde
    this.toDate = formData.fechaHasta

    if (this.checkDates()) {

      if (formData.empleado) {
        this.showGraph(formData)
      } else {
        this.chartVisible = false
      }
      this.getPunchings(formData)
    }
  }

  cancelarEdicion() {
    this._router.navigateByUrl('/')
    return false
  }

  exportToExcel() {
    let dataByUsers: object = {}
    for (let item of this.punchingList) {
      if (!dataByUsers[item.empleadoId])
        dataByUsers[item.empleadoId] = []
      item.task = this.getProject(item.proyectoId)
      item.project = this.getTask(item.tareaId)
      item.workingTime = this.getPunchingTime(item)
      let workerEntry = {
        "Fecha Entrada": moment(new Date(item.fechaEntrada)).format("DD/MM/YYYY"),
        "Hora Entrada": item.horaEntrada.split('.')[0],
        "Fecha Salida": moment(new Date(item.fechaSalida)).format("DD/MM/YYYY"),
        "Hora Salida": item.horaSalida.split('.')[0],
        "Proyecto": this.getProject(item.proyectoId),
        "Tarea": this.getTask(item.tareaId),
        "Tiempo Fichaje": this.getPunchingTime(item)
      }
      dataByUsers[item.empleadoId].push(workerEntry)
    }
    // []{}
    const workbook: XLSX.WorkBook = XLSX.utils.book_new()
    for (let [workerID, items] of Object.entries(dataByUsers)) {
      let workerName = this.getWorker(parseInt(workerID))
      if (workerName === 'EMPLEADO BORRADO')
        continue
      const worksheet = XLSX.utils.json_to_sheet(items)
      XLSX.utils.sheet_add_aoa(
        worksheet,
        [['', '', '', '', '', 'total', this.getTimeDiffToString(this.workerPunchingSums[workerID], false, true)]],
        { origin: -1 }
      )
      XLSX.utils.book_append_sheet(workbook, worksheet, workerName)
    }
    XLSX.writeFile(workbook, `${this.getFilename()}.xlsx`)
  }

  //#region Graph
  yLeftScaleFactor
  yRightScaleFactor

  showXAxis = true
  showYAxis = true
  gradient = false
  showLegend = true
  legendTitle = 'Leyenda'
  legendPosition = 'right'
  showXAxisLabel = true
  xAxisLabel = 'Fecha'
  showYAxisLabel = true
  yAxisLabel = 'Horas fichadas'
  showGridLines = true
  innerPadding = '10%'
  animations: boolean = true
  barChart: any[] = this.fichajes
  lineChartSeries: any[] = this.horarios
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  }

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b']
  }

  showRightYAxisLabel: boolean = true
  yAxisLabelRight: string = ''
}

export let lineChartSeries = [
  {
    name: 'Tablets',
    series: [
      {
        name: 'USA',
        value: 50
      },
      {
        name :'ES',
        value: 100
      }
    ]
  }]

export let barChart: any = [
  {
    name: 'USA',
    value: 50000
  }, {
    name: 'ES',
    value: 6000
  }
]
//#endregion

export class ItemModel {
  id: string
  time: any
  expected_time: number
  employees: number
}

