import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '@angular/cdk/layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    MatToolbarModule, MatButtonModule, MatSidenavModule, MatIconModule, MatListModule, MatGridListModule, MatCardModule, MatMenuModule, MatFormFieldModule, MatInputModule, MatProgressSpinnerModule, MatSnackBarModule
  , MatTableModule, MatCheckboxModule, MatSlideToggleModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule, MatExpansionModule, MatDialogModule, MatCalendar, MatInput, MatSelectModule, MatSelect   
} from '@angular/material';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule } from '@angular/material-moment-adapter';
import { AsSrcDirective, AsHrefDirective } from '../common-directives/as-src.directive';
import { AsDragDropDirective } from '../common-components/as-file-upload/as-drag-drop.directive';


@NgModule({
  imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      LayoutModule,
      FormsModule,
      ReactiveFormsModule,
      MatToolbarModule,
      MatButtonModule,
      MatSidenavModule,
      MatIconModule,
      MatListModule,
      MatGridListModule,
      MatCardModule,
      MatMenuModule,
      MatFormFieldModule,
      MatInputModule,
      MatProgressSpinnerModule,
      MatSnackBarModule,
      MatTableModule,
      MatCheckboxModule,
      MatSlideToggleModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatAutocompleteModule,
      MatExpansionModule,
      MatDialogModule,
      MatMomentDateModule,
      MatInputModule,
      MatSelectModule
    ],
    exports: [      
        BrowserModule,
        BrowserAnimationsModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatGridListModule,
        MatCardModule,
        MatMenuModule,
        MatFormFieldModule,
      MatInputModule,
        MatSelect,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTableModule,
        MatCheckboxModule,
        MatSlideToggleModule,
      MatDatepickerModule, 
      MatCalendar,
        MatNativeDateModule,
        MatAutocompleteModule,
        MatExpansionModule,
      MatDialogModule,
      MatMomentDateModule,
      AsSrcDirective,
      AsHrefDirective,
      AsDragDropDirective

    ],
    declarations: [AsSrcDirective,AsHrefDirective,AsDragDropDirective],
  providers: [
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { horizontalPosition: 'rigth', verticalPosition: 'top', duration: 2500 } },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ]
})
export class CommonFormsModule { }
