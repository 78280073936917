import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { CalendariosService } from '../calendarios.service';
import { MatSnackBar, MatDialog, MatSnackBarConfig, MatDialogRef } from '@angular/material';

import { MAT_DIALOG_DATA } from '@angular/material'
import { AsConfirmComponent } from 'src/app/_common/common-components/as-confirm/as-confirm.component';
import { TaskService } from '../../proyectos/services/task.service';
import { ProjectService } from '../../proyectos/services/project.service';

@Component({
  selector: 'app-calendario-ficha',
  templateUrl: './calendario-ficha.component.html',
  styleUrls: ['./calendario-ficha.component.css']
})
export class CalendarioFichaComponent implements OnInit {
  public calendarFormGroup: FormGroup
  private snackbarConfig: MatSnackBarConfig
  public confirmDialogRef: MatDialogRef<AsConfirmComponent>
  public tasks: any
  public projects: any
  public projectChanged: string
  public action = ''
  public from = ''

  // binding
  public _valorPropiedad;
  @Input() valorPropiedad: number;
  @Output() valorPropiedadChange = new EventEmitter();
  propagateChange = (_: any) => { }
  onTouch = () => { }

  constructor(
    private _formBuilder: FormBuilder,
    private _calendarService: CalendariosService,
    private _matSnackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private _selfDialogRef: MatDialogRef<CalendarioFichaComponent>,
    private _projectService: ProjectService,
    private _taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public incomingData: any
  ) {
    if (this.incomingData === 'undefined') {
      this.incomingData = {
        id: null,
        name: '',
        description: '',
        action: null
      }
    } else {
      this.action = this.incomingData.action == 'copy' ? 'Copiar' : 'Modificar'
      this.from = this.incomingData.action == 'copy' ? ` según ${this.incomingData.nombre}` : ''
    }
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig()
    this.snackbarConfig.verticalPosition = 'top'
    this.snackbarConfig.horizontalPosition = 'right'
    this.snackbarConfig.duration = 3000
    this.snackbarConfig.panelClass = ['error-snackbar']
  }

  ngOnInit() {
    this.setSnackbarConfig()
    this.initializeForm()
    this.fillFormData()
  }

  private initializeForm() {
    this.calendarFormGroup = this._formBuilder.group({
      id: new FormControl({value: null, disabled: true}, Validators.required),
      name: new FormControl({ value: null, disabled: false}),
      description: new FormControl({value: null, disabled: false})
    });
  }

  private fillFormData() {
    this.calendarFormGroup.setValue({
      id: this.incomingData.id,
      name: this.incomingData.action == 'edit' ? this.incomingData.nombre : '' ,
      description: this.incomingData.action == 'edit' ? this.incomingData.descripcion : ''
    });
  }
  private checkChanges() {
    var formData = this.calendarFormGroup.getRawValue();
    let changes = [
      formData.name == this.incomingData.nombre,
      formData.description == this.incomingData.descripcion
    ]
    if (changes.every(Boolean)) {
      this._matSnackBar.open('No ha habido cambios', '')
      return false;
    }
    return true
  }

  onSubmit() {
    if (this.checkChanges()) {
      var formData = this.calendarFormGroup.getRawValue();
      formData.cancelled = false
      formData.action = this.incomingData.action
      this._selfDialogRef.close(formData)
    }
  }

  cancel() {
    var formData = this.calendarFormGroup.getRawValue();
    formData.cancelled = true
    let action = this.incomingData.action == 'edit' ? 'Modificación' : 'Copia'
    this._matSnackBar.open(`${action} cancelada.`, 'Notificación', this.snackbarConfig)
    this._selfDialogRef.close(formData)
  }

}

