import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { AccountProvider } from '../services/user.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TenantProvider } from 'src/app/_conf/tenant.service';
import { tr } from 'date-fns/esm/locale';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
  animations: fuseAnimations
})
export class ResetPasswordComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public tenant;
  public pwd1Icon: string;
  public pwd1Visibility: boolean;
  public pwd2Icon: string;
  public pwd2Visibility: boolean;
  public pwd1VisibilityClicked: boolean;
  public pwd2VisibilityClicked: boolean;
  
  // Private
  private _unsubscribeAll: Subject<any>;
  private snackbarConfig: MatSnackBarConfig;
  private workerId: number;
  private token: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
      private _fuseConfigService: FuseConfigService,
      private _formBuilder: FormBuilder,
      private _matSnackBar: MatSnackBar,
      private _tenantProvider: TenantProvider,
      private _accountProvider: AccountProvider,
      private _activatedRoute: ActivatedRoute,
      private _router: Router

    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
      };
      this.pwd1VisibilityClicked = false;
      this.pwd2VisibilityClicked = false;
    }

    /**
     * On init
     */
  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.workerId = params['workerId'];
      this.token = params['token'];
    });

      this.snackbarConfig = new MatSnackBarConfig();
      this.snackbarConfig.horizontalPosition = 'right';
      this.snackbarConfig.verticalPosition = 'top';
      this.snackbarConfig.duration = 3000;
      this.snackbarConfig.panelClass = ['error-snackbar']

      this.pwd2Icon = 'visibility';
      this.pwd2Visibility = false;
      this.pwd1Icon = 'visibility';
      this.pwd1Visibility = false;

      this.resetPasswordForm = this._formBuilder.group({
        password1: new FormControl({ value: '', disabled: false }, Validators.required),
        password2: new FormControl({ value: '', disabled: false }, Validators.required)
      });

      this.tenant = this._tenantProvider.getTenant();
  }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

  onSubmit() {
    var pwd1 = this.resetPasswordForm.value.password1;
    var pwd2 = this.resetPasswordForm.value.password2;
    let data = {
      userId: this.workerId,
      newPassword: pwd1,
      newPasswordRepeat: pwd2,
      token: this.token
    }
    let config = this.snackbarConfig
    this._accountProvider.resetPassword(data).subscribe(
      (resp: any) => {
        if (resp.ok) {
          config.panelClass = []
          this._matSnackBar.open("Nueva contraseña modificada, puede iniciar sesión.", '', config);
          this._router.navigateByUrl('/')
        }
      },
      (error) => {
        this._matSnackBar.open(`Ha ocurrido un error: ${error}`, 'Error', config);
      }
    );
  }

  // #region Visibilidad carácteres de contraseñas
  public toggleVisibility(evt) {
    let target = evt.target.attributes.name.nodeValue
    let visibility = target == 'pwd1' ? this.pwd1Visibility : this.pwd2Visibility;
    let visibilityClicked = target == 'pwd1' ? this.pwd1VisibilityClicked : this.pwd2VisibilityClicked;
    let mouseleave = evt.type == 'mouseleave'
    if (visibilityClicked || (!visibilityClicked && mouseleave && !visibility))
      return;
    if (target == 'pwd1') {
      this.pwd1Visibility = !visibility;
      this.pwd1Icon = this.pwd1Visibility ? 'visibility_off' : 'visibility';
    } else {
      this.pwd2Visibility = !visibility;
      this.pwd2Icon = this.pwd2Visibility ? 'visibility_off' : 'visibility';
    }
  }

  public password1VisibilityClicked() {
    if (this.pwd1VisibilityClicked && this.pwd1Visibility) {
      this.pwd1Icon = 'visibility';
      this.pwd1Visibility = false;
      this.pwd1VisibilityClicked = false;
      return
    }
    this.pwd1VisibilityClicked = true;

  }

  public password2VisibilityClicked() {
    if (this.pwd2VisibilityClicked && this.pwd2Visibility) {
      this.pwd2Icon = 'visibility';
      this.pwd2Visibility = false;
      this.pwd2VisibilityClicked = false;
      return
    }
    this.pwd2VisibilityClicked = true;

  }
  // #endregion

  public checkForm() {
    let controls = this.resetPasswordForm.controls;
    let valid = controls.password1.value === controls.password2.value

    return !valid;
  }
}
