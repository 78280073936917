import { NgModule } from '@angular/core';
import { ComboChartComponent, ComboSeriesVerticalComponent } from './_common/custom-charts/combo-chart';
import { NgxChartsModule } from '@swimlane/ngx-charts';


@NgModule({
  imports: [
    NgxChartsModule,
  ],
  exports: [
    NgxChartsModule,
    ComboChartComponent,
    ComboSeriesVerticalComponent
  ],
  declarations: [
    ComboChartComponent,
    ComboSeriesVerticalComponent
  ]
})
export class SharedModule { }
