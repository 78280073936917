import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CalendariosService } from '../calendarios.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CalendariosHorariosFichaComponent } from '../calendarios-horarios-ficha/calendarios-horarios-ficha.component';

@Component({
  selector: 'app-calendarios-horarios',
  templateUrl: './calendarios-horarios.component.html',
  styleUrls: ['./calendarios-horarios.component.css']
})
export class CalendariosHorariosComponent implements OnInit {

  @Input() horarios: any;
  @Input() tipos: any;
  @Input() dias: any;
  @Input() calendarioId: number;
  @Output() horarioChange = new EventEmitter();
  public isEditMode: boolean = false;
  constructor(
    private _calendarioService: CalendariosService,
    private _formBuilder: FormBuilder,
    private _matDialog: MatDialog) { }

  ngOnInit() {

  }

  //public columnas = ["Id","Tipo", "FechaInicio","FechaFin", "Numero", "Fecha","Turno","HoraInicio","HoraFin"];
  public columnas = ["Tipo", "Descripcion", "Turno", "HoraInicio", "HoraFin"];


  getHorarios() {
    
  }
  public dialogRef: MatDialogRef<CalendariosHorariosFichaComponent>;

  setItemEditing(row) {
    this.dialogRef = this._matDialog.open(CalendariosHorariosFichaComponent, {
      minWidth: 600, data: {
        horario: row,
        tipos: this.tipos,
        dias : this.dias
      }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if(data === true)
        this.horarioChange.emit();
    });

  }

  nuevo() {
    this.dialogRef = this._matDialog.open(CalendariosHorariosFichaComponent, {
      minWidth: 600,
      data: {
        tipos: this.tipos,
        dias: this.dias,
        horario: { id: 0, calendarioId: this.calendarioId }
      }
    });

    this.dialogRef.afterClosed().subscribe((data) => {
      if (data === true)
        this.horarioChange.emit();
    });
  }
}
