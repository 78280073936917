import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { CalendarModule } from 'angular-calendar';

import { CalendariosModule } from '../calendarios/calendarios.module';

import { TrabajadoresListaComponent } from './trabajadores-lista/trabajadores-lista.component';
import { TrabajadoresFichaComponent } from './trabajadores-ficha/trabajadores-ficha.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_auth/auth.guard';
import { EmpleadosFichajesComponent } from './empleados-fichajes/empleados-fichajes.component';
import { EmpleadosSolicitudesComponent } from './empleados-solicitudes/empleados-solicitudes.component';
import { EmpleadosSolicitudesFichaComponent } from './empleados-solicitudes-ficha/empleados-solicitudes-ficha.component';
import { EmpleadoSolicitudesPropiasComponent } from './empleado-solicitudes-propias/empleado-solicitudes-propias.component';
import { EmpleadosSelectorComponent } from './empleados-selector/empleados-selector.component';
import { EmpleadoCalendarioComponent } from './empleado-calendario/empleado-calendario.component';
import { DepartamentosComponent } from './departamentos/departamentos.component';
import { DepartamentosFichaComponent } from './departamentos-ficha/departamentos-ficha.component';
import { DepartamentosSelectorComponent } from './departamentos-selector/departamentos-selector.component';
import { EmpleadosRolesComponent } from './empleados-roles/empleados-roles.component';
import { EmpresaFichaComponent } from './empresa-ficha/empresa-ficha.component';
import { CentrosTrabajoListaComponent } from './centros-trabajo-lista/centros-trabajo-lista.component';
import { CentroTrabajoFichaComponent } from './centro-trabajo-ficha/centro-trabajo-ficha.component';
import { CentroTrabajoSelectorComponent } from './centro-trabajo-selector/centro-trabajo-selector.component';
import { EmpleadosImportarComponent } from './empleados-importar/empleados-importar.component';
import { AsFileUploadComponent } from '../_common/common-components/as-file-upload/as-file-upload.component';
import { AdminGuardService } from '../users/services/admin-guard.service';
import { SelfAccountGuardService } from '../users/services/self-account-guard.service';
import { AdminOrManagerGuardService } from '../users/services/admin-or-manager-guard.service';
import { EmpleadoSolicitudFichaComponent } from './empleado-solicitud/empleado-solicitud-ficha.component';
import { CalendarioVacacionesComponent } from './calendario-vacaciones/calendario-vacaciones.component';
import { TrabajadoresTodosComponent } from './trabajadores-todos/trabajadores-todos.component';


const routes: Routes = [

  {
    path: 'empleados',
    component: TrabajadoresListaComponent,
    canActivate: [AdminOrManagerGuardService],
    data: {
      title: 'Lista empleados'
    }
  },
  {
    path: 'empleados/todos',
    component: TrabajadoresTodosComponent,
    data: {
      title: 'Todos los usuarios',
    },
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'empleado/:id',
    component: TrabajadoresFichaComponent,
    data: {
      title: 'Datos empleado',
    },
    canActivate: [SelfAccountGuardService] // Debe permitir que el propio usuario vea su perfil pero no el de otros
  },
  {
    path: 'importar-empleados',
    component: EmpleadosImportarComponent,
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'nuevo-empleado',
    component: TrabajadoresFichaComponent,
    data: {
      title: 'Nuevo empleado',
      parent: 'empleados'
    },
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'empleado-solicitudes',
    component: EmpleadosSolicitudesComponent,
    data: {
      title: 'Gestión días libres'
    },
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'empleado-solicitudes/:workerId',
    component: EmpleadosSolicitudesComponent,
    data: {
      title: 'Gestión días libres'
    },
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'empleado-solicitud-ficha',
    component: EmpleadoSolicitudFichaComponent,
    data: {
      title: 'Gestión días libres'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'solicitudes-propias',
    component: EmpleadoSolicitudesPropiasComponent,
    data: {
      title: 'Solicitudes propias de dias libres'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'nueva-solicitud',
    component: EmpleadosSolicitudesFichaComponent,
    data: {
      title: 'Nueva solicitud'
    },
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'editar-solicitud',
    component: EmpleadosSolicitudesFichaComponent,
    data: {
      title: 'Editar solicitud'
    },   
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'departamentos',
    component: DepartamentosComponent,
    data: {
      title: 'Departamentos'
    },
    canActivate: [AdminOrManagerGuardService]
  },
  {
    path: 'test',
    component: EmpleadoCalendarioComponent,
    canActivate: [AdminGuardService]
  },
  {
    path: 'empresa-ficha',
    component: EmpresaFichaComponent
  },
  {
    path: 'centros-trabajo',
    component: CentrosTrabajoListaComponent
  }
];
@NgModule({
  imports: [
    CommonModule,
    CommonFormsModule,
    RouterModule.forChild(routes),
    FuseModule,
    FuseSharedModule,
    CalendarModule,
    CalendariosModule
  ],
  exports: [
    EmpleadosSelectorComponent,
    EmpleadoCalendarioComponent,
  ],
  declarations: [
    AsFileUploadComponent,
    CalendarioVacacionesComponent, CentroTrabajoFichaComponent, CentroTrabajoSelectorComponent, CentrosTrabajoListaComponent,
    DepartamentosComponent, DepartamentosFichaComponent, DepartamentosSelectorComponent,
    EmpleadoCalendarioComponent, EmpleadoSolicitudFichaComponent, EmpleadoSolicitudesPropiasComponent, EmpleadosFichajesComponent,
    EmpleadosImportarComponent, EmpleadosRolesComponent, EmpleadosSelectorComponent, EmpleadosSolicitudesComponent,
    EmpleadosSolicitudesFichaComponent, EmpresaFichaComponent,
    TrabajadoresFichaComponent, TrabajadoresListaComponent, TrabajadoresTodosComponent
  ], 
  entryComponents: [DepartamentosFichaComponent, EmpresaFichaComponent, CentroTrabajoFichaComponent],
  providers : [DatePipe]
})
export class TrabajadoresModule { }
