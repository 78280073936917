import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { AccountProvider } from '../services/user.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TenantProvider } from 'src/app/_conf/tenant.service';

@Component({
    selector: 'reset-pinkey',
    templateUrl: './reset-pinkey.component.html',
    styleUrls: ['./reset-pinkey.component.scss'],
  animations: fuseAnimations
})
export class ResetPINKeyComponent implements OnInit {
  public resetPasswordForm: FormGroup;
  public tenant;
  public pk1Icon: string = 'visibility';
  public pk1Visibility: boolean = false;
  public pk1VisibilityClicked: boolean = false;
  public pk2Icon: string = 'visibility';
  public pk2Visibility: boolean = false;
  public pk2VisibilityClicked: boolean = false;
  
  // Private
  private _unsubscribeAll: Subject<any>;
  private snackbarConfig: MatSnackBarConfig;
  private workerId: number;
  private token: string;
  private pattern = /(Arrow(Down|Up|Left|Right)|Backspace|(Caps|Num|Scroll)Lock|Control|Delete|Enter|End|F\d+|Home|Page(Up|Down)|Pause|PrintScreen)|Shift|Tab/;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
      private _fuseConfigService: FuseConfigService,
      private _formBuilder: FormBuilder,
      private _matSnackBar: MatSnackBar,
      private _tenantProvider: TenantProvider,
      private _accountProvider: AccountProvider,
      private _activatedRoute: ActivatedRoute,
      private _router: Router

    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: false
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
            }
        }
    }

    /**
     * On init
     */
  ngOnInit(): void {
    this._activatedRoute.params.subscribe((params: Params) => {
      this.workerId = params['workerId'];
      this.token = params['token'];
    });

    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.panelClass = ['error-snackbar']

    this.resetPasswordForm = this._formBuilder.group({
        password1: new FormControl({ value: '', disabled: false }, Validators.required),
        password2: new FormControl({ value: '', disabled: false }, Validators.required)
    });

      this.tenant = this._tenantProvider.getTenant();
  }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

  onSubmit() {
    var mail = this.resetPasswordForm.value.email;
    var pwd1 = this.resetPasswordForm.value.password1;
    var pwd2 = this.resetPasswordForm.value.password2;
    let data = {
      workerId: this.workerId,
      newPinKey1: pwd1,
      newPinKey2: pwd2,
      token: this.token,
      email: mail
    }
    let config = this.snackbarConfig
    this._accountProvider.resetPinKey(data).subscribe(
      (resp: any) => {
        if (resp.ok) {
          config.panelClass = []
          this._matSnackBar.open("Clave de acceso rápido modificada, puede iniciar sesión.", '', config);
          this._router.navigateByUrl('/')
        }
      },
      (error) => {
        this._matSnackBar.open(`Ha ocurrido un error: ${error.error.message}`, 'Error', config);
      }
    );
  }

  // #region Visibilidad carácteres de clave de acceso (pinkey).
  public toggleVisibility(evt) {
    let target = evt.target.attributes.name.nodeValue;
    let visibility = target == 'pk1' ? this.pk1Visibility : this.pk2Visibility;
    let visibilityClicked = target == 'pk1' ? this.pk1VisibilityClicked : this.pk2VisibilityClicked;
    let mouseleave = evt.type == 'mouseleave';
    if (visibilityClicked || (!visibilityClicked && mouseleave && !visibility))
      return;
    if (target == 'pk1') {
      this.pk1Visibility = !visibility;
      this.pk1Icon = this.pk1Visibility ? 'visibility_off' : 'visibility';
    } else {
      this.pk2Visibility = !visibility;
      this.pk2Icon = this.pk2Visibility ? 'visibility_off' : 'visibility';
    }
  }

  public pinkey1VisibilityClicked() {
    if (this.pk1VisibilityClicked && this.pk1Visibility) {
      this.pk1Icon = 'visibility';
      this.pk1Visibility = false;
      this.pk1VisibilityClicked = false;
      return
    }
    this.pk1VisibilityClicked = true;
  }

  public pinkey2VisibilityClicked() {
    if (this.pk2VisibilityClicked && this.pk2Visibility) {
      this.pk2Icon = 'visibility';
      this.pk2Visibility = false;
      this.pk2VisibilityClicked = false;
      return
    }
    this.pk2VisibilityClicked = true;
  }
  // #endregion

  public checkForm() {
    let controls = this.resetPasswordForm.controls;
    var valid = controls.password1.value === controls.password2.value
    valid = valid && controls.password1.value != '' && controls.password2.value != ''
    return !valid ;
  }

  public checkKeyDown(evt: any) {
    let key = evt.key;
    if (!key) {
      return false
    } else if (this.pattern.test(key)) {
      return true
    } else if (key == 'Escape') {
      this.resetPasswordForm.patchValue({ pinKey: '' });
      return true;
    } else if (key.toLocaleUpperCase() == 'V') {
      this.toggleVisibility(evt);
      return false;
    } else if (/.*\D.*/.test(key)) {
      return false;
    }
  }

}
