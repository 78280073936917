import { Component, OnInit, EventEmitter, Input, Output, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ProjectService } from '../services/project.service';
import { CustomerService } from '../services/customer.service';
import { TaskService } from '../services/task.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { AsSpinnerService } from '../../_common/common-components/as-spinner/as-spinner.service';
import { AccountProvider } from '../../users/services/user.service';
import { User } from '../../users/model/user.model';
import { concat } from 'rxjs';
import { AsConfirmComponent } from '../../_common/common-components/as-confirm/as-confirm.component';

@Component({
  selector: 'app-proyecto-ficha',
  templateUrl: './proyectos-ficha.component.html',
  styleUrls: ['./proyectos-ficha.component.scss']
})
export class ProjectCardComponent implements OnInit {
  public errorMessage = '';
  private endpoint = '/lista-proyectos';
  public projectFormGroup: FormGroup;
  public projId: number;
  public project: any;
  public errorMsg: string;
  public user: User;
  public filteredCustomers: any;
  public customerIds: number[] = [];
  public _valorPropiedad;
  @Input() valorPropiedad: number;
  @Output() valorPropiedadChange = new EventEmitter();
  @Output() customerChange = new EventEmitter();
  propagateChange = (_: any) => { };
  onTouch = () => { }
  isDisabled: boolean;

  public tasks: any;
  public customers: any;
  public projectId: number;
  private snackbarConfig: MatSnackBarConfig;

  private deleteProjectBool: boolean;
  private confirmDialogRef: MatDialogRef<AsConfirmComponent>;
  private selectedCustomer: number;
  private customerId: number;


  constructor(
    private confirmDialog: MatDialog,
    private _formBuilder: FormBuilder,
    private _activatedRoute: ActivatedRoute,
    private _projectService: ProjectService,
    private _taskService: TaskService,
    private _customersService: CustomerService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _asSpinner: AsSpinnerService,
    private _accountProvider: AccountProvider,
    private cdRef: ChangeDetectorRef
  ) {
    this.setSnackbarConfig();
    this.deleteProjectBool = false;
    this._activatedRoute.params.subscribe(data => {
      this.projectId = data.id
    })
  }

  ngOnInit() {
    this.initializeForm();
    this.getFormData();
    this.user = this._accountProvider.getAccount();
    this.setEndpoint();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.panelClass = ['error-snackbar'];
  }

  private setEndpoint(): void {
    if (!this.user.isAdminWeb)
      this.endpoint = '/'
  }

  initializeForm() {
    this.projectFormGroup = this._formBuilder.group(
      {
        id: new FormControl({ value: 0, disabled: false }),
        nombre: new FormControl({ value: null, disabled: false }, Validators.required),
        descripcion: new FormControl({ value: null, disabled: false }),
        idCliente: new FormControl({ value: null, disabled: false }, Validators.required),
        tareaPrincipal: new FormControl({ value: null, disabled: false })
      });
  }


  private getFormData() {
    // No carga los datos a tiempo así que concateno las llamadas.
    var projectId = 0;
    this._activatedRoute.params.subscribe(params => {
      projectId = params.id
    })

    concat(
      this._customersService.getAvailableCustomerList(),
      this._taskService.getAvailableTaskList(),
      this._activatedRoute.params
    ).subscribe((data: any) => {
      var isParam = true;

      for (let item of data) {
        if ('nombre' in item) {
          this.setCustomers(data);
          isParam = false
          break;
        } else if ('codigo' in item) {
          this.tasks = data.filter(task => task.idProyecto == this.projectId)
          isParam = false
          break;
        }
      }

      if (isParam && 'id' in data) {
        let id = data.id
        this.projId = id
        this.fillFormData(id)
      }
    })
  }

  private setCustomers(customers) {
    this.customers = customers;
    this.filteredCustomers = customers;
    customers.forEach(cust => {
      this.customerIds.push(cust.id)
    });
  }

  private fillFormData(inID: number): void {
    this._projectService.getProjectCard(inID).subscribe((proj: any) => {
      proj = proj[0];
      let mainTask = this.tasks.find(task => task.idProyecto === proj.tareaPrincipal && !task.Borrada);
      this.project = proj;
      this.projectFormGroup.setValue({
        id: proj.id,
        nombre: proj.nombre,
        descripcion: proj.descripcion,
        idCliente: proj.idCliente,
        tareaPrincipal: mainTask ? proj.tareaPrincipal : null
      });
    })
  }

  public writeValue(obj: any): void {
    if (obj !== undefined) {
      this.valorPropiedad = obj;
      this._valorPropiedad = obj;
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public seleccionaValor() {
    this.valorPropiedadChange.emit(this.valorPropiedad); //Para bindar con ngModel y Output
    this.propagateChange(this.valorPropiedad);
    this.onTouch();
  }

  public selectCustomer(evt: any) {
    var selectedWorker = null
    if (this.customerIds.includes(this.customerId)) {
      selectedWorker = this.selectedCustomer
    } else if (this.customerId) {
      this.filteredCustomers = this.customers.filter(w => w.nombre.toLowerCase().trim().includes(`${this.customerId}`.trim()));
    } else {
      // cliente está vacío
      this.customerId = null;
      this.filteredCustomers = this.customers;
    }

    this.customerChange.emit(selectedWorker); //Para bindar con ngModel y Output
    this.propagateChange(selectedWorker);
    this.onTouch();
  }

  public displayFnMainTask(inTask: number) {
    var rtn = '';
    if (this.tasks) {
      let task = this.tasks.find(t => t.id == inTask);
      if (task)
        rtn = task.descripcion;
    }
    return rtn;
  }

  public displayFnCustomer(inCust: number) {
    var rtn = '';
    if (this.customers) {
      let customer = this.customers.find(c => c.id == inCust);
      if (customer)
        rtn = customer.nombre;
    }
    return rtn;
  }

  private validateData() {
    if (this.deleteProjectBool) {
      this.deleteProjectBool = false;
      return false;
    }
    let ctrl = this.projectFormGroup.controls

    // El cliente es incorrecto, no existe.
    if (typeof ctrl.idCliente.value != 'number') {
      this._matSnackBar.open('Debe selecionar un cliente del desplegable', 'Error', this.snackbarConfig);
      return false;
    }

    // La tarea es incorrecta, no existe.
    if (ctrl.tareaPrincipal.value && typeof ctrl.tareaPrincipal.value != 'number') {
      this._matSnackBar.open('Debe selecionar una tarea del desplegable', 'Error', this.snackbarConfig);
      return false;
    }

    // Es una alta de proyecto nuevo.
    if (!this.project)
      return true;

    let changes = [
      this.project.nombre == ctrl.nombre.value,
      this.project.descripcion == ctrl.descripcion.value,
      this.project.idCliente == ctrl.idCliente.value,
      this.project.tareaPrincipal == ctrl.tareaPrincipal.value
    ]

    // El formulario no se ha modificado
    if (changes.every(Boolean)) {
      this._matSnackBar.open('No ha habido cambios', '');
      return false;
    }
    return true;
  }

  onSubmit() {
    if (!this.validateData())
      return

    this._asSpinner.enable();
    let outData = this.projectFormGroup.getRawValue();

    this._projectService.addProject(outData).subscribe((inData) => {
      this._asSpinner.disable();
      this._matSnackBar.open('Proyecto actualizado', '');
      this._router.navigateByUrl(this.endpoint);
    }, (error) => {
        this._asSpinner.disable();
        this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
    });
  }

  public confirmDeleteProject(): void {
    this.deleteProjectBool = true;
    let action = { title: 'Borrar Cliente', question: 'Confirme que desea borrar el proyecto y sus tareas.' }
    let config = { data: { titulo: action.title, mensaje: action.question, } }
    this.confirmDialogRef = this.confirmDialog.open(AsConfirmComponent, config);
    this.confirmDialogRef.afterClosed().subscribe((resp) => {
      if (resp)
        this.deleteProject();
    });
  }

  private deleteProject(): void {
    this._asSpinner.enable();
    var formData = this.projectFormGroup.getRawValue();    
    this._projectService.deleteProject(formData).subscribe((data) => {
      this._asSpinner.disable();
      this._matSnackBar.open('Proyecto y tareas relativas eliminados', '');
      this.back();
    }, (error) => {
      this._asSpinner.disable();
        this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
    });
  }

  public cancel() {
    this.back();
  }

  public back() {
    this._router.navigateByUrl(this.endpoint);
  }

  public hasMainTask() {
    let mainTask = this.projectFormGroup.controls.tareaPrincipal.value
    return !!mainTask
  }

  public setTooltip() {
    return this.hasMainTask() ? "No se permite borrar un proyecto si hay una tarea principal asignada" : "";
  }
}
