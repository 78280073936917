import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';

import { VerticalLayout1Module,VerticalLayout1Component } from './vertical/layout-1/layout-1.module';
import { VerticalLayout2Module } from './vertical/layout-2/layout-2.module';
import { VerticalLayout3Module } from './vertical/layout-3/layout-3.module';

import { HorizontalLayout1Module } from './horizontal/layout-1/layout-1.module';

export { VerticalLayout1Component } from './vertical/layout-1/layout-1.module';
@NgModule({
    imports: [
        CommonModule,
        CommonFormsModule,
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,

        HorizontalLayout1Module,
       

    ],
    exports: [
        VerticalLayout1Module,
        VerticalLayout2Module,
        VerticalLayout3Module,

        HorizontalLayout1Module,
        VerticalLayout1Component
    ]
})
export class FuseLayoutModule
{
}
