import { Component, OnInit, OnDestroy } from '@angular/core';
import { TaskService } from '../services/task.service';
import { ProjectService } from '../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-tareas-lista',
    templateUrl: './tareas-lista.component.html',
    styleUrls: ['./tareas-lista.component.scss']
})
export class TareasListaComponent implements OnInit  {
  public projectId: number;
  public tasks: any;
  public statuses: any;
  public projects: any;
  public isMobile: boolean;
  public columns = ["DESCRIPCION", "PROYECTO", "ESTADO"]
  private taskList = '/lista-tareas';
  private taskCard = '/ficha-tarea/'
  private projectCard = '/ficha-proyecto/'
  private token = 'landingPageTkn';
  public nested = false;

  constructor(
    private _taskService: TaskService,
    private _projectService: ProjectService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this._activatedRoute.params.subscribe(data => {
      this.projectId = data.id
    })
  }

  ngOnInit() {
    this.loadTaskList();

    this._taskService.getTaskStatusList().subscribe(statuses => {
      this.statuses = statuses;
    })

    this._projectService.getProjectList().subscribe(projects => {
      // Cogemos todos los proyectos por si debemos mostrar TODAS las tareas
      this.projects = projects;
    })
  }

  private loadTaskList() {
    // Según la ruta de acceso, devolvemos las tareas pertenecientes a un proyecto, o las mostramos TODAS.
    let url = this._activatedRoute.snapshot['_routerState'].url
    this.nested = url.startsWith(this.projectCard) || url.endsWith('/alta-proyecto');

    if (this.projectId && url.startsWith(this.projectCard)) {
      // Las tareas pertenecen a un proyecto, estamos en /ficha-proyecto/:id
      this._taskService.getAvailableTaskListByProject({ id: this.projectId }).subscribe(tasks => {
        this.tasks = tasks;
      });
    } else if (url === this.taskList) {
      // Mostramos TODAS las tareas, estamos en /lista-tareas. Único sitio donde mostramos
      // TODAS las tareas.
      this._taskService.getTaskList().subscribe(tasks => {
        this.tasks = tasks;
      });
    }
    this.setEndpoint(url);
  }

  private setEndpoint(url) {
    sessionStorage.setItem(this.token, btoa(url))
  }

  public goToTaskCard(task: any) {
    let id = task.id;
    this._router.navigateByUrl(`${this.taskCard}${id}`);
  }

  public getStatus(id: number) {
    if (this.statuses && id > -1) {
      let status = this.statuses.find(statuses => statuses.id == id)
      return status.valor
    }
    return ''
  }

  public getProject(id: number) {
    if (this.projects && id > -1) {
      let project = this.projects.find(projects => projects.id == id)
      return project.nombre
    }
    return ''
  }

  public getClass(task) {
    switch (task.estado) {
      case 0:
        return 'pending-task';
      case 1:
        return 'working-task';
      case 2:
        return 'done-task';
      default:
        return 'default-task';
    }
  }
}

