export class CommentData {
  public commentId: number;
  public punchingInId: number;
  public comment: string;

  constructor(data: any) {
    this.commentId = data.comentarioId;
    this.punchingInId = data.entradaId;
    this.comment = data.comentario ? data.comentario.trim() : '';
  }

  public hasChanges(formData: any): boolean {
    var hasSameValue = formData.comment.trim() == this.comment;

    return !hasSameValue;
  }
}


export class Locator {
  public latitude: string;
  public longitude: string;
  public device: string;
  public message: string;
  public type: string;
  private _success = false;
  public get success() {
    return this._success
  }
  public get error() {
    return !this.success
  }
  private get geoOpts() {
    return {
      maximumAge: 0, timeout: 50000, enableHighAccuracy: true
    }
  }

  constructor() {
    this.device = window.navigator.platform;
    this.latitude = '0';
    this.longitude = '0';
    this.getLocation();
  }

  // Geolocation: Success
  private gpsSuccess = (pos) => {
    this._success = true
    this.latitude = pos.coords.latitude;
    this.longitude = pos.coords.longitude;
  }

  // Geolocation: Error
  private gpsError(err) {
    this.message = 'Para un correcto funcionamiento del sistema active la geolocalización';
    this.type = 'Notificación';
    this._success = false;
  }

  private getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.gpsSuccess, this.gpsError, this.geoOpts);
    } else {
      this.message = 'Geolocalización no soportada por este navegador.';
      this.type = 'Error';
    }
  }
}
