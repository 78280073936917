import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';

import { UserService } from './services/user.service';
import { RecoverComponent } from './recover/recover.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPINKeyComponent } from './reset-pinkey/reset-pinkey.component';

export { ResetPasswordComponent } from './reset-password/reset-password.component';
export { ResetPINKeyComponent } from './reset-pinkey/reset-pinkey.component';
export { LoginComponent } from './login/login.component';
export { UserService } from './services/user.service';
export { User } from './model/user.model';

@NgModule({

  imports: [
    CommonModule,
    CommonFormsModule,
    RouterModule,
    FuseSharedModule
  ],
  declarations: [LoginComponent, RecoverComponent, ResetPasswordComponent, ResetPINKeyComponent],
  exports: [LoginComponent, RecoverComponent, ResetPasswordComponent, ResetPINKeyComponent],
  providers: [
    UserService,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always' },
    },
  ],
  entryComponents: [RecoverComponent]
})
export class UsersModule { }
