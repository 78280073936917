import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TestExcelExportComponent } from './test-excel-export/test-excel-export.component';
import { Routes, RouterModule } from '@angular/router';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';
import { TestExcelMatComponent } from './test-excel-mat/test-excel-mat.component';
import { MatTableExporterModule } from 'mat-table-exporter';

const routes: Routes = [
  {
    path: 'test-excel-export',
    component: TestExcelExportComponent
  }, {
    path: 'test-excel-mat',
    component: TestExcelMatComponent
  }
];

@NgModule({
  imports: [
    CommonModule,
    CommonFormsModule,
    RouterModule.forChild(routes),
    MatTableExporterModule

  ],
  exports: [    
  ],
  declarations: [TestExcelExportComponent, TestExcelMatComponent]
})
export class TestModule { }
