import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { ProjectService } from '../../proyectos/services/project.service';
import { TaskService } from '../../proyectos/services/task.service';
import { CustomerService } from '../../proyectos/services/customer.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { concat } from 'rxjs/internal/observable/concat';


@Component({
  selector: 'app-fichaje-contra-proyecto-y-tarea',
  templateUrl: 'fichaje-contra-proyecto-y-tarea.component.html',
  styleUrls: ['fichaje-contra-proyecto-y-tarea.component.scss']
})
export class FichajeContraProyectoYTareaComponent implements OnInit{
  private projects: any;
  private tasks: any;
  public filteredTasks: any;
  private customers: any;
  public isMobile: boolean;
  public _projectFormGroup: FormGroup;
  public selectedProject: number;
  public selectedTask: number;
  public filteredProjects: {};
  private projectIds: number[];

  constructor(
    private _projectService: ProjectService,
    private _customerService: CustomerService,
    private _taskService: TaskService,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _projectDialogRef: MatDialogRef<FichajeContraProyectoYTareaComponent>,
  ) {
    this.projectIds = [];
    if (this.data === 'undefined') {
      this.data = {
        selectedProject: null,
        selectedTask: null,
        buttonName: 'FICHAR'
      }
    }
  }

  ngOnInit() {
    this.initializeForm();
    concat(
      this._taskService.getAvailableTaskList(),
      this._customerService.getAvailableCustomerList(),
      this._projectService.getAvailableProjectList()
    ).subscribe((data: any) => {
      let item = data[0];
      if ('idProyecto' in item) {
        this.tasks = data;
        this.filteredTasks = [];
        data.forEach(proj => this.projectIds.push(proj.id))
      } else if ('tareaPrincipal' in item) {
        this.projects = data;
        this.filteredProjects = data;
      } else {
        this.customers = data;
      }
    })
  }

  private initializeForm() {
    this._projectFormGroup = this._formBuilder.group({
      projectId: new FormControl({value: null}),
      taskId: new FormControl({value: null, disabled: true}),
    });
  }

  public displayFnProject(inProj: number): string {
    var rtn = '';
    if (this.projects) {
      let project = this.projects.find(p => p.id == inProj);
      if (project)
        rtn = project.nombre;
    }
    return rtn;
  }

  public displayFnTask(inTask: number): string {
    var rtn = '';
    if (this.tasks) {
      let task = this.tasks.find(t => t.id == inTask);
      if (task)
        rtn = task.descripcion;
    }
    return rtn;
  }

  getCustomer(id: number) {
    if (this.customers && id) {
      let customer = this.customers.find(cust => cust.id == id)
      if (customer)
        return customer.nombre
    }
    return '';
  }

  private filterProj(projectId: string) {
    if (['undefined', 'number'].includes(typeof projectId)) 
      return this.projects;
    
    const filterTerm = projectId.toLowerCase().trim();

    return this.projects.filter(proj => proj.nombre.toLowerCase().trim().includes(filterTerm));
  }

  public filterTasks(projectId: any) {
    // Al elegir un proyecto, filtramos sus tareas.
    this.filteredProjects = this.filterProj(projectId)

    // Vaciamos tarea, al modificar proyecto.
    this._projectFormGroup.patchValue({ taskId: null })
    let taskIdControl = this._projectFormGroup.controls.taskId;

    if (['undefined', 'number'].includes(typeof projectId)) {
      // Hay tarea principal o una única posible
      let mainTaskOrUnique = this.getMainTaskOrUnique(projectId)
      if (mainTaskOrUnique) {
        this._projectFormGroup.patchValue({ taskId: mainTaskOrUnique })
        this.data.selectedTask = mainTaskOrUnique;
      }

      // Asignamos el proyecto seleccionado a los datos que devolveremos.
      this.data.selectedProject = projectId;

      // Rellenamos filteredTask según proyecto seleccionado.
      // this._taskService.getActiveTaskListByProject(projectId)
      this.filteredTasks = this.tasks ? this.tasks.filter(task => task.idProyecto === projectId) : []

      // Habilitamos o deshabilitamos Tarea
      if (this.filteredTasks.length > 0 || taskIdControl.value)
        taskIdControl.enable();
      else
        taskIdControl.disable();
      return;
    }
    taskIdControl.disable();
  }

  public selectTask() {
    // Asignamos a this.data.selectedTask la tarea que se ha seleccionado.
    if (this.filteredTasks.find(task => task.id === this.selectedTask))
      this.data.selectedTask = this.selectedTask
  }

  private getMainTask(projectId: number): number {
    // Obtenemos la tarea principal de un proyecto y la asignamos a this.data.selectedTask
    let project = this.projects.find(proj => proj.id == projectId)

    if (this.tasks.includes(project.tareaPrincipal)) {
      this.data.selectedTask = project.tareaPrincipal;
      return project.tareaPrincipal
    }
    return null
  }

  private getFirstTaskIfSingle(projectId: number): number {
    // Si el proyecto solo tiene una tarea, aunque no esté como principal
    // devolvemos ésta en el caso de que no esté finalizada.
    let tasks = this.tasks.filter(task => task.idProyecto === projectId && task.estado < 2)
    if (tasks.length === 1)
      return tasks[0].id;
    return null;
  }

  private getMainTaskOrUnique(projectId: any) {
    // Devolvemos la tarea principal o la única que tiene el proyecto
    if (typeof projectId === 'number') {
      let mainTask = this.getMainTask(projectId)
      if (mainTask)
        return mainTask
      let uniqueTask = this.getFirstTaskIfSingle(projectId)
      return uniqueTask;
    }
    return null;
  }

  public checkProject(): boolean {
    /**
     * Para validar el proyecto debemos saber si el proyecto tiene o no tareas.
     * Si tiene tareas y no hay una principal no será válido si no se elige alguna tarea.
     * Si tiene tarea principal, le ponemos ésta. [planteamiento inicial]
     * Si solo tiene una única tarea, también le colocamos la tarea. [sug.: Bahram]
     */
    // No hay proyecto ni tarea seleccionados
    if (!this.selectedProject && !this.selectedTask)
      return true


    // El proyecto no tiene tareas.
    if (!this.selectedTask && this.filteredTasks.length === 0)
      return false

    // El proyecto tiene tareas o al menos una.
    var valid = false;
    let selectedTaskIsId = typeof this.selectedTask === 'number'
    if (selectedTaskIsId) {
      let taskIds = []
      this.filteredTasks.forEach(task => taskIds.push(task.id))
      let hasTasks = (taskIds.length > 0 && taskIds.includes(this.selectedTask))
      let empty = taskIds.length === 0 && !this.selectedTask
      valid = ( hasTasks || empty )
    }
    return !valid
  }

  public onSubmit() {
    this._projectDialogRef.close(this.data)
  }

  public getButtonName(): string {
    return this.data.buttonName;
  }
}
