import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AsSpinnerService {

    constructor() {
    }

    public spinnerEnabled: boolean;

    enable() {
        this.spinnerEnabled = true;
    }

    disable() {
        this.spinnerEnabled = false;
    }

    toggle() {
        this.spinnerEnabled = !this.spinnerEnabled;
    }
}
