import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FichajesService } from '../fichajes.service';
import { MatDialog, MatDialogConfig, MatSelect, MatDialogRef } from '@angular/material';
import { FichajesCambiosFichaComponent } from '../fichajes-cambios-ficha/fichajes-cambios-ficha.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ProjectService } from '../../proyectos/services/project.service';
import { TaskService } from '../../proyectos/services/task.service';
import { FichajesModificarComentarioComponent } from '../fichajes-modificar-comentario/fichajes-modificar-comentario.component';

@Component({
  selector: 'app-fichajes-cambios',
  templateUrl: './fichajes-cambios.component.html',
  styleUrls: ['./fichajes-cambios.component.css']
})
export class FichajesCambiosComponent implements OnInit, AfterViewInit {
  public timingFormGroup: FormGroup;
  public empleados: any;
  public projects: any;
  public tasks: any;
  private IdsEmpleados: number[] = [];
  public filtroEmpleados: any;
  public currentEmpleado;
  public listaFichajes: any[] = [];
  public punchingDialogRef: MatDialogRef<FichajesCambiosFichaComponent>;
  public commentDialogRef: MatDialogRef<FichajesModificarComentarioComponent>;
  public workerControl = new FormControl();
  public filteredItems: Observable<any>;
  public columnas = [ 'FechaEntrada', 'HoraEntrada', 'Proyecto', 'Tarea', 'FechaSalida', 'HoraSalida', 'Device', 'Localizacion', 'Comentario']
  @ViewChild('itemInput') itemInput: ElementRef<HTMLInputElement> | undefined = undefined;
  @ViewChild('workerId') workerId: number;
  @Output() workerChange = new EventEmitter();

  constructor(
    private _fichajesService: FichajesService,
    private _matDialog: MatDialog,
    private _router: Router,
    private _projectService: ProjectService,
    private _taskService: TaskService,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.setForm()
    this._activatedRoute.params.subscribe(params => {
      let workerId = parseInt(params.id)
      if (workerId) {
        this.workerId = workerId;
      }
    })

    this._projectService.getProjectList().subscribe((projects: any) => {
      this.projects = projects;
    })
    this._taskService.getTaskList().subscribe((tasks: any) => {
      this.tasks = tasks;
    })
    this._fichajesService.getEmpleados().subscribe((data: any) => {
      this.empleados = data;
      this.filtroEmpleados = data;
      data.forEach(empleado => this.IdsEmpleados.push(empleado.id))
      if (this.workerId) {
        this.displayFn(this.workerId)
        this.currentEmpleado = this.workerId
        this.seleccionaEmpleado()
        this.timingFormGroup.controls.workerId.setValue(this.workerId)
      }
    })
  }

  ngAfterViewInit() {
    this._changeDetectorRef.detectChanges();
  }

  private setForm() {
    this.timingFormGroup = this._formBuilder.group({
      workerId: new FormControl({ value: null})
    });
  }

  private setDialogConfig(row: any): MatDialogConfig {
    /*
     * He tenido que crear la configuración por medio de la clase
     * ya que no estaba cogiéndola pasándole un objeto.
     */ 
    let dialogConf = new MatDialogConfig();
    dialogConf.closeOnNavigation = true;
    dialogConf.disableClose = false;
    dialogConf.data = row;
    dialogConf.minWidth = '30%';
    dialogConf.panelClass = 'popup';
    return dialogConf;
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  onTouch = () => { }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public seleccionaEmpleado() {
    var selectedWorker = null
    if (this.IdsEmpleados.includes(this.currentEmpleado)) {
      selectedWorker = this.currentEmpleado;
      this._fichajesService.getFichajesModificables(this.currentEmpleado).subscribe((data: any) => {
        this.listaFichajes = data;
      });
    } else if (this.currentEmpleado) {
      this.filtroEmpleados = this.filter(this.currentEmpleado)
    } else {
      this.currentEmpleado = null
      this.filtroEmpleados = this.empleados
      this.listaFichajes = []
    }

    this.workerChange.emit(selectedWorker); //Para bindar con ngModel y Output
    this.propagateChange(selectedWorker);
    this.onTouch();
  }

  public gotoFicha(row) {
    let config = this.setDialogConfig(row);

    this.punchingDialogRef = this._matDialog.open(FichajesCambiosFichaComponent, config);
    this.punchingDialogRef.afterClosed().subscribe(
      (data) => {
        if (data) {
          this._router.navigateByUrl('/', { skipLocationChange: true }).then( () => {
            this._router.navigate(['modificar-fichajes', row.empleadoId]);
          });
        }
      });
  }

  public displayFn(workerId: number) {
var rtn = '';
    if (this.empleados) {
      let worker = this.empleados.find(c => c.id == workerId);
      if (worker)
        rtn = `${worker.nombre} ${worker.apellido1} ${worker.apellido2}`;
    }
    return rtn;
  }

  private toLcTrim(item: string): string {
    if (typeof item === 'string')
      return item.toLowerCase().trim()
    return ''
  }

  private filter(value: string) {
    const filterTerm = this.toLcTrim(value);

    return this.empleados.filter(empl => `${this.toLcTrim(empl.nombre)} ${this.toLcTrim(empl.apellido1)} ${this.toLcTrim(empl.apellido2)}`.includes(filterTerm));
  }

  public getProject(projId: number) {
    let proj = this.projects.find(proj => proj.id == projId);
    if (proj)
      return proj.nombre;
    return '';
  }

  public getTask(taskId: number) {
    let task = this.tasks.find(task => task.id == taskId);
    if (task)
      return task.descripcion;
    return '';
  }

  public modifyComment(evt: any, row: any) {
    evt.stopPropagation()
    let config = this.setDialogConfig(row);

    this.commentDialogRef = this._matDialog.open(FichajesModificarComentarioComponent, config);
    this.commentDialogRef.afterClosed().subscribe(
      (data) => {
        if (data && !data.cancelled) {
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate(['modificar-fichajes', row.empleadoId]);
          });
        }
      });
  }
}
