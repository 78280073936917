import { Component, OnInit } from '@angular/core';
import { UserService,User } from '../../users/users.module';

@Component({
    selector: 'app-home-layout',
    templateUrl: './home-layout.component.html',
    styleUrls: ['./home-layout.component.css']
})
export class HomeLayoutComponent implements OnInit {

    constructor(private userService: UserService) { }

    user: User;
    ngOnInit() {
        this.userService.account().subscribe(
            (data) => {
                var obj = new User();
                for (var i in data)
                    obj[i] = data[i];
                this.userService.user = obj;
                this.user = obj;
            }, (error) => {
                console.log(error);
            }
        );
    }
}
