import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { UserService, AccountProvider } from '../services/user.service';
import { MatSnackBar, MatSnackBarConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { User } from '../users.module';
import { FuseConfigService } from '../../../@fuse/services/config.service';



@Component({
    selector: 'app-secret-recovery',
    templateUrl: './recover.component.html',
    styleUrls: ['./recover.component.scss'],
  animations: fuseAnimations
})
export class RecoverComponent implements OnInit {
  public focus: boolean;
  public sendMailForm: FormGroup;
  public pinMode: boolean;
  public tenant;
  public pinKey: string;
  private account: User;
  private snackbarConfig: MatSnackBarConfig;
  public showFields: boolean;
  public btnIsDisabled: boolean;
    


    /**
     * Constructor
     *
     * @param {FormBuilder} _formBuilder
     */
    constructor(
      private _formBuilder: FormBuilder,
      private _matSnackBar: MatSnackBar,
      private _accountProvider: AccountProvider,
      private _matDialogRef: MatDialogRef<RecoverComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private _fuseConfigService: FuseConfigService,
    ) {
      this.pinMode = data.pinMode;
    }

    /**
     * On init
     */
  ngOnInit(): void {
    this.focus = true
      this.snackbarConfig = new MatSnackBarConfig();
      this.snackbarConfig.horizontalPosition = 'right';
      this.snackbarConfig.verticalPosition = 'top';
      this.snackbarConfig.duration = 3000;
      this.snackbarConfig.panelClass = ['error-snackbar']

    this.sendMailForm = this._formBuilder.group({
      recoveryEmail: ['', Validators.required]
    });
 }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------


  public toggleMode(): void {
    this.pinMode = !this.pinMode;
  }

  private emailSended(data: any) {
      let config = this.snackbarConfig;
      config.panelClass = []
      this._matSnackBar.open(
        `Se ha enviado un correo electrónico a ${data.email}, diríjase a su correo y siga las instrucciones.`, '', config);
  }

  public forgotSecret() {
    let data = { email: this.sendMailForm.controls.recoveryEmail.value }

    this._accountProvider.getEmail(data).subscribe(
      (data: any) => {
        if (data.email) {
          this.btnIsDisabled = true;
          setInterval(() => {
            this.showFields = false;
            this.btnIsDisabled = false;
            this.sendMailForm.patchValue({ recoveryEmail: ''})
          }, 60000);
          if (this.pinMode) {
            this._accountProvider.forgotPinKey(data).subscribe((data) => {
              this.emailSended(data);
              this._matDialogRef.close();
            })
          } else {
            this._accountProvider.forgotPassword(data).subscribe((data) => {
              this.emailSended(data);
              this._matDialogRef.close();
            })
          }
        } else {
          this._matSnackBar.open("Cuenta de correo electrónico desconocida.", 'Error', this.snackbarConfig);
        }
      }
    );
    // Para que no haga un submit, devolvemos false.
    return false;
  }

  // Nuevos métodos dos en uno

  public enableFields(): void {
    this.showFields = true;
  }
  public getTarget(): string {
    return this.pinMode ? 'Clave de Acceso Rápido' : 'Contraseña'
  }

  public checkSendMailBtn(): void {
    let value = this.sendMailForm.controls.recoveryEmail.value
    this.btnIsDisabled = value == '';
  }
}
