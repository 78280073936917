import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { TrabajadoresModule } from '../trabajadores/trabajadores.module';
import { FichajeComponent } from './fichaje/fichaje.component';
import { FichajesService } from './fichajes.service';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_auth/auth.guard';
import { FichajesCambiosComponent } from './fichajes-cambios/fichajes-cambios.component';
import { FichajesCambiosFichaComponent } from './fichajes-cambios-ficha/fichajes-cambios-ficha.component';
import { FichajeContraProyectoYTareaComponent } from './fichaje-contra-proyecto-y-tarea/fichaje-contra-proyecto-y-tarea.component';
import { AutofichajeComponent } from './autofichajes/autofichaje.component'
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { FichajesPropiosComponent } from './fichajes-propios/fichajes-propios.component';
import { MatTableExporterModule } from 'mat-table-exporter';
import { ProyectosModule } from '../proyectos/proyectos.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { SharedModule } from '../shared.module';
import { FichajesModificarComentarioComponent } from './fichajes-modificar-comentario/fichajes-modificar-comentario.component';

const routes: Routes = [
  {
    path: 'fichajes',
    component: FichajeComponent,
    canActivate : [AuthGuard]
  }, {
    path: 'modificar-fichajes',
    component: FichajesCambiosComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'modificar-fichajes/:id',
    component: FichajesCambiosComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'fichajes-propios',
    component: FichajesPropiosComponent,
    canActivate: [AuthGuard]
  }, {
    path: 'generar-autofichajes',
    component: AutofichajeComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    CommonModule,
    CommonFormsModule,
    RouterModule.forChild(routes),
    NgxChartsModule,
    FuseModule,
    FuseSharedModule,
    TrabajadoresModule,
    ProyectosModule,
    MatTableExporterModule,
    SharedModule
  ],
  providers: [FichajesService, { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
  declarations: [
    FichajeComponent, FichajesCambiosComponent, FichajesCambiosFichaComponent, FichajeContraProyectoYTareaComponent, FichajesPropiosComponent, FichajesModificarComentarioComponent, AutofichajeComponent
  ],
  exports: [FichajeComponent, FichajeContraProyectoYTareaComponent, SharedModule],
  entryComponents: [FichajesCambiosFichaComponent, FichajeContraProyectoYTareaComponent, FichajesModificarComentarioComponent]
})
export class FichajesModule { }
