import { Component, OnInit, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { TrabajadoresService } from '../trabajadores.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-centro-trabajo-selector',
  templateUrl: './centro-trabajo-selector.component.html',
  styleUrls: ['./centro-trabajo-selector.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CentroTrabajoSelectorComponent),
      multi: true
    }
  ]
})
export class CentroTrabajoSelectorComponent implements OnInit, ControlValueAccessor {

  public centros;
  public _valorPropiedad;
  @Input() valorPropiedad: number;
  @Output() valorPropiedadChange = new EventEmitter();
  propagateChange = (_: any) => { };
  onTouch = () => { }
  isDisabled: boolean;

  constructor(private _trabajadoresService: TrabajadoresService) { }

  ngOnInit() {
    this.getElementos();
  }

  getElementos() {
    this._trabajadoresService.getCentrosTrabajo().subscribe((data) => {
      this.centros = data;
    })
  }

  writeValue(obj: any): void {
    if (obj !== undefined) {
      this.valorPropiedad = obj;
      this._valorPropiedad = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  seleccionaValor() {
    this.valorPropiedadChange.emit(this.valorPropiedad); //Para bindar con ngModel y Output
    this.propagateChange(this.valorPropiedad);
    this.onTouch();
  }

  displayFn(ctro) {
    var rtn = '';
    let centro = this.centros.find(x => x.id == ctro);
    if (centro)
      rtn = centro.nombre;
    return rtn;
  }
}
