import { Component, OnInit } from '@angular/core';
import { TrabajadoresService } from '../trabajadores.service';
import { MatDialog, MatDialogRef } from '@angular/material';
import { DepartamentosFichaComponent } from '../departamentos-ficha/departamentos-ficha.component';
import { CalendariosService } from '../../calendarios/calendarios.service'

@Component({
  selector: 'app-departamentos',
  templateUrl: './departamentos.component.html',
  styleUrls: ['./departamentos.component.css']
})
export class DepartamentosComponent implements OnInit {
  public departamentos: any;
  public calendarios: any;
  public dialogRef: MatDialogRef<DepartamentosFichaComponent>;
  public columnas = ['Nombre', 'Descripcion', 'Calendario'];
  private dialogConfig: any;

  constructor(
    private _trabajadoresService: TrabajadoresService,
    private _calendariosService: CalendariosService,
    private _matDialog: MatDialog
  ) {
    this.dialogConfig = {
      data: {},
      panelClass: 'popup',
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
      closeOnNavigation: true
    }
  }

  ngOnInit() {
    this.getCalendarios();
    this.getDepartamentos();
  }

  getDepartamentos() {
    this._trabajadoresService.getDepartamentos().subscribe((data) => {
      this.departamentos = data;
    })
  }

  getCalendarios() {
    this._calendariosService.getCalendarios().subscribe(calendarios => {
      this.calendarios = calendarios
    })
  }

  nuevoDepartamento() {
    this.dialogRef = this._matDialog.open(DepartamentosFichaComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe((data) => {
      this.getDepartamentos();
    })
  }

  editarDepartamento(dpto) {
    let config = this.dialogConfig;
    config.data = dpto;
    this.dialogRef = this._matDialog.open(DepartamentosFichaComponent, config);
    this.dialogRef.afterClosed().subscribe((data) => {
      this.getDepartamentos();
    })
  }

  getCalendario(item: any) {
    if (!this.calendarios)
      return;
    let calendario = this.calendarios.find(cal => cal.id == item.calendarioId)
    if (!calendario)
      return;
    return calendario.nombre
  }
}
