import { Component, OnInit, Inject, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { FichajesService } from '../fichajes.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatDialog, MatSnackBarConfig, MatDialogRef } from '@angular/material';

import { MAT_DIALOG_DATA } from '@angular/material'
import { AsConfirmComponent } from 'src/app/_common/common-components/as-confirm/as-confirm.component';
import { TaskService } from '../../proyectos/services/task.service';
import { ProjectService } from '../../proyectos/services/project.service';

@Component({
  selector: 'app-fichajes-cambios-ficha',
  templateUrl: './fichajes-cambios-ficha.component.html',
  styleUrls: ['./fichajes-cambios-ficha.component.css']
})
export class FichajesCambiosFichaComponent implements OnInit {
  public fichajesFormGroup: FormGroup;
  private snackbarConfig: MatSnackBarConfig;
  public confirmDialogRef: MatDialogRef<AsConfirmComponent>;
  public tasks: any;
  public projects: any;
  public projectChanged: string;
  public filteredTasks: any[];
  public projectNames: any[];
  public projectIds: any[];
  private incomingData: IncomingData;


  // binding
  public _valorPropiedad;
  @Input() valorPropiedad: number;
  @Output() valorPropiedadChange = new EventEmitter();
  propagateChange = (_: any) => { };
  onTouch = () => { }

  constructor(
    private _formBuilder: FormBuilder,
    private _fichajesService: FichajesService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _matDialog: MatDialog,
    private _selfDialogRef: MatDialogRef<FichajesCambiosFichaComponent>,
    private _projectService: ProjectService,
    private _taskService: TaskService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.incomingData = new IncomingData(this.data);
    this.filteredTasks = [];
    this.projectNames = [];
    this.projectIds = [];
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.panelClass = ['error-snackbar'];
  }

  ngOnInit() {
    this.getProjectsAndTasks()
    this.setSnackbarConfig();
    this.initializeForm();
  }

  private initializeForm() {
    this.fichajesFormGroup = this._formBuilder.group({
      entradaId: new FormControl({ disabled: true }, Validators.required),
      salidaId: new FormControl({ disabled: true }),
      empleadoId: new FormControl({ disabled: true }),
      fechaEntrada: new FormControl({}, Validators.required),
      horaEntrada: new FormControl({}, Validators.required),
      fechaSalida: new FormControl({}, Validators.required),
      horaSalida: new FormControl({}, Validators.required),
      proyectoId: new FormControl({}, Validators.required),
      tareaId: new FormControl({})
    });
  }

  private fillFormData() {
    let horaEntrada = this.incomingData.punchingInTimeShort;
    var horaSalida = this.incomingData.punchingOutTimeShort;
    this.fichajesFormGroup.setValue({
      entradaId: this.incomingData.punchingInId,
      salidaId: this.incomingData.punchingOutId,
      empleadoId : this.incomingData.workerId,
      fechaEntrada: this.incomingData.punchingInDate,
      horaEntrada: horaEntrada,
      fechaSalida: this.incomingData.punchingOutDate,
      horaSalida: horaSalida,
      proyectoId: this.incomingData.projectId,
      tareaId: this.incomingData.taskId
    });
  }

  private getProjectsAndTasks() {
    this._projectService.getProjectList().subscribe(projects => {
      this.projects = projects;
      this.projects.forEach(proj => { this.projectNames.push(proj.nombre); this.projectIds.push(proj.id);})
    })

    this._taskService.getTaskList().subscribe(tasks => {
      this.tasks = tasks;
      this.fillFormData();
    })
  }

  eliminarEntrada() {
    this.confirmDialogRef = this._matDialog.open(AsConfirmComponent, {
      data: {
        titulo: 'Eliminar fichaje',
        mensaje: '¿Está seguro de que desea eliminar el fichaje?, se borrarán tanto la entrada como la salida'
      }
    });
    this.confirmDialogRef.afterClosed().subscribe((data) => {
      if (data === true) {
        var formData = this.fichajesFormGroup.getRawValue();
        this._fichajesService.eliminarEntrada(formData).subscribe((data: string) => {
          let json = JSON.parse(data)
          if (json.affected_rows == 2)
            this._matSnackBar.open('Fichajes de entrada y salida eliminados', '');
          else
            this._matSnackBar.open('Fichaje de entrada eliminado', '');
          this._selfDialogRef.close(formData);
        });
      }
    });
  }

  eliminarSalida() {
    this.confirmDialogRef = this._matDialog.open(AsConfirmComponent, {
      data: {
        titulo: 'Eliminar fichaje',
        mensaje: '¿Está seguro de que desea eliminar la salida?'
      }
    });
    this.confirmDialogRef.afterClosed().subscribe((data) => {
      if (data === true) {
        var formData = this.fichajesFormGroup.getRawValue();
        this._fichajesService.eliminarSalida(formData).subscribe((inData) => {
          this._matSnackBar.open('Fichaje de salida eliminado', '');
          this._selfDialogRef.close(formData);
        },
          (error) => {
            this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
          }
        );
      }
    });
  }

  public displayFnProject(inProj: number): string {
    var rtn = '';
    if (this.projects) {
      let project = this.projects.find(p => p.id == inProj);
      if (project)
        rtn = project.nombre;
    }
    return rtn;
  }

  public displayFnTask(inId: number): string {
    var rtn = '';
    if (this.tasks) {
      let task = this.tasks.find(ts => ts.id == inId);
      if (task)
        rtn = task.descripcion;
    }
    return rtn;
  }

  private checkForm(formData: any): boolean {
    /* Comprobar las fechas. Fecha y Hora Entrada debe ser menor a Fecha y Hora Salida */
    let passed = true

    if (typeof formData.fechaEntrada != 'string') {
      let month = `${formData.fechaEntrada.get('month') + 1}`.padStart(2, '0');
      let day = `${formData.fechaEntrada.get('date')}`.padStart(2, '0');
      formData.fechaEntrada = `${formData.fechaEntrada.get('year')}-${month}-${day}`
    }

    var date = formData.fechaEntrada.substring(0, 10)
    var time = formData.horaEntrada

    let punchingInDate = new Date(`${date}T${time}`)

    if (typeof formData.fechaSalida != 'string') {
      let month = `${formData.fechaSalida.get('month') + 1}`.padStart(2, '0');
      let day = `${formData.fechaSalida.get('date')}`.padStart(2, '0');
      formData.fechaSalida = `${formData.fechaSalida.get('year')}-${month}-${day}`
    }

    date = formData.fechaSalida.substring(0, 10)
    time = formData.horaSalida

    let punchingOutDate = new Date(`${date}T${time}`)

    if (punchingOutDate <= punchingInDate) {
      passed = false;
      this._matSnackBar.open('Fecha Entrada debe ser inferior a Fecha Salida', 'Error', this.snackbarConfig);
    }

    /* Si hora y minuto no cambian, no hay cambios */
    let hasChanges = this.incomingData.hasChanges(formData)
    if (!hasChanges) {
      passed = false
      let config = this.snackbarConfig;
      config.panelClass = ['outcoming-snackbar']
      this._matSnackBar.open('No ha habido cambios.', 'Notificación', config);
    }
    return passed
  }

  onSubmit() {
    var outData = this.fichajesFormGroup.getRawValue();
    if (this.checkForm(outData)) {
      if (outData.horaSalida == '23:59' && this.incomingData.punchingOutSeconds == 59)
        outData.horaSalida = "23:59:59"
      this._fichajesService.actualizarFichaje(outData).subscribe(
        (inData) => {
          this._matSnackBar.open(`${inData}`, 'Notificación');
          this._selfDialogRef.close(outData);
        },
        (error) => {
          this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
        }
      );
    } else {
      return false
    }
  }

  public checkTask() {
    // Vaciamos Tarea seleccionada ya que ha habido un cambio de proyecto.
    this.fichajesFormGroup.patchValue({ tareaId: null, disabled: true })

    // Filtramos tareas por proyectos para el select.
    // this._taskService.getActiveTaskListByProject(projectId)
    let selectedCtrlProject = this.fichajesFormGroup.controls.proyectoId
    if (this.tasks)
      this.filteredTasks = this.tasks.filter(task => task.idProyecto == selectedCtrlProject.value)

    // Mostramos tarea principal, si existe, para el proyecto.
    if (this.projectChanged && this.projects) {
      if (this.projectNames.includes(this.projectChanged) || this.projectIds.includes(this.projectChanged)) {
        let selectedProject = this.projects.find(project => (project.id == selectedCtrlProject.value || project.nombre == selectedCtrlProject.value))
        if (selectedProject && selectedProject.tareaPrincipal) {
          this.fichajesFormGroup.patchValue({ tareaId: selectedProject.tareaPrincipal, disabled: false })
        }
      }
    }
  }


}

export class IncomingData {
  public workerId: number;

  public punchingInId: number;
  public punchingOutId: number;

  public punchingInDate: string;
  public punchingOutDate: string;

  public punchingInLogDate: string;
  public punchingOutLogDate: string;

  public punchingInTime: string;
  public punchingOutTime: string;

  public device: string;
  public location: string;
  public projectId: number;
  public taskId: number;
  public punchingType: string;

  constructor(data: any) {
    this.device = data.device;
    this.workerId = data.empleadoId;
    this.punchingInId = data.entradaId;
    this.punchingOutId = data.salidaId;
    this.punchingInDate = data.fechaEntrada;
    this.punchingOutDate = data.fechaSalida;
    this.punchingInLogDate = data.fechaRegistroEntrada;
    this.punchingOutLogDate = data.fechaRegistroSalida;
    this.punchingInTime = data.horaEntrada;
    this.punchingOutTime = data.horaSalida;
    this.location = data.localizacion;
    this.projectId = data.proyectoId;
    this.taskId = data.tareaId;
    this.punchingType = data.tipo;
  }

  public get punchingOutSeconds(): number {
    return parseInt(this.punchingOutTime.substring(6))
  }

  public get punchingInTimeShort() {
    return this.punchingInTime.substring(0, 5)
  }

  public get punchingOutTimeShort() {
    return this.punchingOutTime.substring(0, 5)
  }

  public hasChanges(formData: any): boolean {
    /*
     * Los tiempos del formulario vienen sin segundos para saber si el usuario ha
     * modificado los datos debemos comparar los tiempos de los datos que traemos
     * sin segundos. De lo contrario los detecta como diferentes y procede a
     * modificarlo en la base de datos, sin que el usuario haya hecho algo.
     *
     * En este método devolvemos true si no ha habido cambios ni en fechas, ni
     * en horas y tampoco se haya modificado proyecto o tarea.
     */
    var hasSameValue = formData.horaEntrada == this.punchingInTimeShort;
    hasSameValue = hasSameValue && formData.horaSalida == this.punchingOutTimeShort;
    hasSameValue = hasSameValue && formData.fechaEntrada == this.punchingInDate;
    hasSameValue = hasSameValue && formData.fechaSalida == this.punchingOutDate;
    hasSameValue = hasSameValue && formData.proyectoId == this.projectId;
    hasSameValue = hasSameValue && formData.tareaId == this.taskId;

    return !hasSameValue;
  }
}
