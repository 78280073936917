import { Component, OnInit } from '@angular/core';
import { TrabajadoresService } from '../trabajadores.service';
import { MatDialog } from '@angular/material';
import { CentroTrabajoFichaComponent } from '../centro-trabajo-ficha/centro-trabajo-ficha.component';

@Component({
  selector: 'app-centros-trabajo-lista',
  templateUrl: './centros-trabajo-lista.component.html',
  styleUrls: ['./centros-trabajo-lista.component.css']
})
export class CentrosTrabajoListaComponent implements OnInit {
  public isMobile = false;
  public centrosTrabajo;
  public columnas = ['Nombre', 'Direccion', 'Mail', 'Telefono'];
  public dialogRef;
  private dialogConfig: any;

  constructor(
    private _trabajadoresService: TrabajadoresService,
    private _matDialog: MatDialog
  ) {
    this.dialogConfig = {
      data: {},
      panelClass: 'popup',
      autoFocus: true,
      hasBackdrop: true,
      disableClose: false,
      closeOnNavigation: true
    }
  }

  ngOnInit() {
    this.getCentrosTrabajo();
  }

  getCentrosTrabajo() {
    this._trabajadoresService.getCentrosTrabajo().subscribe((data) => {
      this.centrosTrabajo = data;
    });
  }

  nuevoCentroTrabajo() {
    this.dialogRef = this._matDialog.open(CentroTrabajoFichaComponent, this.dialogConfig);
    this.dialogRef.afterClosed().subscribe((data) => {
      this.getCentrosTrabajo();
    });
  }

  verCentroTrabajo(ctroTrab) {
    let dialogConfig = this.dialogConfig;
    dialogConfig.data = ctroTrab;
    this.dialogRef = this._matDialog.open(CentroTrabajoFichaComponent, dialogConfig);
    this.dialogRef.afterClosed().subscribe((data) => {
      this.getCentrosTrabajo();
    })
  }
}
