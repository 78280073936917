import { Component, OnInit } from '@angular/core';
import { TrabajadoresService } from '../trabajadores.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-empresa-ficha',
  templateUrl: './empresa-ficha.component.html',
  styleUrls: ['./empresa-ficha.component.css']
})
export class EmpresaFichaComponent implements OnInit {


  public empresa;
  public fgEmpresa: FormGroup;
  constructor(private _empleadosService: TrabajadoresService,
    private _formBuilder: FormBuilder,
  private _matSnackBar : MatSnackBar) { }

  ngOnInit() {

    this.fgEmpresa = this._formBuilder.group({
      id: new FormControl({ value: 0, disabled: true }, Validators.required),
      nombre: new FormControl('', Validators.required),
      razonSocial: new FormControl('', Validators.required),
      cif: new FormControl('', Validators.required),
      direccion: new FormControl('', Validators.required),
      cp: new FormControl('', Validators.required),
      poblacion: new FormControl('', Validators.required),
      provincia: new FormControl('', Validators.required),
      pais: new FormControl('', Validators.required),
      mail: new FormControl(''),
      telefono: new FormControl('')
    });

    this._empleadosService.getDatosEmpresa().subscribe((data: any) => {
      this.fgEmpresa.setValue({
        id: data.id,
        nombre: data.nombre,
        razonSocial: data.razonSocial,
        cif: data.cif,
        direccion: data.direccion,
        cp: data.cp,
        poblacion: data.poblacion,
        provincia: data.provincia,
        pais: data.pais,
        mail: data.mail,
        telefono: data.telefono
      });
    });
  }


  onSubmit() {
    var datosEmpresa= this.fgEmpresa.getRawValue();
    this._empleadosService.updateDatosEmpresa(datosEmpresa).subscribe((data) => {
      this._matSnackBar.open('Datos de empresa actualizados', '');
    });
  }

}
