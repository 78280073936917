import { Directive, Input, ElementRef } from '@angular/core';
import { environment } from '../../../environments/environment';

@Directive({
  selector: '[asSrc]'
})
export class AsSrcDirective {
  @Input() asSrc: string;
  constructor(private el: ElementRef) {
    
  }
  ngOnInit() {
    var src = this.asSrc;
    var baseUrl = environment.appBaseHref;
    if (baseUrl)
      src = baseUrl + src;

    this.el.nativeElement.setAttribute("src", src);
  }
}


@Directive({
  selector: '[asHref]'
})

export class AsHrefDirective {
  @Input() asHref: string;
  constructor(private el: ElementRef) {

  }

  ngOnInit() {
    var src = this.asHref;
    var baseUrl = environment.appBaseHref;
    if (baseUrl)
      src = baseUrl + src;

    this.el.nativeElement.setAttribute("href", src);
  }
}
