import { Component, OnInit, ViewChild } from '@angular/core';
import { FichajesService } from '../fichajes.service';
import { User } from '../../users/model/user.model';
import { AccountProvider } from '../../users/services/user.service';
import { ProjectService } from '../../proyectos/services/project.service';
import { TaskService } from '../../proyectos/services/task.service';
import { MatTableDataSource, MatTable, MatDatepickerInputEvent, MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ReportingService } from '../../reporting/reporting.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DateProvider } from '../../_common/common-classes/date-provider';
import { DateDiff } from '../../_common/common-classes/date-diff';
import { FichajesModificarComentarioComponent } from '../fichajes-modificar-comentario/fichajes-modificar-comentario.component';

@Component({
  selector: 'app-fichajes-propios',
  templateUrl: './fichajes-propios.component.html',
  styleUrls: ['./fichajes-propios.component.css']
})
export class FichajesPropiosComponent implements OnInit {
  public filtrosFormGroup: FormGroup;
  public DateLimits: any;
  public horarios: any;
  public fichajes: any;
  public worker: any;
  public punchingList;
  public projects: any;
  public tasks: any;
  public user: User = new User();
  private fromDate: Date;
  private toDate: Date;
  public expectedWorkedTime
  public chartVisible: boolean = false;
  private snackbarConfig: MatSnackBarConfig;
  public dialogRef: MatDialogRef<FichajesModificarComentarioComponent>;

  public columnas = ['Proyecto', 'Tarea', 'FechaEntrada', 'HoraEntrada', 'FechaSalida', 'HoraSalida', 'TiempoFichaje', 'Comentario']
  private colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };


  //#region Punching summaries
  @ViewChild('dataTable') public dataTable: MatTable<any>;
  public projectDataSource: MatTableDataSource<ItemModel>;
  public projectPunchingSums = {};
  public projectSummaryColumns = ['Proyecto', 'HorasFichadas', 'HorasEstipuladas', 'Periodo']
  //#endregion

  constructor(
    private _fichajesService: FichajesService,
    private _accountProvider: AccountProvider,
    private _projectService: ProjectService,
    private _taskService: TaskService,
    private _reportingService: ReportingService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _matSnackBar: MatSnackBar,
    private _matDialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.setSnackbarConfig();
    this.initFormGroup();
    // Obtenemos fechas por defecto de inicio y fin de mes en curso.
    this._reportingService.getFirstAndLastDatesOfCurrentMonth().subscribe((dates: any) => {
      this.DateLimits = dates;
      this.fromDate = dates.fromDate;
      this.toDate = dates.toDate;
      this.getUserInfo();
    });
    this._projectService.getProjectList().subscribe((projects: any) => {
      this.projects = projects;
    })
    this._taskService.getTaskList().subscribe((tasks: any) => {
      this.tasks = tasks;
    })
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.panelClass = ['error-snackbar'];
  }

  private getUserInfo() {
    // Obtener datos del usuario con sesión iniciada, identificado o 'logueado'.
    this._accountProvider.userInfo.subscribe((userInfo) => {
      let user = userInfo;
      this._fichajesService.getEmpleados().subscribe((punchings: any) => {
        this.worker = punchings.find(worker => worker.id == user.empleadoId);

        // Rellenamos datos del formulario
        this.fillForm()
        var formData = this.filtrosFormGroup.getRawValue();
        this.showGraph(formData)

        // Obtenemos datos de fichajes (propios).
        this.getPunchings(formData);
      });
    });
  }

  private showGraph(formData) {
    let graphData = {
      fechaDesde: formData.fromDate,
      fechaHasta: formData.toDate,
      empleado: this.worker.id,
      agrupacion: 'Día'
    }

    this._reportingService.empleadosHoras(graphData).subscribe((filterData: any) => {
      let h = filterData.find(x => x.nombre == "Horario");
      let f = filterData.find(x => x.nombre == "Fichajes");
      this.horarios = [{
        name: 'Horas estipuladas',
        series: this.sortDataByDate(h.datos)
      }];
      this.fichajes = this.sortDataByDate(f.datos);

      var minimo = h.min;
      if (f.min < minimo) {
        minimo = f.min;
      }
      var maximo = h.max;
      if (f.max > maximo)
        maximo = f.max;
      console.log(`min: ${minimo} - max: ${maximo}`);
      this.chartVisible = true;
    });
  }

  private initFormGroup() {
    this.filtrosFormGroup = this._formBuilder.group({
      projectId: new FormControl(),
      fromDate: new FormControl(),
      toDate: new FormControl()
    });
  }

  private fillForm() {
    let fromDate = this.DateLimits.fromDate;
    let toDate = this.DateLimits.toDate;
    this.filtrosFormGroup.setValue({
      projectId: null,
      fromDate: fromDate,
      toDate: toDate
    });
  }

  private getTimeDiff(item: any) {
    let entry = new Date(item.fechaRegistroSalida)
    let exit = new Date(item.fechaRegistroEntrada)
    return entry.getTime() - exit.getTime()
  }

  public dateChanges(evt: MatDatepickerInputEvent<Date>) {
    let dateProvider = new DateProvider();
    dateProvider.fromEvent(evt);
    this.toDate = dateProvider.toDate;
    this.filtrosFormGroup.controls.toDate.setValue(dateProvider.toDate)
  }

  public getTimeDiffToString(diff: number, showDays: boolean = true, showMinutes: boolean = true) {
    let dateDiff = new DateDiff(diff, showDays, showMinutes);
    return dateDiff.toString();
  }

  public getPunchingTime(item, showDays = true, showMinutes = true) {
    let diff = this.getTimeDiff(item);
    return this.getTimeDiffToString(diff, showDays, showMinutes);
  }

  setPunchingSummaries() {
    this.projectPunchingSums = {}
    for (let item of this.punchingList) {
      let diff = this.getTimeDiff(item)
      let projId = item.proyectoId
      if (!this.projectPunchingSums[projId])
        this.projectPunchingSums[projId] = 0;
      this.projectPunchingSums[projId] += diff;

      if (!this.projectPunchingSums['totalEmpleado'])
        this.projectPunchingSums['totalEmpleado'] = 0;
      this.projectPunchingSums['totalEmpleado'] += diff;
    }
    let data: ItemModel[] = [];
    for (let [key, val] of Object.entries(this.projectPunchingSums)) {
        let itemModel = new ItemModel()
        itemModel.id = key;
        itemModel.time = val;
        data.push(itemModel);
    }
    this.projectDataSource = new MatTableDataSource(data)
  }

  public getWorker() {
    if (!this.worker)
      return ''
    return `${this.worker.nombre} ${this.worker.apellido1} ${this.worker.apellido2}`
  }

  public getProject(projId: number|string) {
    if (projId === 'totalEmpleado')
      return 'Total horas empleado'
    let proj = this.projects.find(proj => proj.id == projId);
    if (!proj)
      return 'PROYECTO FINALIZADO o BORRADO'
    return proj.nombre
  }

  public getTask(taskId: number) {
    let task = this.tasks.find(task => task.id == taskId);
  }

  public getFilename() {
    return `registro_fichajes_${this.getWorker().replace(/ +/g, '_')}`
  }

  private sortDataByDate(data: any) {
    let copy = [...data]
    return copy.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
  }

  private sortPunchingsByDate(data: any) {
    let copy = [...data]
    return copy.sort((a, b) => (a.fechaEntrada > b.fechaEntrada) ? 1 : ((b.fechaEntrada > a.fechaEntrada) ? -1 : 0));
  }

  private sortDataById(data: any) {
    let copy = [... data]
    return copy.sort((a, b) => a.entradaId - b.entradaId);
  }

  private getPunchings(formData: any) {
    let punchingFilter = {
      workerId: this.worker.id,
      projectId: formData.projectId,
      fromDate: formData.fromDate,
      toDate: formData.toDate
    }

    this._fichajesService.getFichajesPropios(punchingFilter).subscribe((filterData: any) => {
      this.punchingList = this.sortDataByDate(filterData.punchingList);
      this.expectedWorkedTime = filterData.expectedWorkedTime
      this.setPunchingSummaries()
    })
  }

  private checkDates(): boolean {
    let d1 = new Date(this.fromDate);
    let d2 = new Date(this.toDate);
    if (d2 < d1) {
      this._matSnackBar.open('El campo Fecha Hasta debe ser mayor que Fecha Desde.', 'Error', this.snackbarConfig)
      return false;
    }
    return true;
  }

  public onSubmit() {
    var formData = this.filtrosFormGroup.getRawValue();
    this.fromDate = formData.fromDate;
    this.toDate = formData.toDate;
    if (this.checkDates()) {
      this.showGraph(formData);
      this.getPunchings(formData);
    }
  }

  public goHome() {
    this._router.navigateByUrl('/');
    return false;
  }

  private setDialogConfig(row: any): MatDialogConfig {
    /*
     * He tenido que crear la configuración por medio de la clase
     * ya que no estaba cogiéndola pasándole un objeto.
     */
    let dialogConf = new MatDialogConfig();
    dialogConf.closeOnNavigation = true;
    dialogConf.disableClose = false;
    dialogConf.data = row;
    dialogConf.minWidth = '30%';
    dialogConf.panelClass = 'popup';
    return dialogConf;
  }

  public modifyComment(evt: any, row: any) {
    evt.stopPropagation()
    let config = this.setDialogConfig(row);

    this.dialogRef = this._matDialog.open(FichajesModificarComentarioComponent, config);
    this.dialogRef.afterClosed().subscribe(
      (data) => {
        if (data && !data.cancelled) {
          const formData = this.filtrosFormGroup.value
          this.getPunchings(formData);
        }
      });
  }

  yLeftScaleFactor;
  yRightScaleFactor;

  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  legendTitle = 'Leyenda';
  legendPosition = 'right';
  showXAxisLabel = true;
  xAxisLabel = 'Fecha';
  showYAxisLabel = true;
  yAxisLabel = 'Horas fichadas';
  showGridLines = true;
  innerPadding = '10%';
  animations: boolean = true;
  barChart: any[] = this.fichajes;
  lineChartSeries: any[] = this.horarios;
  lineChartScheme = {
    name: 'coolthree',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };

  comboBarScheme = {
    name: 'singleLightBlue',
    selectable: true,
    group: 'Ordinal',
    domain: ['#01579b']
  };

  showRightYAxisLabel: boolean = true;
  yAxisLabelRight: string = '';

}

export class ItemModel {
  id: string;
  time: any;
  expectedTime: any
}
