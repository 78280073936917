import { Component, OnInit, Inject, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TrabajadoresService } from '../trabajadores.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar, MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatSnackBarConfig, MatCalendar, MatDatepickerInputEvent } from '@angular/material';
import { AccountProvider } from '../../users/services/user.service';
import { CalendarioVacacionesComponent } from '../calendario-vacaciones/calendario-vacaciones.component';
import { DateProvider } from '../../_common/common-classes/date-provider';
import * as moment from 'moment';


/*
 * Se está utilizando este mismo formulario tanto para solicitar un día libre o periodo de vacaciones, es decir
 * para crear una solicitud, como para gestionarlos, o sea, para aprobar o rechazar la solicitud.
 *
 * Lo primero es determinar el motivo para el que se abre el diálogo y en función de éste mostramos y habilitamos
 * o escondemos y deshabilitamos ciertos campos para establecer la validez del formulario.
 *
 * En principio si no trae datos se trata de una nueva solicitud (estamos creando la solicitud), en cambio con
 * datos, se trata de una revisión (vamos a aprobar o denegar la solicitud).
 *
 * - Para la creación (solo para usuario) o para la modificación (solo para el AdminWeb/Responsable):
 *    - Solo son visibles los campos de Solicitar días completos fecha, motivo, descripción.
 *    - campos "Fecha hasta" invisible y no requerido si "Solicitar días completos" es false
 *      visible y requerido si "Solicitar dias completos" es true
 *    - campos "horaDesde" y "horaHasta", invisibles y no requeridos si "Solicitar días completos" es true
 *      visibles y requeridos si "Solicitar días completos" es false
 * - Para la gestión:
 *    - Se bloquean "Solicitar Días completos", fechas, horas, "motivo", "descripción"
 *    - Campo "estado": visible, valor por defecto -> Pendiente
 *    - Campo "Motivo de rechazo": visible, inhabilitado mientras estado no sea rechazado
 *    - Campo "Descripción Aprobación": visible, inhabilitado mientras estado no sea Aprobado.
 *
 *  Al crear una solicitud debemos enviar un correo al AdminWeb y al Responsable
 *  Al gestionar (aprobar/rechazar) una solicitud debemos enviar un correo al usuario que la solicitó.
 */
@Component({
  selector: 'app-empleados-solicitudes-ficha',
  templateUrl: './empleados-solicitudes-ficha.component.html',
  styleUrls: ['./empleados-solicitudes-ficha.component.css']
})
export class EmpleadosSolicitudesFichaComponent implements OnInit {
  @ViewChild(CalendarioVacacionesComponent) CalendarComp: CalendarioVacacionesComponent
  public solicitudFormGroup: FormGroup;
  public motivosSolicitud: Array<any>;
  public estados: Array<any>;
  public motivosRechazo: Array<any>;
  private idSolicitud: number;
  public modoAprobacion: boolean;
  public showDiasCompletos: boolean;
  public buttonCaption: string;
  private createRequest: boolean;
  private buffer: any; // Para almacenar motivo de rechazo y descripción del Aprobador.
  private required = [Validators.required];
  private partialDayRequests: any;
  private snackbarConfig: MatSnackBarConfig;
  private user: any;
  private oldForm: any;
  @Output() workerChange = new EventEmitter();
  public canModifyWorker: boolean;
  public workerId: number;
  public workers;
  public filteredWorkers;
  private workerIds: number[] = [];
  public workerIsDisabled: boolean = false;
  private isDisabled: boolean = false;
  public dateProvider: DateProvider = new DateProvider();

  constructor(
    private _formBuilder: FormBuilder,
    private _trabajadoresService: TrabajadoresService,
    private _activatedRoute: ActivatedRoute,
    private _matSnackBar: MatSnackBar,
    private _accountProvider: AccountProvider,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialogRef : MatDialogRef<EmpleadosSolicitudesFichaComponent>
  ) {
    this.dateProvider.setMinMaxDates()

    this.setSnackbarConfig();
    this.createRequest = typeof this.data.idSolicitud === 'undefined'
    /*
     * La propiedad título (Caption) del botón es:
     * "ENVIAR" cuando se trata de una nueva solicitud
     * "APLICAR" cuando estado esté PENDIENTE o estamos modificando la solicitud
     * "APROBAR" cuando estado sea Aprobado
     * "RECHAZAR" cuando estado sea Rechazado
     */ 
    this.buttonCaption = this.createRequest ? "ENVIAR" : "APLICAR";
    this.buffer = {
      motivoRechazo: 0,
      descripcionAprobador: ''
    }
  }

  private setRequestForm(): void {
    // Formulario para creación
    this.solicitudFormGroup = this._formBuilder.group(
      {
        id: new FormControl({ value: 0, disabled: true }, Validators.required),
        empleadoId: new FormControl({ value: null, disabled: false }, Validators.required),
        fechaDesde: new FormControl({ value: null }, Validators.required),
        fechaHasta: new FormControl({ value: null }, Validators.required),
        motivo: new FormControl({ value: 0 }, Validators.required),
        descripcion: new FormControl({ value: '' }, Validators.required),
        diaCompleto: new FormControl(true),
        horaDesde: new FormControl(),
        horaHasta: new FormControl()
      });
  }

  private getUserInfo() {
    // Obtener datos del usuario con sesión iniciada, identificado o 'logueado'.
    this._accountProvider.userInfo.subscribe((userInfo) => {
      this.user = userInfo;
    });
  }


  private getData() {

    this.getWorkerIds();

    // Solicitudes pendientes o aceptadas de días no completos de todos los trabajadores.
    this._trabajadoresService.obtenerTodasLasSolicitudesDisponibles().subscribe((data: any) => {
      this.partialDayRequests = data
    })

    // Motivos Solicitud
    this._trabajadoresService.getTipados('MotivoSolicitud').subscribe((motivosSolicitud: any) => {
      this.motivosSolicitud = motivosSolicitud;

      if (!this.idSolicitud) {
        var motivoDefecto = this.motivosSolicitud.find(f => f.valorPorDefecto == true);
        this.solicitudFormGroup.patchValue({
          motivo: motivoDefecto.id,
          descripcion: ''
        });
      }
    });

    // Estados Solicitud
    this._trabajadoresService.getTipados('EstadoSolicitud').subscribe((estados: any) => {
      this.estados = estados;
    });

    // Motivos Rechazo.
    this._trabajadoresService.getTipados('MotivoRechazo').subscribe((motivosRechazo: any) => {
      this.motivosRechazo = motivosRechazo;
    });
  }

  ngOnInit() {
    this.getUserInfo();
    this.setRequestForm();
    this.setDiasCompletos(true);  // Sirve para cuando se crea una solicitud.
    this.getData()

    if (this.data && this.data.idSolicitud) {
      this.idSolicitud = this.data.idSolicitud;
      this.modoAprobacion = this.data.modoAprobacion;
      this.inicializaComponente();
    } else {
      this._activatedRoute.queryParams.subscribe((params: Params) => {
        // Esto es para cuando creamos una solicitud, sin embargo en la URL no hay parámetros
        this.idSolicitud = params.id;
        this.modoAprobacion = params.modoAprobacion;
        this.inicializaComponente();
      });
    }

    // Escuchador para cambio de Días Completos
    this.solicitudFormGroup.controls.diaCompleto.valueChanges.subscribe((changed) => {
      this.setDiasCompletos(changed);
    })

    // Escuchador para cambio de Estado
    let estado = this.solicitudFormGroup.controls.estado;
    if (estado) {
      estado.valueChanges.subscribe((value) => {
        this.setReasonAndAprovalDescr(value);
      });
    }
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.panelClass = ['error-snackbar']
  }

  private clearControls() {
    let controls = this.solicitudFormGroup.controls
    controls.descripcionAprobador.setValue(null);
    controls.motivoRechazo.setValue(null);
  }

  private setReasonAndAprovalDescr(value) {
    this.solicitudFormGroup.clearValidators();
    let controls = this.solicitudFormGroup.controls
    this.clearControls();
    if (value === 0) {
      // Estado PENDIENTE
      this.buttonCaption = 'APLICAR'
      controls.descripcionAprobador.disable();
      controls.motivoRechazo.disable();

    } else if (value === 1) {
      // Estado APROBADO
      this.buttonCaption = 'APROBAR'

      controls.motivoRechazo.disable();

      controls.descripcionAprobador.setValue(this.buffer.descripcionAprobador)
      controls.descripcionAprobador.enable();
      // controls.descripcionAprobador.setValidators(this.required); // Eliminado campo oblig. 2023/07/19 11:25

    } else if (value === 2) {
      // Estado RECHAZADO
      this.buttonCaption = 'RECHAZAR'

      controls.descripcionAprobador.disable();

      controls.motivoRechazo.setValue(this.buffer.motivoRechazo)
      controls.motivoRechazo.enable();
      controls.motivoRechazo.setValidators(this.required);
    }
    controls.motivoRechazo.updateValueAndValidity();
    controls.descripcionAprobador.updateValueAndValidity();
  }
 
  private setDiasCompletos(value: boolean) {
    this.showDiasCompletos = value;
    if (this.solicitudFormGroup) {
      this.solicitudFormGroup.clearValidators();
      let controls = this.solicitudFormGroup.controls
      let fechaHasta = controls.fechaHasta;
      let horaDesde = controls.horaDesde;
      let horaHasta = controls.horaHasta;
 
      if (this.showDiasCompletos) {
        fechaHasta.setValidators(this.required);
        fechaHasta.enable();
        horaDesde.disable();
        horaHasta.disable();
      } else {
        horaDesde.setValidators(this.required);
        horaHasta.setValidators(this.required);
        fechaHasta.disable()
        horaDesde.enable();
        horaHasta.enable();
      }
      fechaHasta.updateValueAndValidity();
      horaHasta.updateValueAndValidity();
      horaDesde.updateValueAndValidity();
    }
  }

  private inicializaComponente() {
    let workerId = this.user? this.user.id : 0
    if (this.idSolicitud) {
      if (this.modoAprobacion) {
        // Formulario para aprobación / rechazo
        this.solicitudFormGroup = this._formBuilder.group(
          {
            id: new FormControl({ value: 0, disabled: true }, Validators.required),
            empleadoId: new FormControl({ value: null, disabled: false }, Validators.required),
            fechaDesde: new FormControl({ value: '' }, Validators.required),
            fechaHasta: new FormControl({ value: '' }, Validators.required),
            horaDesde: new FormControl({ value: '' }),
            horaHasta: new FormControl({ value: '' }),
            diaCompleto: new FormControl({ value: true }),
            motivo: new FormControl({ value : '' }, Validators.required),
            descripcion: new FormControl({ value: '' }, Validators.required),
            estado: new FormControl({ value: 0 }, Validators.required),
            descripcionAprobador: new FormControl({ value: '' }),
            motivoRechazo: new FormControl({ value: 0 }, Validators.required)
          }
        );
      }

      this._trabajadoresService.getSolicitud(this.idSolicitud).subscribe((incomingRequest: any) => {
        this.oldForm = incomingRequest;
        this.canModifyWorker = true;
        this.solicitudFormGroup.patchValue({
          id: this.idSolicitud,
          empleadoId: incomingRequest.empleadoId,
          motivo: incomingRequest.motivo,
          fechaDesde: incomingRequest.fechaDesde,
          fechaHasta: incomingRequest.fechaHasta,
          horaDesde: incomingRequest.horaDesde,
          horaHasta: incomingRequest.horaHasta,
          diaCompleto : incomingRequest.diaCompleto,
          descripcion: incomingRequest.descripcion
        });
        if (this.modoAprobacion) {
          if (incomingRequest.motivoRechazo)
            this.buffer.motivoRechazo = incomingRequest.motivoRechazo;
          if (incomingRequest.descripcionAprobador)
            this.buffer.descripcionAprobador = incomingRequest.descripcionAprobador
          this.solicitudFormGroup.patchValue({
            estado: incomingRequest.estado,
            descripcionAprobador: incomingRequest.descripcionAprobador,
            motivoRechazo: incomingRequest.motivoRechazo
          });
        }
      });
    }
  }

  public displayFn(motivo) {
    var rtn = '';
    if (this.motivosSolicitud) {
      let mot = this.motivosSolicitud.find(m => m.id == motivo);
      rtn = mot.valor;
    }
    return rtn;
  }

  public displayFnEstado(estado) {
    var rtn = '';
    if (this.estados) {
      let est = this.estados.find(m => m.id == estado);
      rtn = est.valor;
    }
    return rtn;
  }

  public displayFnMotivoRechazo(motivoId: number) {
    let estado = this.solicitudFormGroup.controls.estado.value;
    if (estado != 2)
      return '';

    // this.buffer.motivoRechazo = motivoId
    var rtn = '';
    if (this.motivosRechazo) {
      let motivo = this.motivosRechazo.find(mr => mr.id == motivoId);
      if (motivo) {
        rtn = motivo.valor;
        this.buffer.motivoRechazo = motivo.id
      }
      let motDef = this.motivosRechazo.find(mr => mr.valorPorDefecto == true);
      if (!motivo && motDef) {
        rtn = motDef.valor;
        this.buffer.motivoRechazo = motDef.id
      }
    }
   return rtn
  }

  private validateForm(formData: any): boolean {
    if (formData.empleadoId === null)
      formData.empleadoId = this.user.empleadoId;
    let form = new FormData(formData);
    form.oldForm = this.oldForm;
    form.dispatch(this.partialDayRequests)

    var errMsg = ""
    // Datos erróneos flagrantes.
    if (form.fromDate === form.toDate) {
      errMsg = ""; // 19/06/2023 - Se permite que se solicite para un solo día, teniendo la misma fecha en ambos campos "Las fechas deben ser distintas";
    } else if (form.fromDate > form.toDate) {
      errMsg = "Fecha Desde debe ser inferior a Fecha Hasta";
      // Es una modificación sin cambios
    } else if (!form.isNew && !form.hasChanges) {
      errMsg = "No ha habido cambios";
      // Ya exite una petición con fecha(s) idéntica(s).
    } else if ((form.isNew || !form.hasChanges) && form.duplicated && form.duplicated.find(dup => dup.empleadoId === form.workerId)) {
      if (!form.fullDay) {
        errMsg = "Ya existe una solicitud para el día seleccionado";
      } else {
        errMsg = "Ya existe una solicitud para los días seleccionados";
      }
    }
    if (errMsg) {
      this._matSnackBar.open(`${errMsg}.`, 'Error', this.snackbarConfig);
      form.valid = false;
    }
    return form.valid;
  }

  public onSubmit() {
    var formData = this.solicitudFormGroup.getRawValue();
    formData.modificacionEstado = this.modoAprobacion
    let validated: boolean = this.validateForm(formData)
    if (validated) {
      if (!this.idSolicitud) {
        // Creando solicitud
        this._trabajadoresService.altaSolicitud(formData).subscribe((createRequestData) => {
          this._matSnackBar.open("Solicitud enviada", '');
          this._matDialogRef.close(true);
        }, (error) => {
          this._matSnackBar.open("Error al enviar la solicitud", 'Error', this.snackbarConfig);
        });
      } else {
        // Modificando solicitud
        this._trabajadoresService.actualizarSolicitud(formData).subscribe((aprovalData) => {
          this._matSnackBar.open("Solicitud modificada", '');
          this._matDialogRef.close(true);
        }, (error) => {
          this._matSnackBar.open("Error al enviar la solicitud", 'Error', this.snackbarConfig);
        });
      }
    }
  }

  public cancelarEdicion() {
    //this._router.navigate(['empleado-solicitudes']);
    this._matDialogRef.close(false);
  }

  public setBuffer() {
    this.buffer.descripcionAprobador = this.solicitudFormGroup.controls.descripcionAprobador.value
  }

  public dateChanges(evt: MatDatepickerInputEvent<Date>) {
    this.dateProvider.fromEvent(evt);
    this.dateProvider.addWeeks(1);

    // Put toDate value.
    let toDateMoment = moment(this.dateProvider.toDate)
    this.solicitudFormGroup.controls.fechaHasta.setValue(toDateMoment);

    this.CalendarComp.goToDateInView(evt.value);
    this.CalendarComp.updateCalendar(this.dateProvider.fromDate);
  }

  // #region Worker
  getWorkerIds() {
    return this._trabajadoresService.getWorkers().subscribe((data: any) => {
      this.workers = data;
      this.filteredWorkers = data;
      data.forEach(worker => this.workerIds.push(worker.id))
    });
  }

  writeValue(obj: any): void {
    if (obj !== null) {
      this.workerId = obj;
      // this.workerIsDisabled = true
    } else {
      this.workerId = null
      // this.workerIsDisabled = false
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  onTouch = () => { }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  selectWorker(evt: any) {
    if (!this.canModifyWorker) {
      return false
    }

    var selectedWorker = null
    if (this.workerIds.includes(this.workerId)) {
      selectedWorker = this.workerId
    } else if (this.workerId) {
      this.filteredWorkers = this.filter(`${this.workerId}`)
    } else {
      // empleado está vacío
      this.workerId = null;
      this.filteredWorkers = this.workers;
    }

    this.workerChange.emit(selectedWorker); //Para bindar con ngModel y Output
    this.propagateChange(selectedWorker);
    this.onTouch();

    // Actualizamos punchingList si tenemos valores en las fechas, sino estamos en el inicio
    // y no se devolverían datos llamando a getPunchings(), por lo tanto salimos.
    let formData = this.solicitudFormGroup.getRawValue();
    if (!formData.fromDate && !formData.toDate)
      return;
  }

  private toLcTrim(worker: string): string {
    return worker.toLowerCase().trim();
  }

  private filter(value: string) {
    const filterTerm = this.toLcTrim(value);

    return this.workers.filter(w => `${this.toLcTrim(w.nombre)} ${this.toLcTrim(w.apellido1)} ${this.toLcTrim(w.apellido2)}`.includes(filterTerm));
  }

  public enableWorker() {
    // this.workerIsDisabled = !this.canModifyWorker;
  }

  public checkWorker() {
    if (this.workerId !== null && typeof this.workerId !== 'undefined') {
      // this.workerIsDisabled = true;
    }
  }

  public getWorkerIsDisabled() {
    return this.workerIsDisabled;
  }

  displayFnWorker(workerId) {
    var rtn = '';
    if (this.workers) {
      let worker = this.workers.find(x => x.id == workerId);
      if (worker)
        rtn = `${worker.nombre} ${worker.apellido1} ${worker.apellido2}`;
    }
    return rtn;
  }
  // #endregion
}

export class FormData {
  public id: number;
  public isNew: boolean = false;
  public oldForm = null;
  public fromDate: string;
  public toDate: string;
  public fullDay: string;
  public duplicated = null
  public valid: boolean = false;
  public hasChanges: boolean = false;
  public workerId: number;
  public description: string;
  public managerDescription: string;
  public reason: string;
  public status: number;
  public service: TrabajadoresService;

  constructor(formData) {
    this.id = formData.id
    this.fullDay = formData.diaCompleto;
    this.isNew = formData.id === 0
    this.workerId = formData.empleadoId;
    this.setDates(formData);
    this.description = formData.descripcion;
    this.managerDescription = formData.descripcionAprovador;
    this.reason = formData.motivo;
    this.status = formData.estado;
  }

  public setDates(formData): void {
    // fromDate y toDate vienen de los datos del formulario
    // - si nos vienen rellenos, son de tipo string,
    // - si los hemos modificado tienen tipo moment.

    this.fromDate = typeof formData.fechaDesde === 'string' ? formData.fechaDesde.split('T')[0] : formData.fechaDesde.format('YYYY-MM-DD');
    if (formData.diaCompleto)
      this.toDate = typeof formData.fechaHasta === 'string' ? formData.fechaHasta.split('T')[0] : formData.fechaHasta.format('YYYY-MM-DD');
  }

  public dispatch(allRequests) {
    this.setOldForm(allRequests)
    this.setDuplicated(allRequests)
    this.checkChanges()
  }

  private setOldForm(allRequests) {
    let sameForm = allRequests.find(r => r.id === this.id)
    if (sameForm) 
      this.oldForm = sameForm;
   }

  private setDuplicated(allRequests) {
    let sameDate = this.fullDay ?
      allRequests.filter(r => r.fechaDesde.split('T')[0] == this.fromDate && r.fechaHasta.split('T')[0] == this.toDate) :
      allRequests.filter(r => r.fechaDesde.split('T')[0] == this.fromDate);
    if (sameDate.length > 0)
      this.duplicated = sameDate;
  }

  private checkChanges() {
    if (this.isNew) {
      this.hasChanges = false;
      this.valid = true;
      return
    }

    let comparisons = [
      this.workerId != this.oldForm.empleadoId,
      this.description != this.oldForm.descripcion,
      this.fullDay != this.oldForm.diaCompleto,
      this.fromDate != this.oldForm.fechaDesde.split('T')[0],
      this.toDate != this.oldForm.fechaHasta.split('T')[0],
      this.reason != this.oldForm.motivo,
      this.status != this.oldForm.estado,
      this.managerDescription != this.oldForm.descripcionAprobador
    ]
    this.hasChanges = comparisons.some(Boolean)
    this.valid = this.hasChanges
  }

  public exists() {
    return this.duplicated
  }
}
