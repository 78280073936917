import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PlatformLocation } from '@angular/common';
import { AccountProvider } from '../users/services/user.service';
import { User } from '../users/users.module';
import { CookieManagerService } from '../_conf/cookie-manager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  private constructor(
    private router: Router,
    private _platformLocation: PlatformLocation,
    private _accountProvider: AccountProvider,
    private _cookieManagerService: CookieManagerService) {
  }

  private get account(): User {
    return this._accountProvider.getAccount();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    var host : string = this._platformLocation["location"].host;
    var subdominio = this.account && this.account.subdominio ? this.account.subdominio : '';

    if (subdominio != '' && !host.startsWith(subdominio)) {
      let url = this._platformLocation["location"].href;
      window.location.href = url.replace('://', '://' + this.account.subdominio + '.');
      return false;
    }

    var cookie = this._cookieManagerService.getCookie('userToken');
    if (cookie != null) {
      return true;
    }

    //if (localStorage.getItem('userToken') != null) {
    //  return true;
    //}
 
    this.router.navigate(['/login']);
    return false;
  }
}
