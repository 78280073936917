import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {
  readonly rootUrl = `${environment.apiUrl}api/configuracion`;
  constructor(private _httpClient: HttpClient) { }

  public get() {
    var url = `${this.rootUrl}/get`;
    return this._httpClient.get(url);
  }

  public getDaysAgo() {
    var url = `${this.rootUrl}/get-days-ago`;
    return this._httpClient.get(url);
  }

  public updateConfiguracion(data) {
    var url = `${this.rootUrl}/updateConfiguracion`;
    return this._httpClient.post(url, data);
  }
}
