import { Component, OnInit } from '@angular/core';
import { ProjectService } from '../services/project.service';
import { CustomerService } from '../services/customer.service';
import { TaskService } from '../services/task.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-proyectos',
    templateUrl: './proyectos-lista.component.html',
    styleUrls: ['./proyectos-lista.component.scss']
})
export class ProyectosListaComponent implements OnInit  {
  public projects: any;
  private customers: any;
  private tasks: any;
  public isMobile: boolean;
  public columns: string[] = ["NOMBRE", "DESCRIPCION", "CLIENTE", "TAREA PRINCIPAL"]

  constructor(
    private _projectService: ProjectService,
    private _taskService: TaskService,
    private _customerService: CustomerService,
    private _router: Router
  ) { }

  ngOnInit() {
    // No se deben mostrar los proyectos borrados
    this._projectService.getAvailableProjectList().subscribe(projects => {
      
      this.projects = this.sort(projects);
    })

    // No se deben mostrar los clientes borrados
    this._customerService.getAvailableCustomerList().subscribe(customers => {
      this.customers = customers;
    })

    // No se deben mostrar las tareas borradas
    this._taskService.getAvailableTaskList().subscribe(tasks => {
      this.tasks = tasks;
    })
  }

  private sort(data, key='nombre') {
    return data.sort((a, b) => (a[key] > b[key]) ? 1 : (b[key] < a[key]) ? 0 : -1);
  }

  public goToProjectCard(proj: any) {
    this._router.navigateByUrl(`/ficha-proyecto/${proj.id}`);
  }

  public getCustomer(id: number) {
    if (this.customers && id) {
      let customer = this.customers.find(cust => cust.id == id)
      if (customer)
        return customer.nombre
    }
    return ''
  }

  public getMainTask(id: number) {
    if (this.tasks && id) {
      let task = this.tasks.find(task => task.id == id)
      if (task)
        return task.descripcion;
    }
    return '';
  }
}
