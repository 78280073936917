import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomeLayoutComponent, DashboardComponent, LoginLayoutComponent } from './_layouts/layouts.module';
import { VerticalLayout1Component } from './_layouts-fuse/layout.module';
import { AuthGuard } from './_auth/auth.guard';

import { LoginComponent } from './users/users.module';
import { IndexComponent } from './index/index.component';
import { MatFormFieldModule } from '@angular/material';
import { ResetPINKeyComponent } from './users/users.module';
import { ResetPasswordComponent } from './users/users.module';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],    
    component: IndexComponent
  },
  {
      path: 'login',
      component: LoginComponent,
  },
  {
    path: 'recuperar-contrasenya/:workerId/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'recuperar-acceso-rapido/:workerId/:token',
    component: ResetPINKeyComponent
  }
];
@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forRoot(routes),
    MatFormFieldModule
  ]

})
export class AppRoutingModule { }
