import { Component, OnInit, Inject, Input, EventEmitter, Output} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { FichajesService } from '../fichajes.service';
import { MatSnackBar, MatSnackBarConfig, MatDialogRef } from '@angular/material';

import { MAT_DIALOG_DATA } from '@angular/material'
import { AsConfirmComponent } from 'src/app/_common/common-components/as-confirm/as-confirm.component';
import { CommentData } from '../../_common/common-classes/common-punchings';

@Component({
  selector: 'app-fichajes-modificar-comentario',
  templateUrl: './fichajes-modificar-comentario.component.html',
  styleUrls: ['./fichajes-modificar-comentario.component.css']
})
export class FichajesModificarComentarioComponent implements OnInit {
  public fichajesFormGroup: FormGroup;
  private snackbarConfig: MatSnackBarConfig;
  public confirmDialogRef: MatDialogRef<AsConfirmComponent>;
  private incomingData: CommentData;
  private action = 'MODIFICAR'


  constructor(
    private _formBuilder: FormBuilder,
    private _fichajesService: FichajesService,
    private _matSnackBar: MatSnackBar,
    private _selfDialogRef: MatDialogRef<FichajesModificarComentarioComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.incomingData = new CommentData(this.data);
    if (this.data.comment === '') {
      this.action = 'CREAR'
    }
  }

  public getRemainingChars() {
    const comment = this.fichajesFormGroup.controls.comment.value
    return 100 - comment.length
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.panelClass = ['error-snackbar'];
  }

  ngOnInit() {
    this.setSnackbarConfig();
    this.initializeForm();
    this.fillFormData()
  }

  private initializeForm() {
    this.fichajesFormGroup = this._formBuilder.group({
      commentId: new FormControl(null),
      comment: new FormControl(null, Validators.required),
      punchingInId: new FormControl(null, Validators.required)
    });
  }

  private fillFormData() {
    this.fichajesFormGroup.setValue({
      commentId: this.incomingData.commentId,
      comment: this.incomingData.comment,
      punchingInId: this.incomingData.punchingInId
    });
  }

  onSubmit() {
    var outData = this.fichajesFormGroup.getRawValue();
    outData.cancelled = false
    if (this.incomingData.hasChanges(outData)) {
      this._fichajesService.setPunchingComment(outData).subscribe(
        (inData: any) => {
          this._matSnackBar.open(inData.mensaje, 'Notificación');
          this._selfDialogRef.close(outData);
        },
        (error) => {
          this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
        }
      );
    } else {
      this._matSnackBar.open('No ha habido cambios', 'Notificación');
      outData.cancelled = true;
      this._selfDialogRef.close(outData);
    }
  }

  deletePunchingComment() {
    var outData = this.fichajesFormGroup.getRawValue();
    if (outData.commentId && outData.comment != '') {
      this._fichajesService.deletePunchingComment(outData).subscribe(
        (inData: any) => {
          this._matSnackBar.open(inData.mensaje, 'Notificación');
          this._selfDialogRef.close(outData);
        },
        (error) => {
          this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
        }
      );
    } else if (outData.commentId && !outData.comment) {
      this._matSnackBar.open('El comentario ya fue borrado anteriormente.', 'Error', this.snackbarConfig)
    } else  {
      this._matSnackBar.open('Todavía no hay ningún comentario para borrar.', 'Error', this.snackbarConfig)
    }
  }

  cancel() {
    var formData = this.fichajesFormGroup.getRawValue();
    formData.cancelled = true;
    this._matSnackBar.open('Modificación cancelada', 'Notificación', this.snackbarConfig);
    this._selfDialogRef.close(formData);
  }
}
