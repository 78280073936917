import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  readonly rootUrl = `${environment.apiUrl}api/proyectos`;

  constructor(private _http: HttpClient) { }

  public getTaskList() {
    // Todas las Tareas
    let url = `${this.rootUrl}/lista-tareas`;
    return this._http.get(url);
  }

  public getActiveTaskList() {
    // Tareas activas (no finalizadas)
    let url = `${this.rootUrl}/lista-tareas-activas`;
    return this._http.get(url);
  }

  public getAvailableTaskList() {
    // Tareas activas (no finalizadas) y no borradas
    let url = `${this.rootUrl}/lista-tareas-disponibles`;
    return this._http.get(url);
  }

  public getTaskListByProject(id: number) {
    // Tareas de un proyecto
    let url = `${this.rootUrl}/lista-tareas-filtradas`;
    let project = {id: id}
    return this._http.post(url, project);
  }

  public getActiveTaskListByProject(project: any) {
    // Tareas activas de un proyecto
    let url = `${this.rootUrl}/lista-tareas-entrada`;
    return this._http.post(url, project);
  }

  public getAvailableTaskListByProject(project: any) {
    // Tareas disponibles de un proyecto
    let url = `${this.rootUrl}/lista-tareas-dispo-entrada`;
    return this._http.post(url, project);
  }

  public getTaskCard(id: number): any {
    let url = `${this.rootUrl}/ficha-tarea/${id}`;
    return this._http.get(url);
  }

  public addTask(task: any) {
    let url = `${this.rootUrl}/alta-tarea`;
    return this._http.post(url, task);
  }

  public deleteTask(task: any) {
    let url = `${this.rootUrl}/borrar-tarea`;
    return this._http.post(url, task);
  }

  public getTaskStatusList() {
    let url = `${this.rootUrl}/estados-tarea`;
    return this._http.get(url);
  }
}
