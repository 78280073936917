export class CalendarDate {
  public date: string = '';
  public workers: string[] = []
  public isHoliday: boolean

  constructor(date: string, workers: string[], isHoliday: boolean = false) {
    this.date = date;
    this.workers = workers;
    this.isHoliday = isHoliday;
  }
}

export class Calendar {
  public calDates: CalendarDate[];

  constructor(incomingData: any) {
    // Poblamos calDates, aplicamos días de fiesta del calendario de la empresa
    this.calDates = [];
    this.addDays(incomingData.dias, incomingData.diasFestivosEmpresa)
    this.addWorkers(incomingData.empleados)

  }

  private addDays(days, companyHolidays) {
    for (let day of days) {
      let date = day.fecha.slice(0, 10);
      let holidayFound = companyHolidays.filter(f => f.fecha.slice(0, 10) == date);
      this.calDates.push(new CalendarDate(date, [], holidayFound.length > 0))
    }
  }

  private addWorkers(workers: any) {
    // Iteramos empleados para insertarlos al vector workers de calendarDate.
    for (let item of workers) {
      for (let day of item.dias) {
        if (day.festivoEmpleado || day.festivoHoras) {
          let date = day.fecha.slice(0, 10);
          let calDate = this.calDates.find(c => c.date == date)
          if (!calDate.workers.includes(item.empleadoNombre))
            calDate.workers.push(item.empleadoNombre);
        }
      }
    }
  }

  public addDates(incomingData: any) {
    this.addDays(incomingData.dias, incomingData.diasFestivosEmpresa);
    this.addWorkers(incomingData.empleados);
  }
}
