import { Component, OnInit, Input } from '@angular/core';
import { UploadService } from './upload.service';

@Component({
  selector: 'app-as-file-upload',
  templateUrl: './as-file-upload.component.html',
  styleUrls: ['./as-file-upload.component.css']
})
export class AsFileUploadComponent implements OnInit {

  @Input() tipo: string;
  constructor(private _uploadService: UploadService) { }

  ngOnInit() {
  }

  files: any = [];

  uploadFile(event) {
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element.name)
    }
    debugger;
    var data2 = {
      tipo: 'Empleados',
      //file: this.files[0]
    };
    var formData = new FormData();
    formData.append(this.files[0].name, this.files[0]);

    this._uploadService.import(this.files[0]).subscribe((data) => {
      console.info('FILE UPLOADED', data)
    });
  }

  deleteAttachment(index) {
    this.files.splice(index, 1)
  }

}
