import { Component, OnInit, ViewChild, ElementRef, AfterContentInit, AfterViewInit, AfterViewChecked } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { TrabajadoresService } from '../trabajadores.service';
import { Router } from '@angular/router';
import { TenantService } from '../../_conf/tenant.service';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver'

@Component({
  selector: 'app-trabajadores-lista',
  templateUrl: './trabajadores-lista.component.html',
  styleUrls: ['./trabajadores-lista.component.scss']
})
export class TrabajadoresListaComponent implements OnInit {
  public isMobile: boolean;
  public columnasTest = ["NIF", "Nombre"];
  public empleados = [];
  public centrosTrabajo = []
  public columnas = ["NIF", 'Nombre', 'Mail', 'Telefono', "Centro", "Departamento", 'UsuarioWeb', 'Activo', 'FicharContraProyectos'];
  public employeeStatus = null
  public statuses = []
  public selectedStatus
  public selectedWorkCenter
  private maxDuration = 10 ** 4;
  public hasOnlyDocNo = false

  private isLite = false
  private _empleados: any[] = [];
  private snackbarConfig: MatSnackBarConfig
  @ViewChild('fileUpload') fileUpload: ElementRef

  constructor(
    private _trabajadoresService: TrabajadoresService,
    private _router: Router,
    private _matSnackBar: MatSnackBar,
    private _tenantService: TenantService
  ) {
  }

  ngOnInit() {
    this.hasOnlyDocNo = this._trabajadoresService.tenantUsesDocAsPass()
    this._trabajadoresService.getWorkers().subscribe((data: any) => {
      this._empleados = data
      this.empleados = data
      this.setStatuses()
      this.getCentrosTrabajo()
    })
    this.isLite = !this._trabajadoresService.tenantUsesPinID()
    this.setSnackbarConfig()
  }

  private getCentrosTrabajo() {
    this._trabajadoresService.getCentrosTrabajo().subscribe((data: any) => {
      this.centrosTrabajo = data
      this.centrosTrabajo = [{ id: 0, nombre: 'Todos' }, ...this.centrosTrabajo]
      this.selectedWorkCenter = this.centrosTrabajo[0]
    })
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig()
    this.snackbarConfig.verticalPosition = 'top'
    this.snackbarConfig.horizontalPosition = 'right'
    this.snackbarConfig.duration = this.maxDuration
    this.snackbarConfig.panelClass = ['error-snackbar']
  }

  private setStatuses() {
    this.statuses = [
      { id: 0, name: 'Ambos', value: null },
      { id: 1, name: 'Activos', value: true },
      { id: 2, name: 'Finalizados', value: false}
    ]
    this.selectedStatus = this.statuses[0]
  }

  verEmpleado(empleado:any) {
    this._router.navigateByUrl(`/empleado/${empleado.id}`);
  }

  getWorker(worker) {
    return `${worker.nombre} ${worker.apellido1} ${worker.apellido2}`.trim();
  }

  getWorkCenter(worker: any, skipDefault=false) {
    const workCenter = this.centrosTrabajo.find(w => w.id === worker.centroTrabajo)
    if (workCenter) {
      if (skipDefault && workCenter.id === 0) {
        return ''
      }
      return workCenter.nombre
    }
    return ''
  }

  workCenterChanges(workCenter: any) {
    if (workCenter.id === 0) {
      this.empleados = this._empleados
    } else {
      this.empleados = this._empleados.filter(e => e.centroTrabajo === workCenter.id) 
    }

    const status = this.selectedStatus
    if (status.id !== 0) {
      this.empleados = this.empleados.filter(e => e.activo === status.value)
    }

  }

  statusChanges(status: any) {
    if (status.id === 0) {
      this.empleados = this._empleados
    } else {
      this.empleados = this._empleados.filter(e => e.activo === status.value)
    }

    const workCenter = this.selectedWorkCenter
    if (workCenter.id !== 0) {
      this.empleados = this.empleados.filter(e => e.centroTrabajo === workCenter.id)
    }
  }

  resetFilters() {
    const workCenter = this.centrosTrabajo.find(w => w.id === 0)
    this.selectedWorkCenter = workCenter
    const status = this.statuses.find(w => w.id === 0)
    this.selectedStatus = status
  }

  exportTemplate() {
      const employee = new Employee(null)
      employee.isLite = true  // this.isLite
      const rows = employee.columns
      this.exportToCSV([rows], 'plantilla')
  }

  exportDocument(evt: any) {
    if (evt.shiftKey || evt.ctrlKey || evt.metaKey) {
      // Si se clica con mayús/ctrl es para descargar la plantilla del csv que se debe subir.
      this.exportTemplate()
    } else {
      const headers = new Employee({
        nombre: '*Nombre',
        apellido1: '*Apellido1',
        apellido2: 'Apellido2',
        nif: '*DNI / NIE',
        centroTrabajo: '*Centro de trabajo',
        // mail: '*Correo electrónico',
        noSegSocial: '*Nº Seg. Social',
        noCtaBancaria: '*Cta. Bancaria',
        importeAPagar: '*Importe a pagar',
        telefono: 'Teléfono',
        activo: 'Activo'
      })
      const rows = [new LiteEmployee(headers, this.centrosTrabajo)]
      this.empleados.forEach(e => {
        const empl = new Employee(e)
        empl.isLite = true
        delete empl.email
        rows.push(empl.toLiteEmployee(this.centrosTrabajo))
      })
      this.exportToCSV(rows, 'empleados')
    }
  }

  exportToCSV(items: any[], filename = 'plantilla') {
    const worksheet = XLSX.utils.json_to_sheet(items, { skipHeader: true })
    const csv = XLSX.utils.sheet_to_csv(worksheet, { FS: ';' })
    this.saveAsFile(csv, `${filename}.csv`, 'text/csv;charset=UTF-8');
  }

  exportToExcelOld(items: any[], filename = 'plantilla') {
    // TODO: separar con ";" (hay que cambiar configuración a español, francés, ...)
    const workbook: XLSX.WorkBook = XLSX.utils.book_new()
    const worksheet = XLSX.utils.json_to_sheet(items, { skipHeader: true })
    XLSX.utils.book_append_sheet(workbook, worksheet, filename)
    XLSX.writeFile(workbook, `${filename}.csv`, { type: "array", bookType: 'csv', });
  }

  private saveAsFile(buffer: any, fileName: string, fileType: string): void {
    const blobArray = [`\uFEFF${buffer}`]  // Añadimos carácter unicode \ufeff para forzar codifición en UTF8.
    const data: Blob = new Blob(blobArray, { type: fileType });
    FileSaver.saveAs(data, fileName );
  }

  clicked(evt: any) {
    if (evt.shiftKey || evt.ctrlKey || evt.metaKey) {
      evt.stopPropagation()
      this.exportTemplate()
      return false
    }
    this.fileUpload.nativeElement.click()
  }

  importDocument(evt: any) {
    const files = evt.target.files
    const file: File = files.item(0)
    if (file.type !== 'text/csv') {
      evt.stopPropagation()
      return false
    }

    const reader = new FileReader()
    reader.onload = (e: any) => {
      const binarystr: string = e.target.result
      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" })

      const wsname: string = wb.SheetNames[0]
      const ws: XLSX.WorkSheet = wb.Sheets[wsname]

      const employees: LiteEmployee[] = []
      const data = XLSX.utils.sheet_to_json(ws, { rawNumbers: false })
      const errors = []
      data.forEach(empl => {
        let employee = new Employee(null)
        const result = employee.setAsLite(empl, this.centrosTrabajo)
        if (!result.success)
          errors.push(result)
        employees.push(employee)
      })

      const seen = []
      // const seen =  this._empleados.map(e => e.nif) // Para impedir actualizar empleados.
      employees.forEach(empl => {
        if (seen.includes(empl.documentNo)) {
          errors.push({success: false, error: 'NIF repetido', mistake: empl.documentNo})
        }
        seen.push(empl.documentNo)
      })

      if (errors.length === 0) {
        this._trabajadoresService.altaEmpleados(employees).subscribe(
          (data: any) => {
            const json = JSON.parse(data)
            if (json.inserted > 0 || json.updated > 0) {
              this.resetFilters()
              // Cargamos de nuevo los empleados.
              this._empleados = json.employees
              this.empleados = json.employees

              // Indicamos cambios.
              const iPlural = json.inserted === 1 ? '' : 's'
              const uPlural = json.updated === 1 ? '' : 's'
              let modified = json.updated > 0 ? `Se han modificado ${json.updated} registro${uPlural}` : ''
              if (json.readmitted > 0) {
                modified = modified != '' ? `${modified}, s` : 'S'
                const ePlural = json.readmitted == 1 ? '' : 's'
                modified +=`e ha readmitido ${json.readmitted} empleado${ePlural}`
              }
              const created = json.inserted > 0 ? `e han añadido ${json.inserted} nuevo${iPlural} registro${iPlural}.` : ''
              const middle = json.updated > 0 && json.inserted > 0 ? ' y s' : json.updated === 0 && json.inserted > 0 ? 'S' : '.'
              let message = `${modified}${middle}${created}`
              if (json.inserted === 0 && json.readmitted > 0 && json.updated > 0)
               message = message.replace(', ', ' y ')
              this._matSnackBar.open(message, 'Notificación', { duration: this.maxDuration })

            } else if (json.success) {
              this._matSnackBar.open('No ha habido cambios', 'Notificación', this.snackbarConfig)

            } else {
              this._matSnackBar.open('Ocurrió un error', 'Error', this.snackbarConfig)
            }
          },
          (error) => {
            console.error(error)
            this._matSnackBar.open('Ocurrió un error', 'Error', this.snackbarConfig)
          },
          () => { }
        )
      } else {
        console.info('errors', errors)
        errors.forEach(error => {
          let msg = ''
          if (error.error === 'Centro de trabajo')
            msg = `Centro de trabajo '${error.mistake}' desconocido.`
          else if (error.error === 'NIF repetido')
            msg = `DNI/NIE repetido: ${error.mistake}`
          this._matSnackBar.open(msg, 'Error', this.snackbarConfig)
        })
      }
    }
    reader.readAsText(files[0]);
  }
}


class Employee {
  /*
   * Clase para cualquier empresa con todos los campos que trae el back.
   */
  isLite = false
  // from system
  id: number
  // requested
  name: string
  // requested
  surname1: string
  // optional
  surname2: string
  // optional if not useDocument, only cap letters and numbers
  documentNo: string
  // optional if not useDocument, must exist.
  workCenter: string
  // optional
  department: string
  // optional if not useDocument elsewhere requested
  username: string
  // optional if not useDocument elsewhere requested
  password: string
  socialSecurityNo: string
  bankAccountNo: string
  amount: number
  // requested
  email: string
  // optional
  phoneNo: string
  // optional
  active: string | boolean

  constructor(empleado: any) {
    if (!empleado)
      return
    this.id == empleado ? empleado.id : null
    this.name = empleado.nombre
    this.surname1 = empleado.apellido1
    this.surname2 = empleado.apellido2 ? empleado.apellido2 : '' 
    this.documentNo = empleado.nif ? empleado.nif : ''
    this.workCenter = empleado.centroTrabajo ?  empleado.centroTrabajo : ''
    this.department = empleado.departamento ? empleado.departamento : '' 
    this.password = empleado.password ? empleado.password : ''
    this.email = empleado.mail ? empleado.mail : '' 
    this.phoneNo = empleado.telefono ? empleado.telefono : ''
    this.bankAccountNo = empleado.noCtaBancaria ? empleado.noCtaBancaria : ''
    this.socialSecurityNo = empleado.noSegSocial ? empleado.noSegSocial : ''
    this.amount = empleado.importeAPagar ? empleado.importeAPagar : 0
    this.active = empleado.activo
  }

  setAsLite(empl: any, workCenters: any[]) {

    const  workCenter = workCenters.find(w => w.nombre.toLowerCase() === empl['*Centro de trabajo'].toLowerCase())
    const result = {success: true, error: '', mistake: ''}
    if (!workCenter) {
      return { success: false, error: 'Centro de trabajo', mistake: empl['*Centro de trabajo'] }
    }

    this.isLite = true
    this.id = null
    this.name = empl['*Nombre']
    this.surname1 = empl['*Apellido1']
    this.surname2 = empl['Apellido2']
    this.documentNo = empl['*DNI / NIE']
    this.workCenter = workCenter.id
    // this.email = empl['*Correo electrónico']
    this.socialSecurityNo = empl['*Nº Seg. Social']
    this.bankAccountNo = empl['*Cta. Bancaria']
    // https://stackoverflow.com/a/61679717
    const isHeader = empl['*Importe a pagar'].toString().startsWith("*")
    const amount = isHeader ? empl['*Importe a pagar'] : empl['*Importe a pagar'].replace(/[\. ]/g, '').replace(',', '.')
    this.amount = isNaN(parseFloat(amount)) ? empl['*Importe a pagar'] : parseFloat(amount)
    this.phoneNo = empl['Teléfono']
    this.active = empl['Activo'] ? ['sí', 'si', '+', 'x'].includes(empl['Activo'].toLowerCase()) : true
    return result
  }

  get columns() {
    /*
     * columnas con * son obligatorias siempre
     */
    const defaultFields = [
      '*Nombre', '*Apellido1', 'Apellido2', 'DNI / NIE', '*Correo electrónico', 'Teléfono', '*Usuario', '*Contraseña', 
      'Centro de trabajo', 'Departamento'
    ]
    const useDocumentFields = [
      '*Nombre', '*Apellido1', 'Apellido2', '*DNI / NIE', '*Centro de trabajo', '*Nº Seg. Social', '*Cta. Bancaria', '*Importe a pagar', 'Teléfono', 'Activo'
    ]
    return this.isLite ? useDocumentFields : defaultFields
  }

  toLiteEmployee(workCenters: any[]) {
    return new LiteEmployee(this, workCenters)
  }
}

class LiteEmployee {
  /*
   * Clase específica para empresa que inicia sesión con DNI.
   */
  name: string
  surname1: string
  surname2: string
  documentNo: string
  workCenter: string
  // email: string
  socialSecurityNo: string
  bankAccountNo: string
  amount: number | string
  phoneNo: string
  active: string | boolean

  constructor(employee: Employee, workCenters: any = []) {
    const workCenter = workCenters.find(w => `${w.id}` == employee.workCenter)
    const active = typeof employee.active == 'string' ? employee.active : typeof employee.active == 'boolean' && employee.active ? 'sí' : 'no'
    this.name = employee.name
    this.surname1 = employee.surname1
    this.surname2 = employee.surname2
    this.documentNo = employee.documentNo
    this.workCenter = workCenter ? workCenter.nombre : employee.workCenter
    // this.email = employee.email
    this.socialSecurityNo = employee.socialSecurityNo
    this.bankAccountNo = employee.bankAccountNo
    const isHeader = employee.amount.toString().startsWith('*')
    this.amount = isHeader ? employee.amount : employee.amount.toString().replace(/[ ,]/g, '').replace('.', ',')
    this.phoneNo = employee.phoneNo
    this.active = active
  }
}

class DefaultEmployee {
  name: string
  surname1: string
  surname2: string
  documentNo: string
  email: string
  phoneNo: string
  username: string
  password: string
  workCenter: string
  department: string
}
