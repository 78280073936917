export class User {
  codigoUsuario: string;
  isAuthenticated: boolean;
  nombre: string;
  usuarioNavision: string;
  almacen: string;
  userName: string;
  roles: Array<Rol> = [];
  schema: string;
  subdominio: string;
  empleadoId: string;
  userId: string;
  id: number;
  rolNombres: Rol = new Rol();
  active: boolean;
  deleted: boolean;

  private isInRole(rolNombre): boolean {
    return this.roles.find(f => f.roleId == rolNombre) != null
  }
  public get isAdminWeb(): boolean {
    return this.isInRole(this.rolNombres.AdminWeb);
  }
  public get isEmpleado(): boolean {
    return this.isInRole(this.rolNombres.Empleado);
  }
  public get isResponsable(): boolean {
    return this.isInRole(this.rolNombres.Responsable);
  }
}

export class Rol {
  roleId: string;
  roleName: string;
  AdminWeb = "AdminWeb";
  Empleado = "Empleado";
  Responsable = "Responsable";
}
