import { Component, OnDestroy, OnInit, AfterViewInit, ChangeDetectorRef, Directive, ViewChild, ElementRef, Renderer } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { UserService, AccountProvider } from '../services/user.service';
import { MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { TenantProvider } from 'src/app/_conf/tenant.service';
import { User } from '../users.module';
import { CookieManagerService } from 'src/app/_conf/cookie-manager.service';
import { AsSrcDirective } from '../../_common/common-directives/as-src.directive';
import { RecoverComponent } from '../recover/recover.component';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
  animations: fuseAnimations
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  focus: boolean;
  loginForm: FormGroup;
  pinForm: FormGroup;
  sendMailForm: FormGroup;
  loginFormErrors: any;
  pinMode: boolean;
  public tenant;
  public pinKey: string;
  private account: User;
  public pwdIcon: string;
  public pwdVisibility: boolean;
  public pwdVisibilityClicked: boolean;
  public pkIcon: string;
  public pkVisibility: boolean;
  public pkVisibilityClicked: boolean;
  private snackbarConfig: MatSnackBarConfig;
  public dialogRef: MatDialogRef<RecoverComponent>;
  public btnIsDisabled: boolean;
  @ViewChild('pinId') public pinIdInput: ElementRef;
  @ViewChild('pinKey') public pinKeyInput: ElementRef;
  @ViewChild('userInput') public userInput: ElementRef;
  private pattern = /(Arrow(Down|Up|Left|Right)|Backspace|(Caps|Num|Scroll)Lock|Control|Delete|Enter|End|F\d+|Home|Page(Up|Down)|Pause|PrintScreen)|Shift|Tab/;
  private hidePinId = false

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
      private _fuseConfigService: FuseConfigService,
      private _formBuilder: FormBuilder,
      private _userService: UserService,
      private _matSnackBar: MatSnackBar,
      private _router: Router,
      private _tenantProvider: TenantProvider,
      private _accountProvider: AccountProvider,
      private _cookieManagerService: CookieManagerService,
      private _matDialog: MatDialog,
      private _chDRef: ChangeDetectorRef
    ) {
      // Configure the layout
      this._fuseConfigService.config = {
          layout: {
              navbar: {
                  hidden: true
              },
              toolbar: {
                  hidden: true
              },
              footer: {
                  hidden: true
              }
          }
      };
      
      // Set the defaults
      this.loginFormErrors = {
        email: {},
        password: {},
        empresa: {}
      };

      // Set the private defaults
      //this._unsubscribeAll = new Subject();

      // TODO: this.pinMode a false si configGeneral pinMode = false y entonces ocultamos el botón de conmutar pin/contraseña
      this.pinMode = true; 

      // Password/PinKey Recovery
      this.btnIsDisabled = true;

      this.pkVisibilityClicked = false;
      this.pwdVisibilityClicked = false;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

  /**
    * On init
    */
  ngOnInit(): void {
    this.setPageTitleWithAppVersion()
    this.focus = true
    this.setSnackbarConfig()
    this.pkIcon = 'visibility'
    this.pkVisibility = false
    this.pwdIcon = 'visibility'
    this.pwdVisibility = false

    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required]],
      password: ['', Validators.required]
    });
    this.pinForm = this._formBuilder.group({
      pin: [''],
      pinId: ['']
    });

    this.sendMailForm = this._formBuilder.group({
      recoveryEmail: ['', Validators.required]
    });

    /*
    this.loginForm.valueChanges
      .pipe(
        takeUntil(this._unsubscribeAll)).subscribe(
          (data) => {
            this.onLoginFormValuesChanged();
          },
          (error) => { console.log("LOGIN ERROR") }
    );
    if (!this.tenant) {
       this.loginForm.addControl("empresa", new FormControl('', Validators.required));
    }
    */
    this.tenant = this._tenantProvider.getTenant()

    this.hidePinId = environment.useDocAsPass.includes(this.tenant)

  }

  /**
    * On destroy
    */
  ngOnDestroy(): void {
      // Unsubscribe from all subscriptions
      //this._unsubscribeAll.next();
      //this._unsubscribeAll.complete();
  }


  /**
    * On AfterViewInit
    */
  ngAfterViewInit() {
    // Colocar el cursor en el input

    if (this.pinIdInput && !this.hidePinId) {
      this.pinIdInput.nativeElement.select()
      this.pinIdInput.nativeElement.focus()
    } else if (this.pinKeyInput && this.hidePinId) {
      this.pinKeyInput.nativeElement.select()
      this.pinKeyInput.nativeElement.focus()
    } else if (this.userInput) {
      this.pinKeyInput.nativeElement.select()
      this.pinKeyInput.nativeElement.focus()
    }
  }

  ngAfterViewChecked() {
    this._chDRef.detectChanges()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------
  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.panelClass = ['error-snackbar']
  }

  private get welcomeSnackbar() {
    const conf = new MatSnackBarConfig()
    conf.horizontalPosition = 'right';
    conf.verticalPosition = 'bottom';
    conf.duration = 3000;
    conf.panelClass = ['success-snackbar']
    return conf
  }


  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
    * On form values changed
    */
  onLoginFormValuesChanged(): void {
    /*
    for (const field in this.loginFormErrors) {
        if (!this.loginFormErrors.hasOwnProperty(field)) {
            continue;
        }

        // Clear previous errors
        this.loginFormErrors[field] = {};

        // Get the control
        const control = this.loginForm.get(field);

        if (control && control.dirty && !control.valid) {
            this.loginFormErrors[field] = control.errors;
        }
    }
    */
  }

  public toggleMode(): void {
    this.pinMode = !this.pinMode;
  }


  public Click_Numpad(num): void {
    if (this.focus) {
      let pinId = this.pinForm.controls.pinId.value;
      pinId = num == "clear" ? pinId.substring(0, pinId.length - 1) : `${pinId}${num}`;
      this.pinForm.patchValue({ "pinId": pinId });
 
    } else {
      var pin = this.pinForm.value.pin;
      pin = num == "clear" ? pin.substring(0, pin.length - 1) : `${pin}${num}`;
      this.pinForm.patchValue({ "pin": pin });

      this.pinKey = btoa(`${this.tenant}${pin}`);
    }
  }

  public checkKeyDown(evt: any) {
    let key = evt.key;
    let pin = this.pinForm.value.pin;
    if (!key) {
      return false
    } else if (key == 'Enter') {
      this.pinKey = btoa(`${this.tenant}${pin}`);
      this.onSubmitPin()
      return false
    } else if (this.pattern.test(key)) {
      return true
    } else if (key == 'Escape') {
      if (this.focus) {
        this.pinForm.patchValue({ "pinId": '' });
      } else {
        this.pinForm.patchValue({ "pin": '' });
        this.pinKey = '';
      }
      return true;
    } else if (key.toLocaleUpperCase() == 'V') {
      this.toggleVisibility(evt)
      return false;
    } else if (key.toLocaleUpperCase() == 'I') {
      this.pinIdInput.nativeElement.focus();
      return false;
    } else if (key.toLocaleUpperCase() == 'P') {
      this.pinKeyInput.nativeElement.focus();
      return false;
    } else if (/.*\D.*/.test(key)) {
      return false;
    }
  }

  setPINKey($event: KeyboardEvent) {
    let key = $event.key;
    let pin = this.pinForm.value.pin;
    if (/\d/.test(key)) {
      this.pinForm.patchValue({ "pin": pin })
      this.pinKey = btoa(`${this.tenant}${pin}`);
      return true;
    }
  }

  onSubmit() {
    var user = this.loginForm.value.email;
    var pass = this.loginForm.value.password;
    var empresa = this.loginForm.value.empresa;
      this._userService.userAuthentication(user, pass, empresa).subscribe(
        (authData: any) => {
            //localStorage.setItem('userToken', data.access_token);
            this._cookieManagerService.setCookie('userToken', authData.access_token, 1);
          this._accountProvider.getAccountSync().subscribe((accData) => {
              this._accountProvider.setAccount(accData);
              this.account = this._accountProvider.getAccount()
              this._matSnackBar.open(`Bienvenido ${this.account.userName}`, '', this.welcomeSnackbar);
              this._router.navigate(['']);
            });                
          },
        (error) => {
          this._matSnackBar.open("Usuario o contraseña inválidos, inténtelo de nuevo.", 'Error', this.snackbarConfig);
        }
      );
  }

  onSubmitPin() {
    if (this.hidePinId) {
      this.onSubmitUserDoc()
      return
    }

    var pin = this.pinForm.value.pin;
    let pinId = this.pinForm.value.pinId
    this._userService.userPinAuthentication(pin, this.pinKey, pinId, this.tenant).subscribe((authData: any) => {
        //localStorage.setItem('userToken', data.access_token);
        this._cookieManagerService.setCookie('userToken', authData.access_token, 1);
        this._accountProvider.getAccountSync().subscribe((accData) => {
          this._accountProvider.setAccount(accData as User);
          this.account = this._accountProvider.getAccount()
          this._matSnackBar.open(`Bienvenido ${this.account.userName}`, '', this.welcomeSnackbar);
          this._router.navigate(['']);
        });
      },
      (error) => {
        this._matSnackBar.open("PIN o ID erróneos, inténtelo de nuevo.", 'Error', this.snackbarConfig);
        console.error(error)
      }
    );
  }

  private onSubmitUserDoc() {
    // Utilizamos solo el PinKey sin PinID el nº debe tener al menos 8 dígitos.
    var pin = this.pinForm.value.pin;
    const message = "No hay coincidencia, inténtelo de nuevo."
    if (pin.length >= 8) {
      this._userService.userDocAuthentication(pin, this.pinKey, this.tenant).subscribe((authData: any) => {
        //localStorage.setItem('userToken', data.access_token);
        this._cookieManagerService.setCookie('userToken', authData.access_token, 1)
        this._accountProvider.getAccountSync().subscribe((accData) => {
          this._accountProvider.setAccount(accData as User)
          this.account = this._accountProvider.getAccount()
          this._matSnackBar.open(`Bienvenido ${this.account.userName}`, '', this.welcomeSnackbar)
          this._router.navigate([''])
        });
      },
        (error) => {
          this._matSnackBar.open(message, 'Error', this.snackbarConfig);
          console.error(error)
        }
      )
    } else {
      this._matSnackBar.open(message, 'Error', this.snackbarConfig);
    }
  }

  // #region Visibilidad carácteres de contraseñas
  public toggleVisibility(evt) {
    let target = evt.target.attributes.name.nodeValue
    let visibility = target == 'pinkey' ? this.pkVisibility : this.pwdVisibility;
    let visibilityClicked = target == 'pinkey' ? this.pkVisibilityClicked : this.pwdVisibilityClicked;
    let mouseleave = evt.type == 'mouseleave'
    if (visibilityClicked || (!visibilityClicked && mouseleave && !visibility))
      return;
    if (target == 'pinkey') {
      this.pkVisibility = !visibility;
      this.pkIcon = this.pkVisibility ? 'visibility_off' : 'visibility';
    } else {
      this.pwdVisibility = !visibility;
      this.pwdIcon = this.pwdVisibility ? 'visibility_off' : 'visibility';
    }
  }

  public pinKeyVisibilityClicked() {
    if (this.pkVisibilityClicked && this.pkVisibility) {
      this.pkIcon = 'visibility';
      this.pkVisibility = false;
      this.pkVisibilityClicked = false;
      return
    }
    this.pkVisibilityClicked = true;
  } 

  public passwordVisibilityClicked() {
    if (this.pwdVisibilityClicked && this.pwdVisibility) {
      this.pwdIcon = 'visibility';
      this.pwdVisibility = false;
      this.pwdVisibilityClicked = false;
      return
    }
    this.pwdVisibilityClicked = true;
  }
  // #endregion

  // Nuevos métodos dos en uno
    private setDialogConfig(row: any): MatDialogConfig {
    /*
     *  He tenido que crear la configuración por medio de la clase
     *  ya que no estaba cogiéndola pasándole un objeto.
     */
    const dialogConf = new MatDialogConfig();
    dialogConf.closeOnNavigation = true;
    dialogConf.disableClose = false;
    dialogConf.data = row;
    dialogConf.minWidth = '30%';
    dialogConf.panelClass = 'popup';
    return dialogConf;
  }

  public openDialog(): void {
    this.popupEmailRecovery();
  }

  public popupEmailRecovery() {
    const data = {pinMode: this.pinMode}
    const config = this.setDialogConfig(data);

    this.dialogRef = this._matDialog.open(RecoverComponent, config);
    this.dialogRef.afterClosed().subscribe(
      (data) => {
        if (data) {
          this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this._router.navigate(['modificar-fichajes']);
          });
        }
      });
  }

  public getTarget(): string {
    return this.pinMode ? 'clave de acceso rápido' : 'contraseña'
  }

  public checkSendMailBtn(): void {
    const value = this.sendMailForm.controls.recoveryEmail.value
    this.btnIsDisabled = value == '';
  }

  private setPageTitleWithAppVersion() {
    const version = environment.app_version
    const title = document.title
    if (!title.includes('versión'))
      document.title = `${title} - versión actual: ${version}`
  }

  public setText(evt: any) {
    /*
     * (input)="setText($event)"
     */
    const pin = evt.target.value
    this.loginForm.patchValue({ 'pin': pin })
    this.pinKey = btoa(`${this.tenant}${pin}`);
  }

  public pasteText(evt: any) {
    const pin = evt.clipboardData.getData('text/plain')
    this.loginForm.patchValue({ 'pin': pin })
    this.pinKey = btoa(`${this.tenant}${pin}`);
  }
}
