import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TrabajadoresService } from '../trabajadores.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-centro-trabajo-ficha',
  templateUrl: './centro-trabajo-ficha.component.html',
  styleUrls: ['./centro-trabajo-ficha.component.css']
})
export class CentroTrabajoFichaComponent implements OnInit {

  public fgCentro : FormGroup
  constructor(
    private _formBuilder: FormBuilder,
    private _trabajadoresService: TrabajadoresService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router,
    private _dialogRef: MatDialogRef<CentroTrabajoFichaComponent>,
    public _matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {

    this.fgCentro = this._formBuilder.group({
      id: new FormControl({ value: 0, disabled: true }, Validators.required),
      nombre: new FormControl('', Validators.required),
      razonSocial: new FormControl(''),
      cif: new FormControl(''),
      direccion: new FormControl('', Validators.required),
      cp: new FormControl('', Validators.required),
      poblacion: new FormControl('', Validators.required),
      provincia: new FormControl('', Validators.required),
      pais: new FormControl('', Validators.required),
      mail: new FormControl(''),
      telefono: new FormControl('')
    });


    if (this.data && this.data.id) {
      this.fgCentro.setValue({
        id: this.data.id,
        nombre: this.data.nombre,
        razonSocial: this.data.razonSocial,
        cif: this.data.cif,
        direccion: this.data.direccion,
        cp: this.data.cp,
        poblacion: this.data.poblacion,
        provincia: this.data.provincia,
        pais: this.data.pais,
        mail: this.data.mail,
        telefono: this.data.telefono
      });
    }
  }

  onSubmit() {
    var ct = this.fgCentro.getRawValue();
    this._trabajadoresService.updateCentroTrabajo(ct).subscribe(
      (data) => {
        this._dialogRef.close(this.data)
        this._matSnackBar.open("Centro de Trabajo actualizado", '');
        this._dialogRef.close();
        this._router.navigate(['centros-trabajo']);
      }, (error) => {
        let sbConfig = new MatSnackBarConfig();
        sbConfig.horizontalPosition = 'right';
        sbConfig.verticalPosition = 'top';
        sbConfig.duration = 3000;
        sbConfig.panelClass = ['error-snackbar'];
        this._matSnackBar.open(error.error.message, 'Error', sbConfig);
      }
      );
    
  }
}
