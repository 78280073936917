import { Injectable } from '@angular/core';
import { AccountProvider } from './user.service';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AdminGuardService implements CanActivate {

  constructor(
    private _router: Router,
    private _accountProvider: AccountProvider,
    private _platformLocation: PlatformLocation
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    var account = this._accountProvider.getAccount();
    var host: string = this._platformLocation["location"].host;
    var subdominio = account && account.subdominio ? account.subdominio : '';

    if (subdominio != '' && !host.startsWith(subdominio)) {
      let url = this._platformLocation["location"].href;
      window.location.href = url.replace('://', '://' + account.subdominio + '.');
      return false;
    }

    var puede = account && account.isAdminWeb;
    if (puede)
      return true;
    this._router.navigateByUrl('/login')
    return account && account.isAdminWeb;
  }
}
