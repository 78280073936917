import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { CalendarModule } from 'angular-calendar';

import { CalendariosListaComponent } from './calendarios-lista/calendarios-lista.component';
import { CalendariosFichaComponent } from './calendarios-ficha/calendarios-ficha.component';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../_auth/auth.guard';
import { CalendariosHorariosComponent } from './calendarios-horarios/calendarios-horarios.component';
import { CalendariosHorariosFichaComponent } from './calendarios-horarios-ficha/calendarios-horarios-ficha.component';
import { CalendariosFestivosComponent } from './calendarios-festivos/calendarios-festivos.component';
import { CalendariosFestivosFichaComponent } from './calendarios-festivos-ficha/calendarios-festivos-ficha.component';
import { CalendariosSelectorComponent } from './calendarios-selector/calendarios-selector.component';
import { CalendarioFichaComponent } from './calendario-ficha/calendario-ficha.component';
const routes: Routes = [
  {
    path: 'calendarios',
    component: CalendariosListaComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'calendario-ficha/:id',
    component: CalendariosFichaComponent,
    canActivate: [AuthGuard],
    data: {
      title: 'Detalles calendario',
      parent: 'calendarios'
    },
  },
];


@NgModule({
  imports: [
    CommonModule,
    CommonFormsModule,
    FuseSharedModule,
    RouterModule.forChild(routes),
    CalendarModule
  ],
  exports: [
    CalendariosSelectorComponent
  ],
  declarations: [
    CalendariosListaComponent, CalendariosFichaComponent, CalendariosHorariosComponent,
    CalendariosHorariosFichaComponent, CalendariosFestivosComponent, CalendariosFestivosFichaComponent,
    CalendariosSelectorComponent, CalendarioFichaComponent
  ],
  entryComponents: [CalendariosHorariosFichaComponent, CalendariosFestivosFichaComponent, CalendarioFichaComponent],
  providers : [DatePipe]
})
export class CalendariosModule { }
