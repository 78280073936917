import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { CalendariosService } from '../calendarios.service';
import { AsConfirmComponent } from 'src/app/_common/common-components/as-confirm/as-confirm.component';

@Component({
  selector: 'app-calendarios-horarios-ficha',
  templateUrl: './calendarios-horarios-ficha.component.html',
  styleUrls: ['./calendarios-horarios-ficha.component.css']
})
export class CalendariosHorariosFichaComponent implements OnInit {

  private horarioFormGroup: FormGroup;
  private tipos;
  private dias;
  public showTipoDia;
  public idHorario: number;

  constructor(private _formBuilder: FormBuilder,
    private _calendariosService : CalendariosService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialog,
    private _matDialogRef: MatDialogRef<CalendariosHorariosFichaComponent>,
    private _matSnackBar : MatSnackBar
  ) { }

  ngOnInit() {
    this.horarioFormGroup = this._formBuilder.group({
      id: new FormControl({ value: 0, disabled: true }, Validators.required),
      calendarioId: new FormControl(''),
      fechaInicio: new FormControl({ value: '' }),
      fechaFin: new FormControl({ value: '' }),
      tipo: new FormControl({ value: '' }, Validators.required),
      numero: new FormControl(''),
      fecha: new FormControl(''),
      turno: new FormControl(''),
      horaInicio: new FormControl('', Validators.required),
      horaFin: new FormControl('', Validators.required)
    });
    if (this.data && this.data.horario && this.data.horario.id && this.data.horario.id != 0) {
      let hor = this.data.horario;
      this.idHorario = hor.id;
      this.horarioFormGroup.setValue(
        {
          id: hor.id ? hor.id : 0,
          calendarioId : hor.calendarioId,
          fechaInicio: hor.fechaInicio,
          fechaFin: hor.fechaFin,
          tipo: hor.tipo,
          numero: hor.numero,
          fecha: hor.fecha,
          turno: hor.turno,
          horaInicio: hor.horaInicio,
          horaFin: hor.horaFin
        });
      this.showTipoDia = hor.tipo == 0;
    } else if (this.data && this.data.horario && this.data.horario.calendarioId) {
      this.horarioFormGroup.patchValue({
        id: 0,
        calendarioId: this.data.horario.calendarioId
      });
    }
    this.tipos = this.data.tipos;
    this.dias = this.data.dias;
  

    this.horarioFormGroup.controls['tipo'].valueChanges.subscribe(value => {
      this.showTipoDia = value == 0;
    });
  }

  onSubmit() {
    var data = this.horarioFormGroup.getRawValue();
    this._calendariosService.updateHorario(data).subscribe((data) => {
      this._matSnackBar.open("Horario actualizado correctamente", '');
      this._matDialogRef.close(true);
    }, (error) => {
        let sbConfig = new MatSnackBarConfig();
        sbConfig.horizontalPosition = 'right';
        sbConfig.verticalPosition = 'top';
        sbConfig.duration = 3000;
        sbConfig.panelClass = ['error-snackbar'];
      this._matSnackBar.open(error.error.message, 'Error', sbConfig);
    });
  }


  displayFnTipo(data) {
    var rtn = "";
    if (this.tipos) {
      let c = this.tipos.find(x => x.id == data);
      if (c)
        rtn = c.descripcion;
    }
    return rtn;
  }

  displayFnDia(data) {
    var rtn = "";
    if (this.dias) {
      let c = this.dias.find(x => x.id == data);
      if (c)
        rtn = c.descripcion
    }
    return rtn;
  }

  public dialogRef;
  borrar() {
    this.dialogRef = this._matDialog.open(AsConfirmComponent,
      {
        data:
        {
          titulo: 'Borrar horario',
          mensaje: '¿Está seguro que desea borrar este horario?, esto afectará al calendario.'
        }
      });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this._calendariosService.borrarHorario({ id: this.idHorario }).subscribe((data2) => {
          this._matSnackBar.open('Horario borrado', '');
          this._matDialogRef.close(true);
        });

      }
    });
  }

}
