import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FichajesService {
  readonly rootUrl = `${environment.apiUrl}api/fichajes`;
  readonly rootUrlCalendario = `${environment.apiUrl}api/calendarios`;
  readonly rootUrlEmpleados = `${environment.apiUrl}api/empleados`;
  constructor(private _http: HttpClient) { }


  fichar(data: any = {lat: 0, lng: 0, device: 0, project: null, task: null}) {
    let longitud = data.lng;
    let latitud = data.lat;
    let device = data.device;
    // Si el usuario no tiene que fichar contra proyectos se le asigna el
    // proyecto por defecto, que es el proyecto con id 1.
    if (data.project == '' && data.task == '') {
      data.project = 1;
    }

    var url = `${this.rootUrl}/fichar/${longitud}/${latitud}/${device}`;
    return this._http.post(url, data);
  }

  getUltimoFichaje() {
    var url = `${this.rootUrl}/get-ultimo-fichaje`;
    return this._http.get(url);
  }

  mustLoginIntoProject() {
    var url = `${this.rootUrl}/must-login-into-project`;
      return this._http.get(url);
    }

  userMustLoginIntoProject(data: number) {
    let user = {id: data}
    var url = `${this.rootUrl}/user-must-login-into-project`;
      return this._http.post(url, user);
    }

  getFichajesDia() {
    var url = `${this.rootUrl}/get-fichajes-dia`;
    return this._http.get(url);
  }


  getHorarioDia() {
    var url = `${this.rootUrlCalendario}/get-horario-dia`;
    return this._http.get(url);
  }
  getEmpleados() {
    var url = `${this.rootUrlEmpleados}/trabajadores`;
    return this._http.get(url);
  }

  getFichajesModificables(id) {
    var url = `${this.rootUrl}/fichajes-modificables/${id}`;
    return this._http.get(url);
  }

  getExpectedTime(data: any) {
    var url = `${this.rootUrl}/tiempo-estipulado`;
    return this._http.post(url, data);
  }

  getPunchingFilter(data: any) {
    var url = `${this.rootUrl}/fichajes-filtrados`;
    return this._http.post(url, data);
  }

  setPunchingComment(data: any) {
    var url = `${this.rootUrl}/fichajes-comentarios`;
    return this._http.post(url, data);
  }

  deletePunchingComment(data: any) {
    var url = `${this.rootUrl}/borrar-comentario`;
    return this._http.post(url, data);
  }

  getFichajesPropios(formData: any) {
    var url = `${this.rootUrl}/fichajes-propios`;
    return this._http.post(url, formData);
  }

  eliminarEntrada(data) {
    var url = `${this.rootUrl}/eliminar-entrada`;
    return this._http.post(url, data);
  }

  eliminarSalida(data) {
    var url = `${this.rootUrl}/eliminar-salida`;
    return this._http.post(url, data);
  }

  actualizarFichaje(data) {
    var url = `${this.rootUrl}/actualizar-fichaje`;
    return this._http.post(url, data);
  }

  autofichajes(formData) {
    var url = `${this.rootUrl}/autofichajes`;
    return this._http.post(url, formData);
  }
  autofichajesPropios(formData) {
    var url = `${this.rootUrl}/autofichajes-propios`;
    return this._http.post(url, formData);
  }
}
