import { Injectable } from '@angular/core';
import { AccountProvider } from './user.service';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminOrManagerGuardService implements CanActivate {

  constructor(private _router : Router, private _accountProvider: AccountProvider) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let account = this._accountProvider.getAccount();
    let allowed = account && (account.isResponsable || account.isAdminWeb);
    if (allowed)
      return true;
    this._router.navigateByUrl('')
    return allowed;
  }
}
