import { Component, OnInit, Inject, Input, EventEmitter, Output } from '@angular/core';
import { MatDialog } from '@angular/material';
import { CalendariosFestivosFichaComponent } from '../calendarios-festivos-ficha/calendarios-festivos-ficha.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-calendarios-festivos',
  templateUrl: './calendarios-festivos.component.html',
  styleUrls: ['./calendarios-festivos.component.css']
})
export class CalendariosFestivosComponent implements OnInit {

  @Input() diasEspeciales: any;
  @Input() tipos: any;
  @Input() dias: any;
  @Input() calendarioId: number;
  @Input() periodicidades: any;
  @Output() horarioChange = new EventEmitter();
  columnas = ['Periodicidad', "Dia"];
  public dialogRef;

  constructor(private _matDialog: MatDialog,
    private _datePipe: DatePipe
  ) { }

  ngOnInit() { }

  nuevo() {
    this.openDialog(null);
  }

  editar(row) {
    this.openDialog(row);
  }

  openDialog(row) {
    var festivo = row ? row : { calendarioId: this.calendarioId };
    this.dialogRef = this._matDialog.open(CalendariosFestivosFichaComponent, {
      data: {
        dias: this.dias,
        periodicidades: this.periodicidades,
        festivo: festivo
      }
    });
    this.dialogRef.afterClosed().subscribe((data) => {
      if (data === true)
        this.horarioChange.emit();
    });
  }

  getFechaDia(item) {
    var rtn = '';
    switch (item.periodicidad) {
      case 1:
        rtn = item.numeroDescripcion;
        break;
      case 2:
        rtn = this._datePipe.transform(item.fecha, 'dd/MM');
        break;
      default:
        rtn = this._datePipe.transform(item.fecha, 'dd/MM/yyyy');
    }
    return rtn;

  }
}
