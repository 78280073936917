import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Response } from "@angular/http";
import { User } from '../model/user.model';
import { environment } from '../../../environments/environment';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { CookieManagerService } from 'src/app/_conf/cookie-manager.service';
import { TenantProvider } from 'src/app/_conf/tenant.service';

@Injectable(
  { providedIn: 'root' },
)
export class UserService {
  readonly rootUrl = environment.apiUrl;
  private _user: User;

  constructor(private http: HttpClient,
    private _cookieManagerService: CookieManagerService
  ) { }

  userAuthentication(userName, password, tenant) {
    var data = `username=${userName}&password=${password}&grant_type=password`;
    var reqHdr = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    if (tenant)
      reqHdr = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'Tenant': tenant });
    return this.http.post(`${this.rootUrl}token`, data, { headers: reqHdr });
  }

  userPinAuthentication(pin, pinKey, pinId, tenant) {
    var data = `username=${pin}&id=${pinId}&grant_type=password`;
    var reqHdr = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    if (tenant) {
      reqHdr = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'PinKey': pinKey, 'Tenant': tenant, 'PinId': pinId });
    }
    return this.http.post(`${this.rootUrl}token`, data, { headers: reqHdr });
  }

  userDocAuthentication(pin, pinKey, tenant) {
    var data = `username=${pin}&grant_type=password`;
    var reqHdr = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    if (tenant) {
      reqHdr = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded', 'PinKey': pinKey, 'UseDoc': 'true', 'Tenant': tenant });
    }
    return this.http.post(`${this.rootUrl}token`, data, { headers: reqHdr });
  }

  account() {
    let data = this.http.get(`${this.rootUrl}api/account`);
    return data
  }

  public set user(value: User) {
    this._user = value;
  }
  public get user(): User {
    return this._user;
  }

  logout() {
    this._cookieManagerService.deleteCookie("userToken");
  }  
}



@Injectable({
  providedIn: 'root'
})
export class AccountProvider {
  readonly rootUrl = `${environment.apiUrl}api/Account`;
  private account: User;
  public userInfo = new BehaviorSubject<User>(new User());
  constructor(
    private _userService: UserService,
    private _cookieManagerService: CookieManagerService,
    private _http: HttpClient,
    private _tenantProvider: TenantProvider
  ) { }

  public getAccount(): User {
    return this.account;
  }

  public setAccount(value: any) {
    this.mapeaAccount(value);
  }

  load() {
    return this.getAccountAsync();
  }

  public mapeaAccount(value) {
    this.account = new User();
    if (value.isAuthenticated) {
      this.account.empleadoId = value.empleadoId;
      this.account.isAuthenticated = value.isAuthenticated;
      this.account.roles = value.roles;
      this.account.schema = value.schema;
      this.account.subdominio = value.subdominio;
      this.account.userName = value.userName;
      this.account.userId = value.userId;
      this.account.id = value.id;
    }
    this.userInfo.next(this.account);
  }

  public async getAccountAsync() {
    if (this._cookieManagerService.getCookie('userToken') != null) {
      try {
        let tenantAsync : any = await this._http.get('api/Tenant/GetTenant').toPromise();
        this._tenantProvider.setTenant(tenantAsync.codigo as string);

        let asyncResult: any = await this._userService.account().toPromise();
        //let asyncResult: any = await this._http.get(url).toPromise();
        //this.account = asyncResult as User;
        this.mapeaAccount(asyncResult);
      } catch (err) {
        this.mapeaAccount({ isAuthenticated: false });
      }
    }
  }

  public getAccountSync() {
    return this._userService.account();
  }

  public getEmail(email: any) {
    /*
    return this._http.post(`${this.rootUrl}/get-mail`, email)
    */
    let request = new Request('get-mail', email)
    return this.postRequest(request)
  }

  public forgotPassword(email: any) {
    /*
    return this._http.post(`${this.rootUrl}/forgot-password`, email)
    */
    let request = new Request('forgot-password', email)
    return this.postRequest(request)
  }

  public resetPassword(email: any) {
    /*
    return this._http.post(`${this.rootUrl}/reset-password`, email)
    */
    let request = new Request('reset-password', email)
    return this.postRequest(request)
  }

  public forgotPinKey(email: any) {
    /*
    return this._http.post(`${this.rootUrl}/forgot-pinkey`, email)
    */
    let request = new Request('forgot-pinkey', email)
    return this.postRequest(request)
  }

  public resetPinKey(email: any) {
    let request = new Request('reset-pinkey', email)
    return this.postRequest(request)
    /*
    return this._http.post(`${this.rootUrl}/reset-pinkey`, email)
    */
  }

  private postRequest(request: Request) {
    return this._http.post(request.url, request.data)
  }
}


export function accountProviderFactory(provider: AccountProvider) {
  return () => provider.load();
}

class Request {
  readonly rootUrl = `${environment.apiUrl}api/Account`;
  url: string
  data: any

  constructor(url: string, data: any) {
    this.url = `${this.rootUrl}/${url}`
    this.data = data
  }
}
