import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  //{
  //  id: 'applications',
  //  title: 'Applications',
  //  translate: 'NAV.APPLICATIONS',
  //  type: 'group',
  //  children: [
  //    {
  //      id: 'sample',
  //      title: 'Sample',
  //      translate: 'NAV.SAMPLE.TITLE',
  //      type: 'item',
  //      icon: 'email',
  //      url: '/sample',
  //      badge: {
  //        title: '25',
  //        translate: 'NAV.SAMPLE.BADGE',
  //        bg: '#F44336',
  //        fg: '#FFFFFF'
  //      }
  //    }
  //  ]
  //},
  {
    id: 'empresa-ficha',
    title: 'Información empresa',
    type: 'collapse',
    icon: 'business',
    children: [
      {
        id: 'empresa-ficha',
        title: 'Empresa',
        type: 'item',
        icon: 'business',
        url: '/empresa-ficha'
      },
      {
        id: 'centros-trabajo',
        title: 'Centro de trabajo',
        type: 'item',
        icon: 'layers',
        url: '/centros-trabajo'


      },
      {
        id: 'departamentos',
        title: 'Departamentos',
        icon: 'group',
        type: 'item',
        url: 'departamentos'
      }
    ]
  },
  //{
  //  id: 'centro-trabajo-lista',
  //  title: 'Centro de trabajo',
  //  type: 'item',
  //  icon: 'layers',
  // url: '/centro-trabajo-lista'
     
    
  //},
  {
    id: 'empleados',
    title: 'Empleados',
    type: 'collapse',
    icon: 'contacts',
    children: [    
      {
        id: 'empleados-lista',
        title: 'Empleados',
        type: 'item',
        icon: 'contacts',
        url: '/empleados'
      },
      {
        id: 'nuevo-empleado',
        title: 'Nuevo empleado',
        icon: 'add_circle_outline',
        type: 'item',
        url: '/nuevo-empleado',
        function: () => {
          //this._router.navigateByUrl('/nuevo-empleado');
        }
      },
      {
        id: 'empleado-solicitudes',
        title: 'Gestión Vacaciones',
        icon: 'flight',
        type: 'item',
        url: 'empleado-solicitudes'
      }
    ]
  },
  {
    id: 'calendarios',
    title: 'Calendarios',
    type: 'collapse',
    icon: 'schedulers',
    children: [
      {
        id: 'calendarios-lista',
        title: 'Calendarios',
        icon: 'calendar_today',
        type: 'item',
        url: '/calendarios'
        //function: () => {
        //  this._router.navigateByUrl('/calendarios');
        //}
      }
    ]
  },
  {
    id: 'configuracion',
    title: 'Configuración',
    type: 'item',
    icon: 'settings',
    url: '/configuracion',
    
  },
  {
    id: 'fichajes',
    title: 'Fichajes',
    type: 'collapse',
    icon: 'repeat',
    children: [
      {
        id: 'modificar-fichajes',
        title: 'Modificar fichajes',
        icon: 'restore',
        type: 'item',
        url:'/modificar-fichajes'
      },
      {
        id: 'autofichaje',
        title: 'Generar autofichajes',
        icon: 'punch_clock',
        type: 'item',
        url: '/generar-autofichajes',
        
      }
    ]    
  },
  {
    id: 'informes',
    title: 'Informes',
    type: 'collapse',
    icon: 'insert_chart ',
    children: [
      {
        id: 'empleados-horas-filtros',
        title: 'Horas por empleado',
        icon: 'insert_chart',
        type: 'item',
        url: '/empleados-horas-filtros'
        
      }, {
        id: 'fichajes-propios',
        title: 'Fichajes propios',
        icon: 'autorenew',
        type: 'item',
        url: '/fichajes-propios'
      }, {
        id: 'empleados-vacaciones',
        title: 'Vacaciones empleados',
        icon: 'insert_chart ',
        type: 'item',
        url: '/empleados-vacaciones'

      }]
  },
  {
    id: 'proyectos',
    title: 'Proyectos',
    type: 'collapse',
    icon: 'work',
    children: [
      /*
      {
        id: 'tareas',
        title: 'Tareas',
        icon: 'task',
        type: 'item',
        url: '/lista-tareas'
      },
      */
      {
        id: 'proyectos',
        title: 'Proyectos',
        icon: 'work',
        type: 'item',
        url: '/lista-proyectos'
      },
      {
        id: 'clientes',
        title: 'Clientes',
        icon: 'groups',
        type: 'item',
        url: '/lista-clientes'
      }
    ]
  }
];

