import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../services/customer.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-clientes',
    templateUrl: './clientes-lista.component.html',
    styleUrls: ['./clientes-lista.component.scss']
})
export class ClientesListaComponent implements OnInit  {
  public isMobile: boolean;
  public customers: any;
  public columns: string[] = ["NOMBRE", "CIF", "LOCALIDAD", "CONTACTO", "TELEFONO", "EMAIL"]

  constructor(
    private _customerService: CustomerService,
    private _router: Router
  ) { }

  ngOnInit() {
    this._customerService.getAvailableCustomerList().subscribe((data) => {
      this.customers = data;
    })
  }

  goToCustomerCard(customer: any) {
    this._router.navigateByUrl(`/ficha-cliente/${customer.id}`);
  }

}

