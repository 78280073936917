import { Component, OnInit, AfterViewInit, ViewChild, Renderer2, ChangeDetectorRef, AfterViewChecked, AfterContentChecked } from '@angular/core';
import { ReportingService } from '../reporting.service';
import { MatCalendar, MatDatepickerInputEvent } from '@angular/material';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DateProvider } from '../../_common/common-classes/date-provider';
import { Calendar } from '../../_common/common-classes/calendar';
import { CalendariosService } from '../../calendarios/calendarios.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { VacacionesEmpleado, PeriodoVacaciones } from '../../_common/common-classes/common-holidays';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-report-empleados-vacaciones',
  templateUrl: './report-empleados-vacaciones.component.html',
  styleUrls: ['./report-empleados-vacaciones.component.css']
})
export class ReportEmpleadosVacacionesComponent implements OnInit, AfterViewInit, AfterViewChecked, AfterContentChecked {
  @ViewChild('firstCalendar') firstCalendar: MatCalendar<Moment>;
  @ViewChild('secondCalendar') secondCalendar: MatCalendar<Moment>;
  @ViewChild('thirdCalendar') thirdCalendar: MatCalendar<Moment>;
  public filtroFormGroup: FormGroup;
  selectedDate: Moment;
  public firstDate: Moment;
  public thirdDate: Moment;
  public secondDate: Moment;

  public vacaciones;
  public empleados;
  public dias;
  public meses;
  public dateProvider = new DateProvider();
  private selectedMonth;
  private selectedYear;
  public years: number[] = []
  public allDays: Calendar;
  public employeeHolidaysCollector: VacacionesEmpleado[] = []
  public columnasTrabajador = ['Empleado', 'Días consumidos', 'Días solicitados', 'Días pendientes']
  public columnasDesgloseHoras = ['Fecha', 'Hora inicio', 'Hora fin', 'Horas']
  public columnasDesgloseDias = ['Periodo', 'Fechas', 'Días']
  public calendarios: Calendario[] = []
  public holidayYears = []

  constructor(
    private _reportingService: ReportingService,
    private _calendarService: CalendariosService,
    private _renderer: Renderer2,
    private _formBuilder: FormBuilder,  
    private changeDetectorRefs: ChangeDetectorRef,
    private _datePipe: DatePipe
  ) {
    this.dateProvider.setMinMaxDates();
    this.selectedYear = this.dateProvider.year;
    this.selectedMonth = this.dateProvider.month;
    const today = new Date();
    [-2, -1, 0, 1].forEach(year => { this.holidayYears.push(today.getFullYear() + year) })
  }

  ngOnInit() {
    this.filtroFormGroup = this._formBuilder.group({
      idCalendario: new FormControl({ value: 1, disabled: false }, Validators.required),
      anyo: new FormControl({ value: new Date().getFullYear(), disabled: false }, Validators.required)
    })

    this.filtroFormGroup.valueChanges.subscribe((formData: any) => {
      if (this.holidayYears.includes(formData.anyo)) {
        this.setVacacionesEmpleados()
      }
    });

    this._calendarService.getCalendarios().subscribe((data: any) => {
      const calendarios = data as Calendario[]
      calendarios.forEach(c => {
        if (c.activo)
          this.calendarios.push(c as Calendario)
      })
    })
    this.getVacaciones()
  }

  ngAfterViewInit() {
    /* Escuchadores botones previo y siguiente */
    const monthPrevBtn = document.querySelectorAll('.mat-calendar-previous-button')
    const monthNextBtn = document.querySelectorAll('.mat-calendar-next-button')

    if (monthPrevBtn) {
      Array.from(monthPrevBtn).forEach((button) => {
        this._renderer.listen(button, 'click', (event) => {
          this.setCurrentMonthAndYear(null)
          this.updateCalendars(null)
        })
      })
    }

    if (monthNextBtn) {
      Array.from(monthNextBtn).forEach((button) => {
        this._renderer.listen(button, 'click', (event) => {
          this.setCurrentMonthAndYear(null);
          this.updateCalendars(null);
        })
      })
    }

    /* Inhabilitar segundo y tercer botón de periodos */
    const secondPeriodBtn = document.querySelector(".inline-calendar-card-second .mat-calendar-period-button")
    const thirdPeriodBtn = document.querySelector(".inline-calendar-card-third .mat-calendar-period-button")
    secondPeriodBtn.setAttribute('disabled', '')
    thirdPeriodBtn.setAttribute('disabled', '')
  }

  ngAfterViewChecked() {
    this.changeDetectorRefs.detectChanges()
  }

  ngAfterContentChecked() {
    this.changeDetectorRefs.detectChanges()
  }

  private changeCurrentMonth(month: number) {
    if (month === 2) {
      this.selectedMonth = `${this.secondDate.get('month') + 1}`.padStart(2, '0')
    } else {
      this.selectedMonth = `${this.thirdDate.get('month') + 1}`.padStart(2, '0')
    }
  }

  handleMonthSelected(evt) {
    this.setCurrentMonthAndYear(evt._d)
    this.updateCalendars(evt._d)
  }

  updateCalendars(date = null) {
      this.updateCalendar(date, 1)
      this.changeCurrentMonth(2)
      this.secondCalendar._goToDateInView(this.secondDate, 'month')
      this.updateCalendar(date, 2)
      this.changeCurrentMonth(3)
      this.thirdCalendar._goToDateInView(this.thirdDate, 'month')
      this.updateCalendar(date, 3)
  }

  private updateDates(date: Date) {
    let firstDate = date;
    let secondDate = new Date(firstDate)
    let thirdDate = new Date(firstDate)
    secondDate.setMonth(firstDate.getMonth() + 1);
    thirdDate.setMonth(firstDate.getMonth() + 2);

    this.firstDate = moment(firstDate)
    this.secondDate = moment(secondDate)
    this.thirdDate = moment(thirdDate)
  }

  private setCurrentMonthAndYear(paramDate) {
    let date = paramDate;
    if (date) {
      // Si viene desde handleMonthSelected, tenemos fecha.
      this.selectedMonth = `${date.getMonth() + 1}`.padStart(2, '0')
      this.selectedYear = date.getFullYear()

    } else {

      // Necesitamos actualizar mes y año para iterarlos.
      const matCalendarCard = document.querySelector('.mat-calendar .mat-calendar-controls .mat-calendar-period-button .mat-button-wrapper');
      if (matCalendarCard && matCalendarCard.textContent) {
        this.dateProvider.fromMonthAndYear(matCalendarCard.textContent)
        this.selectedYear = this.dateProvider.year
        this.selectedMonth = this.dateProvider.month
        date = this.dateProvider.parsedDate
      }
    }
    this.getHolidaysFromSelectedYear()
    this.updateDates(date)
  }

  private updateCalendar(date = null, calendar: number=1) {
    if (!this.allDays)
      return

    var position = '';
    switch (calendar) {
      case 1:
        position = 'first'
        break
      case 2:
        position = 'second';
        break;
      case 3:
        position = 'third';
        break;
    }

    /* Iteramos los días del calendario para mostrar días/horas libres y festivos. */
    setTimeout(() => {
      const cells = Array.from(document.querySelectorAll<HTMLDivElement>(`.inline-calendar-card-${position} .mat-calendar-body-cell-content`))
      cells.forEach(c => {
        let currentDay = c.innerText.padStart(2, '0')
        let currentDate = position === 'first' ? this.firstDate : position === 'second' ? this.secondDate : this.thirdDate
        let currentMonth = `${currentDate.get('month') + 1}`.padStart(2, '0')
        let currentYear = currentDate.get('year')


        if (currentDay.length > 2)
          return;

        let data = this.allDays.calDates.find(f => f.date == `${currentYear}-${currentMonth}-${currentDay}`)
        let counter = data == null ? 0 : data.workers.length;
        let workers = data == null ? [] : data.workers.map(w => w);
        let workerList = data == null ? '' : workers.concat(workers.splice(-2, 2).join(' y ')).join(', ');

        if (workerList && workerList.includes('y I'))
          workerList = workerList.replace('y I', 'e I')

        c.innerText = currentDay;

        if (counter > 0)
          c.className += ' special-date';

        if (data && data.isHoliday)
          c.className += ' holiday-date';

        if (workerList)
          c.innerHTML = `<div class="row"><div class="col-12" style="font-size:1.5em;">${currentDay}</div><div class="col-12" title="${workerList}">${counter}</div></div>`;
        else
          c.innerHTML = `<div class="row"><div class="col-12" style="font-size:1.5em;">${currentDay}</div><div class="col-12">${counter}</div></div>`;
      });
    });
  }

  dateFilter = (moment: any): boolean => {
    const day = moment._d.getDay();
    // Prevent Saturday and Sunday from being selected.
    return day !== 0 && day !== 6;
  }

  private toUTC(date: Date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
  }

  private toUTCString(date: Date) {
    date = this.toUTC(date);
    return date.toDateString()
  }

  private getVacaciones() {
    let today = new Date();
    this.getHolidays(today.getUTCFullYear())
  }

  private getHolidays(year: number, init: boolean=true) {
    if (year && !this.years.includes(year)) {
      this.years.push(year);
      let outData = {
        fechaDesde: new Date(Date.UTC(year, 0, 1)),
        fechaHasta: new Date(Date.UTC(year, 11, 31, 23, 59, 59, 999))
      }
      this._reportingService.empleadosVacaciones(outData).subscribe((inData: any) => {
        if (init) {
          this.allDays = new Calendar(inData);
          this.setCurrentMonthAndYear(null);
          this.updateCalendars();
        } else {
          this.allDays.addDates(inData);
        }
        this.setVacacionesEmpleados()
      });
    }
  }

  private setVacacionesEmpleados() {
      const formData = this.filtroFormGroup.value
      const fechas = {
        inicio: new Date(formData.anyo, 0, 31),
        fin: new Date(formData.anyo, 11, 31, 23, 59, 59, 999)
      }
      const newData = { idCalendario: formData.idCalendario, fechaInicio: fechas.inicio, fechaFin: fechas.fin }

      this._reportingService.vacacionesEmpleados(newData).subscribe((holidayData: any) => {
        this.setEmployeeHolidays(holidayData)
      })
  }

  private getHolidaysFromSelectedYear() {
    let year = this.selectedYear
    this.getHolidays(year, false)
  }

  private setEmployeeHolidays(inData: any) {
    const employees = []
    inData.forEach(data => {
      let employee = employees.find(e => e.idEmpleado === data.idEmpleado)
      if (employee) {
        employee.append(data)
      } else {
        employee = new VacacionesEmpleado(data)
        employee.append(data)
        employees.push(employee)
      }
    })
    for (const employee of employees)
      employee.calcDays()
    this.employeeHolidaysCollector = employees
    this.changeDetectorRefs.detectChanges()
  }

  getPeriod(period: PeriodoVacaciones) {
    const head = period.periodo.includes('/') ? 'del ' : ''
    return `${head}${period.periodo.replace('/', ' al ')}`
  }

  concatDates(period: PeriodoVacaciones) {
    let dateChain = ''
    period.fechas.forEach(d => dateChain += `${this._datePipe.transform(d, 'dd/MM')}, `)
    dateChain = dateChain.replace(/, $/, '').replace(/, (\d+\/\d+$)/, ' y $1')
    return dateChain
  }

  private prepareEmployees = () => {
      /*
       * Método para nombres de hojas teniendo en cuenta que no pueden ser superiores a 30 carácteres.
       * Recogemos nombre y apellidos, nos quedamos con el nombre e iniciales de les apellidos,
       * si hay alguna conincidencia (del tipo 2 Carmen RP) añadimos un número: Carmen RP (1), Carmen RP (2), ...
       */
    const shortNames = {}
    let counter = {}
    this.employeeHolidaysCollector.forEach(e => {
      let [name, surname1, surname2] = e.empleado.split(' ', 3)
      let shortName = `${name[0].toUpperCase()}${name.substring(1,15)} ${surname1 ? surname1[0].toUpperCase() : ''}${surname2 ? surname2[0].toUpperCase() : ''}`.trim()

      if (counter[shortName]) {
        const count = counter[shortName].length + 1
        name = `${shortName} (${count})`
        counter[shortName].push({ id: e.idEmpleado, name: name })
      } else {
        name = `${shortName} (1)`
        counter[shortName] = [{ id: e.idEmpleado, name: name }]
      }
    })

    for (let [shortName, employees] of Object.entries<any>(counter)) {
      if (employees.length === 1) {
        const employee = employees[0]
        shortNames[employee.id] = shortName
      } else {
        employees.forEach((employee) => {
          shortNames[employee.id] = employee.name
        })
      }
    }
    return shortNames
  }

  public exportToExcel() {
    const year = this.filtroFormGroup.value.anyo
    const pages: any = { Global: [this.columnasTrabajador] }
    const employees = this.prepareEmployees()
    this.employeeHolidaysCollector.forEach((employee: VacacionesEmpleado) => {

      // Resumen usuarios (1ª pág.)
      const usedDays = employee.days + employee.accDays
      const requestedDays = employee.pendingDays + employee.pendingAccDays
      const remainingDays = employee.holidays 
      const dfmt = '#,##0.00'
      const ifmt = '#,##0'
      const row = [employee.empleado, { t: 'n', v: usedDays, z: dfmt }, { t: 'n', v: requestedDays, z: dfmt }, { t: 'n', v: remainingDays, z: dfmt}]
      pages['Global'].push(row)

      // Desglose empleados (pág. siguientes)
      const sheetname = employees[employee.idEmpleado]
      pages[sheetname] = [[employee.empleado]]
      pages[sheetname].push([]) // salto de línea
      pages[sheetname].push(['Desglose días']) // título sección
      pages[sheetname].push(this.columnasDesgloseDias)
      employee.periodos.forEach((period: PeriodoVacaciones) => {
        const head = this.getPeriod(period)
        const chain = this.concatDates(period)
        const days = period.fechas.length 
        pages[sheetname].push([head, chain, { t: 'n', v: days, z: ifmt }])
      })

      // Desglose Horas
      pages[sheetname].push([]) // salto de línea
      pages[sheetname].push(['Desglose horas libres']) // título sección
      pages[sheetname].push(this.columnasDesgloseHoras)
      employee.desgloseHoras.forEach((request: any) => {
        const date = this._datePipe.transform(request.fecha, 'dd-MM-yyyy')
        const startTime = request.horaInicio.substring(0,5)
        const endTime = request.horaFin.substring(0, 5)
        const hours = request.horas
        const row = [date, startTime, endTime, { t: 'n', v: hours, z: dfmt }]
        pages[sheetname].push(row)
      })
    })
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    for (const [sheetname, rows] of Object.entries(pages)) {
      let items: any = rows
      const worksheet = XLSX.utils.json_to_sheet(items, { skipHeader: true });
      if (sheetname === 'Global')
        worksheet['!cols'] = [{ wch: 22.25 }, { wch: 14 }, { wch: 14 }, { wch: 14 }];
      else {
        worksheet['!cols'] = [{ wch: 24.5 }, { wch: 35 }, { wch: 14 }, { wch: 14 }]
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, sheetname)
    }
    XLSX.writeFile(workbook, `Informe Vacaciones Año ${year}.xlsx`);
  }
}

class Calendario {
  id: number
  nombre: string
  descripcion: string
  activo: boolean
}
