import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TaskService } from '../services/task.service';
import { ProjectService } from '../services/project.service';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatDialog, MatDialogRef } from '@angular/material';
import { AsSpinnerService } from '../../_common/common-components/as-spinner/as-spinner.service';
import { AccountProvider } from '../../users/services/user.service';
import { User } from '../../users/model/user.model';
import { concat } from 'rxjs';
import { AsConfirmComponent } from '../../_common/common-components/as-confirm/as-confirm.component';

@Component({
  selector: 'app-tareas-ficha',
  templateUrl: './tareas-ficha.component.html',
  styleUrls: ['./tareas-ficha.component.scss']
})
export class TaskCardComponent implements OnInit {
  /*
   * En un principio al modificar una tarea volvíamos a /lista-tareas
   * Al suprimir este elemento del menú lateral, al modificar una
   * tarea por medio del botón 'Volver' debemos volver a uno de las dos
   * rutas:
   * - /ficha-proyecto/:id [por lo general venimos de ahí]
   * - /lista-tareas [la ruta existe pero de momento solo se puede llegar por URL]
   * Esto lo conseguimos mediante una variable de sesión.
   */
  public errorMessage = '';
  public taskFormGroup: FormGroup;
  public taskId: number;
  public task: any;
  public errorMsg: string;
  public user: User;

  public projects: any;
  public taskStatuses: any;

  private snackbarConfig: MatSnackBarConfig;
  private confirmDialogRef: MatDialogRef<AsConfirmComponent>;
  private deleteTaskBool: boolean;
  public isDisabled: boolean;
  private endpoint: string;
  private token: string = 'landingPageTkn';

  // binding
  public _valorPropiedad;
  @Input() valorPropiedad: number;
  @Output() valorPropiedadChange = new EventEmitter();
  propagateChange = (_: any) => { };
  onTouch = () => { }

  constructor(
    private matDialog: MatDialog,
    private _formBuilder: FormBuilder,  
    private _activatedRoute: ActivatedRoute,
    private _taskService: TaskService,
    private _projectService: ProjectService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _asSpinner: AsSpinnerService,
    private _accountProvider: AccountProvider,
  ) {
    this.setSnackbarConfig();
    this.isDisabled = false;
    this.deleteTaskBool = false;
  }

  ngOnInit() {
    this.initializeForm()
    this.getFormData()
    this.user = this._accountProvider.getAccount();
    this.setEndpoint()
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.panelClass = ['error-snackbar'];
  }

  private setEndpoint() {
    this.endpoint = this.getEndpoint();
  }

  private getEndpoint(): string {
    var endpoint = '/';
    if (this.user.isAdminWeb) {
      let token = sessionStorage.getItem(this.token)
      if (token) {
        // Origen dins d'una variable de sessió
        token = sessionStorage.getItem(this.token);
        sessionStorage.removeItem(this.token);
        endpoint = atob(token);
      } else {
        /*
           Possibles origens: /ficha-proyecto/:id i /lista-tareas
           Com no tenim projecte agarrem el valor del projecte
           del formulari i en absència d'este tornaríem a
           /lista-proyectos. /lista-tareas no deuria ser un destí
           ja que l'hem eliminat de moment.
        */
        endpoint = '/lista-proyectos'
        let projectId = parseInt(this.taskFormGroup.controls.idProyecto.value)
        if (projectId)
          endpoint = `/ficha-proyecto/${projectId}`
      }
    }
    return endpoint;
  }

  private setProjectId() {
    // Obtenemos el id del proyecto de la URL y lo colocamos en el campo del proyecto.
    let pattern = /ficha-proyecto\/(\d+)$/g
    let match: any = pattern.exec(this.endpoint)
    if (match) {
      let projectId = match[1];
      this.taskFormGroup.patchValue({ idProyecto: parseInt(projectId), disabled: true })
    }
    this.checkDisableProject()
  }

  private checkDisableProject() {
    let idProyecto = this.taskFormGroup.controls.idProyecto;
    this.isDisabled = !!idProyecto.value;
    if (this.isDisabled)
      idProyecto.disable();
    else
      idProyecto.enable();
  }

  private initializeForm(): void {
    this.taskFormGroup = this._formBuilder.group(
      {
        id: new FormControl({value: 0, disabled: false}),
        codigo: new FormControl({value: 0, disabled: false}),
        descripcion: new FormControl({ value: null, disabled: false }, Validators.required),
        idProyecto: new FormControl({ value: null, disabled: false }),
        estado: new FormControl({ value: null, disabled: false }, Validators.required)
      });
  }

  private getFormData(): void {
    concat(
      this._projectService.getAvailableProjectList(),
      this._taskService.getTaskStatusList(),
      this._activatedRoute.params
    ).subscribe((data: any) => {
      var isParam = true;

      for (let item of data) {
        if ('idCliente' in item) {
          this.projects = data
          isParam = false
          this.setProjectId();
          break;
        } else if ('clave' in item) {
          this.taskStatuses = data
          isParam = false
          break;
        }
      }

      if (isParam && 'id' in data) {
        this.taskId = data.id
        this.fillFormData(data.id)
      } 
    })
  }

  public writeValue(obj: any): void {
    if (obj !== undefined) {
      this.valorPropiedad = obj;
      this._valorPropiedad = obj;
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public seleccionaValor(): void {
    this.valorPropiedadChange.emit(this.valorPropiedad); //Para bindar con ngModel y Output
    this.propagateChange(this.valorPropiedad);
    this.onTouch();
  }

  public displayFnProject(inProj: number): string {
    var rtn = '';
    if (this.projects) {
      let project = this.projects.find(p => p.id == inProj);
      if (project)
        rtn = project.nombre;
    }
    return rtn;
  }

  public displayFnTaskStatus(inStatus: number): string {
    var rtn = '';
    if (this.taskStatuses) {
      let status = this.taskStatuses.find(ts => ts.id == inStatus);
      if (status)
        rtn = status.valor;
    }
    return rtn;
  }

  private fillFormData(id: number): void {
    this._taskService.getTaskCard(id).subscribe((task: any) => {
      task = task[0];
      this.task = task;
      this.taskFormGroup.setValue({
        id: task.id,
        codigo: task.codigo,
        descripcion: task.descripcion,
        idProyecto: task.idProyecto,
        estado: task.estado
      });
    })
  }

  private validateData() {
    if (this.deleteTaskBool) {
      this.deleteTaskBool = false;
      return false;
    }

    let ctrl = this.taskFormGroup.controls
    if (typeof ctrl.estado.value != 'number') {
      this._matSnackBar.open('Debe selecionar un estado del desplegable', 'Error', this.snackbarConfig);
      return false;
    }

    if (typeof ctrl.idProyecto.value != 'number') {
      this._matSnackBar.open('Debe selecionar un proyecto del desplegable', 'Error', this.snackbarConfig);
      return false;
    }

    // Es una creación de una nueva tarea.
    if (!this.task)
      return true;

    let changes = [
      this.task.idProyecto == ctrl.idProyecto.value,
      this.task.descripcion == ctrl.descripcion.value,
      this.task.estado == ctrl.estado.value
    ]

    // El formulario no se ha modificado
    if (changes.every(Boolean)) {
      this._matSnackBar.open('No ha habido cambios', '');
      return false;
    }

    return true;
  }

  onSubmit() {
    if (!this.validateData())
      return;
    this._asSpinner.enable();
    var outData = this.taskFormGroup.getRawValue();

    this._taskService.addTask(outData).subscribe((resp: any) => {

      this._asSpinner.disable();
      if (resp.ok) {
        this._matSnackBar.open('Tarea actualizada', '');
        if (this.user.isAdminWeb)
          this._router.navigateByUrl(this.endpoint);
      } else {
        this._matSnackBar.open(resp.mensaje, '');
      }
    }, (error) => {
        this._asSpinner.disable();
        this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
    });
  }

  public confirmDeleteTask(): void {
    this.deleteTaskBool = true;
    let action = {title: 'Borrar Tarea', question: 'Confirme que desea borrar la tarea.'}
    let config = { data: { titulo: action.title, mensaje:  action.question, } }
    this.confirmDialogRef = this.matDialog.open(AsConfirmComponent, config);
    this.confirmDialogRef.afterClosed().subscribe((resp) => {
      if (resp)
        this.deleteTask();
    });
  }

  private deleteTask(): void {
    this._asSpinner.enable();
    var formData = this.taskFormGroup.getRawValue();    
    this._taskService.deleteTask(formData).subscribe((data) => {
      this._asSpinner.disable();
      this._matSnackBar.open('Tarea eliminada', '');
      this.back();
    }, (error) => {
        this._asSpinner.disable();
        this._matSnackBar.open(error.error.message, 'Error', this.snackbarConfig);
    });
  }

  public cancel(): void {
    // Boton cancela solo disponible cuando la tarea es nueva
    this.back();
  }

  private back(): void {
    this._router.navigateByUrl(this.getEndpoint());
  }

  public toggleDisabled(): void {
    // (click)="showTooltip('edit')" [title]="setTooltip('edit')" para boton edit del idProyecto
    this.isDisabled = !this.isDisabled;
    let idProyecto = this.taskFormGroup.controls.idProyecto;
    if (this.isDisabled)
      idProyecto.disable();
    else
      idProyecto.enable();
  }

  public setTooltipOld() {
    return this.taskId ? "No está permitido modificar el proyecto, cree una nueva tarea." : "Tenga en cuenta que no podrá modificar el proyecto asignado."
  }

  public showTooltip(action) {
    this._matSnackBar.open(this.setTooltip(action), '')
  }

  public hasProject() {
    return !!this.taskFormGroup.controls.idProyecto.value
  }

  public setTooltip(action) {
    if (action === 'delete')
      return this.hasProject() ? "No se permite borrar una tarea si hay un proyecto asignado." : "";
    else if (action === 'save')
      return !this.hasProject() ? "No se permite guardar la tarea sin asignarle un proyecto." : "";
    return "";
  }
}
