import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef, AfterContentChecked, Renderer2, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { TrabajadoresService } from '../trabajadores.service';
import { Params, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig, MatCalendar, MatDatepickerInputEvent } from '@angular/material';
import { AccountProvider } from '../../users/services/user.service';
import { ReportingService } from '../../reporting/reporting.service';
import { Moment } from 'moment';
import { DateProvider } from '../../_common/common-classes/date-provider';
import { Calendar } from '../../_common/common-classes/calendar';
import { CalendarioVacacionesComponent } from '../calendario-vacaciones/calendario-vacaciones.component';
import * as moment from 'moment';
import { AsSpinnerService } from '../../_common/common-components/as-spinner/as-spinner.service';
import { PeriodoVacaciones, VacacionesEmpleado } from '../../_common/common-classes/common-holidays';
import { DatePipe } from '@angular/common';


/*
 * Aquí solo se trata de crear una solicitud de periodo vacacional/asuntos propios
 * Para el propio usuario.
 */
@Component({
  selector: 'app-empleado-solicitud-ficha',
  templateUrl: './empleado-solicitud-ficha.component.html',
  styleUrls: ['./empleado-solicitud-ficha.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmpleadoSolicitudFichaComponent implements OnInit {
  @ViewChild(CalendarioVacacionesComponent) CalendarComp: CalendarioVacacionesComponent
  public solicitudFormGroup: FormGroup;
  public motivosSolicitud: Array<any>;
  private idSolicitud: number;
  public showDiasCompletos: boolean;
  public buttonCaption: string;
  private createRequest: boolean;
  private required = [Validators.required]
  private partialDayRequests: any;
  private snackbarConfig: MatSnackBarConfig;
  private selectedMonth: string;
  private selectedYear: number;
  public dateProvider = new DateProvider();
  public allDays: Calendar;
  public employeeHolidays: VacacionesEmpleado
  public workerColumns = ['Días consumidos', 'Días solicitados', 'Días pendientes']
  public daysDetailColumns = ['Periodo', 'Fechas', 'Días']
  public hoursDetailColumns = ['Fecha', 'Hora inicio', 'Hora fin', 'Horas']
  public isMobile = false

  constructor(
    private _formBuilder: FormBuilder,
    private _trabajadoresService: TrabajadoresService,
    private _reportingService: ReportingService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _accountProvider: AccountProvider,
    private _renderer: Renderer2,
    private _spinner: AsSpinnerService,
    private changeDetectorRefs: ChangeDetectorRef,
    private _datePipe: DatePipe
  ) {
    this.dateProvider.setShortMinMaxDates();
    this.buttonCaption = "ENVIAR";
  }

  ngOnInit() {
    this.setSnackbarConfig()
    this.setRequestForm();
    this.setDiasCompletos(true);  // Sirve para cuando se crea una solicitud.
    this.getData()

    // Escuchador para cambio de Días Completos
    this.solicitudFormGroup.controls.diaCompleto.valueChanges.subscribe((value: boolean) => {
      this.setDiasCompletos(value);
    })

  }

  ngAfterViewChecked() {
    this.changeDetectorRefs.detectChanges()
  }

  ngAfterContentChecked() {
    this.changeDetectorRefs.detectChanges()
  }

  private setRequestForm(): void {
    // Formulario para creación
    this.solicitudFormGroup = this._formBuilder.group(
      {
        id: new FormControl({ value: 0, disabled: true }, Validators.required),
        fechaDesde: new FormControl({ value: null }, Validators.required),
        fechaHasta: new FormControl({ value: null }, Validators.required),
        motivo: new FormControl({ value: 0 }, Validators.required),
        descripcion: new FormControl({ value: '' }, Validators.required),
        diaCompleto: new FormControl(true),
        horaDesde: new FormControl(),
        horaHasta: new FormControl()
      });
  }

  private getData() {
    // Motivos Solicitud
    this._trabajadoresService.getTipados('MotivoSolicitud').subscribe((motivosSolicitud: any) => {
      this.motivosSolicitud = motivosSolicitud;

      if (!this.idSolicitud) {
        var motivoDefecto = this.motivosSolicitud.find(f => f.valorPorDefecto == true);
        this.solicitudFormGroup.patchValue({
          motivo: motivoDefecto.id,
          descripcion: ''
        });
      }

      this.getEmployeeRequests()
    });
  }

  private getEmployeeRequests() {
    // Solicitudes del trabajador pendientes o aceptadas de dias no completos.
    let empleado = { id: null }
    this._trabajadoresService.obtenerSolicitudesDisponibles(empleado).subscribe((data: any) => {
      this.partialDayRequests = data
      this.getUserHolidays()
    })
  }

  private getUserHolidays() {
    const today = new Date()
    const data = {
      idEmpleado: null,
      fechaInicio: new Date(today.getFullYear(), 0, 1),
      fechaFin: new Date(today.getFullYear(), 11, 31)
    }
    this._reportingService.vacacionesEmpleado(data).subscribe((holidayData: any) => {
      this.setEmployeeHolidays(holidayData)
    })
  }

  private setDiasCompletos(value: boolean) {
    this.showDiasCompletos = value;
    this.solicitudFormGroup.clearValidators();
    if (this.solicitudFormGroup) {
      let controls = this.solicitudFormGroup.controls
      let fechaHasta = controls.fechaHasta;
      let horaDesde = controls.horaDesde;
      let horaHasta = controls.horaHasta;

      if (this.showDiasCompletos) {
        fechaHasta.setValidators(this.required);
        fechaHasta.enable();
        horaDesde.disable();
        horaHasta.disable();
      } else {
        horaDesde.setValidators(this.required);
        horaHasta.setValidators(this.required);
        fechaHasta.disable()
        horaDesde.enable();
        horaHasta.enable();
      }
      fechaHasta.updateValueAndValidity();
      horaHasta.updateValueAndValidity();
      horaDesde.updateValueAndValidity();
    }
  }

  public displayFn(motivo) {
    var rtn = '';
    if (this.motivosSolicitud) {
      let mot = this.motivosSolicitud.find(m => m.id == motivo);
      rtn = mot.valor;
    }
    return rtn;
  }

  public onSubmit() {
    return false
  }

  private checkRequest(formData: any): boolean {
    let requestNotFound = false
    let fromDate = formData.fechaDesde
    let toDate = formData.fechaHasta
    var found = false
    var errMsg = ""
    if (!formData.diaCompleto) {
      found = this.partialDayRequests.find(r => r.fechaDesde.split('T')[0] == fromDate.format('YYYY-MM-DD'))
      errMsg = "Ya existe una solicitud para el día seleccionado"
    } else if (formData.diaCompleto) {
      if (fromDate.format('YYYY-MM-DD') === toDate.format('YYYY-MM-DD')) {
        found = false
        errMsg = ""; // 19/06/2023 - Se permite que se solicite para un solo día, teniendo la misma fecha en ambos campos "Las fechas deben ser distintas";
      } else if (fromDate.format('YYYY-MM-DD') > toDate.format('YYYY-MM-DD')) {
        found = true
        errMsg = "Fecha Desde debe ser inferior a Fecha Hasta"
      } else {
        found = this.partialDayRequests.find(r => r.fechaDesde.split('T')[0] == fromDate.format('YYYY-MM-DD') && r.fechaHasta.split('T')[0] == toDate.format('YYYY-MM-DD'))
        errMsg = "Ya existe una solicitud para los días seleccionados"
      }
    }
    requestNotFound = !found;
    if (found)
      this._matSnackBar.open(`${errMsg}.`, 'Error', this.snackbarConfig);
    return requestNotFound;
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig();
    this.snackbarConfig.horizontalPosition = 'right';
    this.snackbarConfig.verticalPosition = 'top';
    this.snackbarConfig.duration = 3000;
    this.snackbarConfig.panelClass = ['error-snackbar']
  }

  public sendMail() {
    this._spinner.enable()
    var formData = this.solicitudFormGroup.getRawValue();
    if (!this.idSolicitud && this.checkRequest(formData)) {
      this._trabajadoresService.altaSolicitud(formData).subscribe((createRequestData: any) => {
        if (createRequestData.ok) {

          // OK es true, significa que hemos insertado la solicitud.
          this._matSnackBar.open("Solicitud enviada", 'Notificación');
          let dayOffModel = JSON.parse(createRequestData.mensaje)
          if (dayOffModel.requestId && dayOffModel.sent) {
            // TODO: Mostramos solicitudes del usuario
            this._router.navigateByUrl('/solicitudes-propias');
          } else
            this.goHome();
        } else
          this.goHome();
      }, (error) => {
          this._matSnackBar.open("Error al enviar la solicitud", 'Error', this.snackbarConfig);
      });
    } 
  }

  public goHome() {
    this._router.navigateByUrl('/');
  }

  public cancelarEdicion() {
    this.goHome();
  }

  public dateChanges(evt: MatDatepickerInputEvent<Date>) {
    let dateProvider = new DateProvider();
    dateProvider.fromEvent(evt);
    dateProvider.addWeeks(1);

    // Put toDate value.
    let toDateMoment = moment(dateProvider.toDate)
    this.solicitudFormGroup.controls.fechaHasta.setValue(toDateMoment);


    // Update calendar to selected month.
    this.CalendarComp.goToDateInView(evt.value)
    this.CalendarComp.updateCalendar(dateProvider.fromDate);
  }

  private setEmployeeHolidays(inData: any) {
    const employee = new VacacionesEmpleado(inData[0])
    inData.forEach(data => {
      employee.append(data)
    })
    employee.calcDays()
    this.employeeHolidays = employee
    this.changeDetectorRefs.detectChanges()
  }

  getPeriod(period: PeriodoVacaciones) {
    const head = period.periodo.includes('/') ? 'del ' : ''
    return `${head}${period.periodo.replace('/', ' al ')}`
  }

  concatDates(period: PeriodoVacaciones) {
    let dateChain = ''
    period.fechas.forEach(d => dateChain += `${this._datePipe.transform(d, 'dd/MM')}, `)
    dateChain = dateChain.replace(/, $/, '').replace(/, (\d+\/\d+$)/, ' y $1')
    return dateChain
  }
}
