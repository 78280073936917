import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TenantService {


  public baseUrl = environment.appBaseHref;
  constructor(private _http: HttpClient) { }

  getTenant() {
    var url = this.baseUrl + 'api/Tenant/GetTenant';
    return this._http.get(url).subscribe((data:any) => {
      this.tenant = data.codigo;      
    });
  }

  getTenantSubscribe() {
    var url = this.baseUrl + 'api/Tenant/GetTenant';
    return this._http.get(url);
  }


  public tenant;

  Init() {
    /*
    return Promise<void>((resolve, reject) => {
      this.getTenant().subscribe((data: any) => {
        this.tenant = data.codigo;
        resolve();
      });
    });
    */
  }
}

@Injectable({
  providedIn: 'root'
})
export class TenantProvider {
  private tenant: string;
  public baseUrl = environment.appBaseHref;
  constructor(private _http: HttpClient) {

  }
  public getTenant(): string {
    return this.tenant;
  }

  public setTenant(value: string) {
    this.tenant = value;
  }

  load() {
    return this.getTenantAsync();
    //return new Promise((function (resolve, reject) { });
      //(resolve) => {
      //  this._http.get('api/Tenant/GetTenant').subscribe((data: any) => {
      //    this.tenant = data.codigo;          
      //    resolve(true);
      //  })
      //});    
  }

  private async getTenantAsync() {
    var url = this.baseUrl + 'api/Tenant/GetTenant';
    let asyncResult: any = await this._http.get(url).toPromise();
    this.tenant = asyncResult.codigo;
    //.subscribe((data: any) => {
    //   this.tenant = data.codigo;
    //});
  }
}

export function tenantProviderFactory(provider: TenantProvider) {
  return () => provider.load();
}
