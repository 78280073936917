import { Component } from '@angular/core';
import * as shape from 'd3-shape';
import { ActivatedRoute } from '@angular/router';

import { fuseAnimations } from '@fuse/animations/index';

@Component({
    selector: 'app-proyectos-lista-view',
    templateUrl: './lista.component.html',
    styleUrls: ['./lista.component.scss'],
    animations: fuseAnimations
})
export class ProyectosListaViewComponent {
    view: string;

    proyectos: any;
    constructor(private _route:ActivatedRoute) {
        // Set the defaults
        this.view = 'preview';
        this.proyectos = this._route.snapshot.data['proyectos'];
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle the view
     */
    toggleView(): void {
        if (this.view === 'preview') {
            this.view = 'source';
        }
        else {
            this.view = 'preview';
        }
    }
}
