import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuseSharedModule } from '@fuse/shared.module';
import { ConfiguracionComponent } from './configuracion/configuracion.component';
import { MatSelectModule} from '@angular/material/select';
import { CommonFormsModule } from '../_common/common-forms/common-forms.module';

import { Routes, RouterModule } from '@angular/router';
import { ConfiguracionService } from './configuracion.service';
import { AdminOrManagerGuardService } from '../users/services/admin-or-manager-guard.service';


const routes: Routes = [{
  path: 'configuracion',
  component: ConfiguracionComponent,
  data: {
    title: 'Configuración'
  },
  canActivate: [AdminOrManagerGuardService]
}]
@NgModule({
  imports: [
    CommonModule,
    CommonFormsModule,
    RouterModule.forChild(routes),
    FuseSharedModule,
    MatSelectModule
  ],
  declarations: [ConfiguracionComponent],
  providers:[ConfiguracionService]
})
export class ConfiguracionModule { }
