import { Component, OnInit, Inject, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FichajesService } from '../fichajes.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatDatepickerInputEvent, MatSnackBarConfig, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { AccountProvider } from '../../users/services/user.service';
import { User } from '../../users/users.module';
import { TrabajadoresService } from '../../trabajadores/trabajadores.service';
import { Router } from '@angular/router';
import { ConfiguracionService } from '../../configuracion/configuracion.service';
import { FichajeContraProyectoYTareaComponent } from '../fichaje-contra-proyecto-y-tarea/fichaje-contra-proyecto-y-tarea.component';
import { ProjectService } from '../../proyectos/services/project.service';
import { TaskService } from '../../proyectos/services/task.service';
import { Locator } from '../../_common/common-classes/common-punchings';
import { DateProvider } from '../../_common/common-classes/date-provider';
import { DateDiff } from '../../_common/common-classes/date-diff';
import { AsSpinnerComponent } from '../../_common/common-components/as-spinner/as-spinner.component';
import { AsSpinnerService } from '../../_common/common-components/as-spinner/as-spinner.service';


@Component({
  selector: 'app-autofichaje',
  templateUrl: './autofichaje.component.html',
  styleUrls: ['./autofichaje.component.css']
})
export class AutofichajeComponent implements OnInit {
  public parametersFormGroup: FormGroup;
  public canModifyWorker: boolean = false;
  public workerId: number = null;
  private user: User;
  public workers;
  public filteredWorkers;
  private workerIds: number[] = [];
  public workerIsDisabled: boolean = false;
  private isDisabled: boolean;
  public punchingList: any[] = []
  @Output() workerChange = new EventEmitter();
  private config: any;
  private geoloc = new Locator();
  private mustLogIntoProject: boolean;
  private selectedProject: string;
  private selectedTask: string;
  private dialogRef: MatDialogRef<FichajeContraProyectoYTareaComponent>;
  public columns = ['Proyecto', 'Tarea', 'FechaEntrada', 'HoraEntrada', 'FechaSalida', 'HoraSalida', 'TiempoFichaje']
  public projects: any;
  public tasks: any;
  public dateProvider = new DateProvider();
  public punchingRanges: TimingRanges[] = [];

  constructor(
    private _punchingService: FichajesService,
    private _formBuilder: FormBuilder,
    private _accountProvider: AccountProvider,
    private _trabajadoresService: TrabajadoresService,
    private _router: Router,
    private _config: ConfiguracionService,
    private _fichajesService: FichajesService,
    private _matSnackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialog: MatDialog,
    private _projectService: ProjectService,
    private _taskService: TaskService,
    private cdRef: ChangeDetectorRef,
    private _spinner: AsSpinnerService
  ) {
    this.dateProvider.setShortMinMaxDates()
   }

  ngOnInit() {
    this.user = this._accountProvider.getAccount();
    this.workerIsDisabled = !(this.user.isAdminWeb || this.user.isResponsable);
    this.getMustLoginIntoProject(parseInt(this.user.empleadoId));
    this.setForm();
    this.getWorkerIds();
    this._config.get().subscribe((config: any) => {
      this.config = config
    })
    this._projectService.getProjectList().subscribe((projects: any) => {
      this.projects = projects;
    })
    this._taskService.getTaskList().subscribe((tasks: any) => {
      this.tasks = tasks;
    })
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  private getMustLoginIntoProject(workerId: number) {
    this._fichajesService.userMustLoginIntoProject(workerId).subscribe((bool: boolean) => {
      this.mustLogIntoProject = bool;
    })
  }

  private getPreviousMonday(dateRef: Date) {
    // Obtenemos el lunes de la semana en curso.
    var prevMonday = new Date(dateRef);
    prevMonday.setDate(prevMonday.getDate() - (prevMonday.getDay() || 7) + 1)
    return prevMonday
  }

  private setForm() {
    this.parametersFormGroup = this._formBuilder.group({
      workerId: new FormControl({ value: null, disabled: this.workerIsDisabled }, Validators.required),
      fromDate: new FormControl(null, Validators.required),
      toDate: new FormControl(null, Validators.required)
    });
  }

  private fillForm() {
    this.workerId = parseInt(this.user.empleadoId);

    // Por defecto mostraremos los registros que haya para la semana en curso.
    let today = new Date()
    let fromDate = this.getPreviousMonday(today);
    var toDate = new Date();
    toDate = new Date(toDate.setDate(fromDate.getDate() + 4));

    this.parametersFormGroup.setValue({
      workerId: this.workerId,
      fromDate: fromDate,
      toDate: toDate,
    });

    this.getPunchings()
  }

  private getPunchings() {
    let formData = this.parametersFormGroup.getRawValue();

    let punchingFilter = {
      workerId: formData.workerId,
      projectId: null,
      fromDate: formData.fromDate,
      toDate: formData.toDate
    }

    this._fichajesService.getPunchingFilter(punchingFilter).subscribe((filterData: any) => {
      this.punchingList = filterData.punchingList;
      this.punchingRanges = filterData.punchingRanges;
    });

  }

  public fromDateChanges(evt: MatDatepickerInputEvent<Date>) {
    let fromDate = new Date(evt.value);
    var toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 4);
    this.parametersFormGroup.controls.fromDate.setValue(fromDate);
    this.parametersFormGroup.controls.toDate.setValue(toDate);

    this.getPunchings();
  }

  public toDateChanges(evt: MatDatepickerInputEvent<Date>) {
    let toDate = new Date(evt.value);
    this.parametersFormGroup.controls.toDate.setValue(toDate);

    this.getPunchings();
  }

  onSubmit() {
    this._spinner = new AsSpinnerService();
    this._spinner.enable()
    if (this.geoloc.error)
      this.showMsg(this.geoloc.message, this.geoloc.type);

    let formData = this.parametersFormGroup.getRawValue()
    // El empleado SIEMPRE tiene que tener un proyecto y una tarea por defecto.
    formData.project = this.selectedProject ? this.selectedProject : 1;
    formData.task = this.selectedTask ? this.selectedTask: 1;

    this._fichajesService.userMustLoginIntoProject(formData.workerId).subscribe(
      (bool: boolean) => {
        this.mustLogIntoProject = bool;
        if (this.mustLogIntoProject) {
          this.openDialog(formData)
        } else {
          this.generatePunchings(formData)
        }
        this._spinner.disable()
      },
      (error) => {
        this._spinner.disable()
      }
    )
  }

  private generatePunchings(formData: any) {
    this.config.punchingRanges = this.punchingRanges;
    let rndPunching = new RandomPunching(formData, this.config, this.geoloc);
    this._punchingService.autofichajes(rndPunching).subscribe((data: any) => {
      let json = JSON.parse(data.mensaje)
      if (json.error) {
        // El servicio devuelve un error.
        this.showMsg(json.error, 'Error');
        return
      }

      let summary = json.pop();
      if (summary.InsertedPunchings === 0) {
        if (summary.WorkingDays > 0) {
          // No se han generado ni insertado registros.
          this.showMsg('No ha habido cambios', '', 'incoming-snackbar');
        } else if (summary.WorkingDays === 0) {
          // El empleado no dispone de días laborales (debido a vacaciones, festivos, etc.).
          this.showMsg(
            'El empleado no dispone de días laborales para las fechas indicadas.',
            'Error',
            'error-snackbar',
            5000
          );
        }
        return
      }
      // Se ha podido insertar los fichajes.
      let newPunchings = summary.InsertedPunchings;
      this.showMsg(`Se han insertado ${newPunchings} registros correspondientes a ${newPunchings / 4} días.`, 'Notificación', 'popup')
      this.getPunchings();
    })
  }

  public getProject(projId: number) {
    if (!projId)
      return
    let proj = this.projects.find(proj => proj.id == projId);
    if (proj)
      return proj.nombre
    else
      return 'PROYECTO FINALIZADO O BORRADO'
  }

  public getTask(taskId: number) {
    if (!taskId)
      return
    let task = this.tasks.find(task => task.id == taskId);
    if (task)
      return task.descripcion
    else
      return 'TAREA FINALIZADA O BORRADA'
  }

  private getTimeDiff(item: any) {
    let entry = new Date(item.fechaRegistroSalida)
    let exit = new Date(item.fechaRegistroEntrada)
    return entry.getTime() - exit.getTime()
  }

  public getTimeDiffToString(diff: number, showDays: boolean = true, showMinutes: boolean = true) {
    let dateDiff = new DateDiff(diff, showDays, showMinutes);
    return dateDiff.toString();
  }

  public getPunchingTime(item, showDays = true, showMinutes = true) {
    let diff = this.getTimeDiff(item);
    return this.getTimeDiffToString(diff, showDays, showMinutes);
  }


  // #region Worker
  getWorkerIds() {
    return this._trabajadoresService.getWorkers().subscribe((data: any) => {
      this.workers = data;
      this.filteredWorkers = data;
      data.forEach(worker => this.workerIds.push(worker.id))
      this.fillForm();
    });
  }

  writeValue(obj: any): void {
    if (obj !== null) {
      this.workerId = obj;
      // this.workerIsDisabled = true
    } else {
      this.workerId = null
      // this.workerIsDisabled = false
    }
  }

  propagateChange = (_: any) => { };

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  onTouch = () => { }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  cancel() {
    this._router.navigateByUrl('/')
    return false
  }

  private checkUserCanAddPunchings(): void {
    let loggedInUserWorkerId = parseInt(this.user.empleadoId);
    this.canModifyWorker = this.user.isAdminWeb || this.user.isResponsable
    if (this.workerId && loggedInUserWorkerId !== this.workerId && !(this.user.isAdminWeb || this.user.isResponsable)) {
      this.showMsg('No se permite asignar un usuario distinto al suyo', 'Error');
      this.workerId = parseInt(this.user.empleadoId);
      this.parametersFormGroup.patchValue({ workerId: loggedInUserWorkerId })
    }
  }

  selectWorker(evt: any) {
    this.checkUserCanAddPunchings()
    if (!this.canModifyWorker) {
      return false
    }

    var selectedWorker = null
    if (this.workerIds.includes(this.workerId)) {
      selectedWorker = this.workerId
    } else if (this.workerId) {
      this.filteredWorkers = this.filter(`${this.workerId}`)
    } else {
      // empleado está vacío
      this.workerId = null;
      this.filteredWorkers = this.workers;
    }

    this.workerChange.emit(selectedWorker); //Para bindar con ngModel y Output
    this.propagateChange(selectedWorker);
    this.onTouch();

    // Actualizamos punchingList si tenemos valores en las fechas, sino estamos en el inicio
    // y no se devolverían datos llamando a getPunchings(), por lo tanto salimos.
    let formData = this.parametersFormGroup.getRawValue();
    if (!formData.fromDate && !formData.toDate)
      return;
    this.getMustLoginIntoProject(parseInt(formData.workerId))
    if (selectedWorker) {
      this.getPunchings()
    } else {
      this.punchingList = []
    }
  }

  private toLcTrim(worker: string): string {
    return worker.toLowerCase().trim();
  }

  private filter(value: string) {
    const filterTerm = this.toLcTrim(value);

    return this.workers.filter(w => `${this.toLcTrim(w.nombre)} ${this.toLcTrim(w.apellido1)} ${this.toLcTrim(w.apellido2)}`.includes(filterTerm));
  }

  public enableWorker() {
    // this.workerIsDisabled = !this.canModifyWorker;
  }

  public checkWorker() {
    if (this.workerId !== null && typeof this.workerId !== 'undefined') {
      // this.workerIsDisabled = true;
    }
  }

  public getWorkerIsDisabled() {
    return this.workerIsDisabled;
  }

  displayFnWorker(workerId) {
    var rtn = '';
    if (this.workers) {
      let worker = this.workers.find(x => x.id == workerId);
      if (worker)
        rtn = `${worker.nombre} ${worker.apellido1} ${worker.apellido2}`;
    }
    return rtn;
  }
  // #endregion

  // #region Geoloc
  // Geolocation: Success
  // #endregion

  // #region Select Project and Task
  openDialog(formData: any) {
    let inData = { selectedProject: this.selectedProject, selectedTask: this.selectedTask, buttonName: 'GENERAR' }
    this.dialogRef = this._matDialog.open(FichajeContraProyectoYTareaComponent, {
      data: inData,
      disableClose: false,
      autoFocus: true,
      hasBackdrop: true,
      closeOnNavigation: true
    });

    this.dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.selectedProject = data.selectedProject;
        this.selectedTask = data.selectedTask;
        formData.project = data.selectedProject;
        formData.task = data.selectedTask;
        this.generatePunchings(formData);
      }
    })
  }
  // #endregion

  private showMsg(msg, type = '', panelClass = 'error-snackbar', duration=3000) {
    let sbConfig = new MatSnackBarConfig();
    sbConfig.horizontalPosition = 'right';
    sbConfig.verticalPosition = 'top';
    sbConfig.duration = duration;
    sbConfig.panelClass = [panelClass];
    this._matSnackBar.open(msg, type, sbConfig);
  }

  public getEllipsis(): string {
    return this.mustLogIntoProject ? '...' : '';
  }
}

export class RandomPunching {
  public workerId: number;
  public fromDate: Date;
  public toDate: Date;
  public morningStartHour: string;
  public morningEndHour: string;
  public eveningStartHour: string;
  public eveningEndHour: string;
  public margin: string;
  private days: number;
  private latitude: string;
  private longitude: string;
  private device: string;
  private project: string;
  private task: string

  constructor(formData: any, config: any, geoloc: Locator) {
    // from FormData
    this.workerId = formData.workerId;
    this.fromDate = this.resetDate(formData.fromDate);
    this.toDate = this.resetDate(formData.toDate, false);
    this.project = formData.project;
    this.task = formData.task;

    // from config
    this.morningStartHour = config.morningStartHour;
    this.morningEndHour = config.morningEndHour;
    this.eveningStartHour = config.eveningStartHour;
    this.eveningEndHour = config.eveningEndHour;
    this.margin = config.margenAutofichaje;
    let dateDiff = new DateDiff(this.toDate.getTime() - this.fromDate.getTime());
    this.days = dateDiff.dayDelay + 1; // Por simplicidad, hallamos diferencia de días y sumamos 1 para obtener el conjunto de días.

    // from geoloc
    this.device = geoloc.device;
    this.latitude = geoloc.latitude;
    this.longitude = geoloc.longitude;
  }

  private resetDate(date: Date, min = true): Date {
    // Ajustamos las horas a 00:00:00 para FechaDesde y 23:59:59 para HoraHasta. NOTA: en vez de 0 le pongo 2 por el cambio de hora.
    date.setHours(min ? 2 : 23);
    date.setMinutes(min ? 0 : 59);
    date.setSeconds(min ? 0 : 59);
    return date
  }

}

export class NewRandomPunching {
  public workerId: number;
  public fromDate: Date;
  public toDate: Date;
  public punchingRanges: TimingRanges;
  public margin: string;
  private days: number;
  private latitude: string;
  private longitude: string;
  private device: string;
  private project: string;
  private task: string

  constructor(formData: any, config: any, geoloc: Locator) {
    // from FormData
    this.workerId = formData.workerId;
    this.fromDate = this.resetDate(formData.fromDate);
    this.toDate = this.resetDate(formData.toDate, false);
    this.project = formData.project;
    this.task = formData.task;

    // from config
    this.punchingRanges = config.punchingRanges
    this.margin = config.margenAutofichaje;
    let dateDiff = new DateDiff(this.toDate.getTime() - this.fromDate.getTime());
    this.days = dateDiff.dayDelay + 1; // Por simplicidad, hallamos diferencia de días y sumamos 1 para obtener el conjunto de días.

    // from geoloc
    this.device = geoloc.device;
    this.latitude = geoloc.latitude;
    this.longitude = geoloc.longitude;
  }

  private resetDate(date: Date, min = true): Date {
    // Ajustamos las horas a 00:00:00 para FechaDesde y 23:59:59 para HoraHasta. NOTA: en vez de 0 le pongo 2 por el cambio de hora.
    date.setHours(min ? 2 : 23);
    date.setMinutes(min ? 0 : 59);
    date.setSeconds(min ? 0 : 59);
    return date
  }

}

export class TimingRanges {
  public weekday: number;
  public morningStartHour: string;
  public morningEndHour: string;
  public eveningStartHour: string;
  public eveningEndHour: string;
}
