import { MatDatepickerInputEvent } from "@angular/material";

export class DateProvider {

  /* Al modificar fechaDesde, asignamos a fechaHasta uno de los siguientes valores:
   - si fechaDesde es día 1, fecha hasta será el último día del mismo mes.
   - si fechaDesde es superior a 1, queremos el mismo día del mes siguiente.
   - si escogemos el último día del mes, queremos ver hasta el último día del mes siguiente.
     Esto es, si el mes elegido tiene 31 y el siguiente 30, no devolvemos el 1 del siguiente mes al mes siguiente, sino 30 del mes siguiente.
     Por ejemplo: 31/03/2023 asignaría a fechaHasta a 01/05/2023, queremos que devuelva 30/04/2023 (o sea 30 y 31 de marzo da siempre 30 de abril)
     También: 31/01/2023 daría 02/03/2023, mientras que queremos que devuelva 28/02/2023 (del 28 al 31 de enero devolverá, 28 de febrero)
  En definitiva el mes de FechaHasta siempre debe ser m + 1, donde m es el mes de FechaDesde.
  */

  public fromDate: Date;
  public toDate: Date;
  public minDate: Date;
  public maxDate: Date;
  public year: number;
  public month: string;
  public parsedDate: Date;

  constructor() {
    this.fromDate = new Date();
    this.toDate = new Date();
    this.generate();
  }

  public fromEvent(evt: MatDatepickerInputEvent<Date>) {
    this.fromDate = new Date(evt.value)
    this.toDate = new Date(evt.value)

    this.generate();
  }

  private generate() {
    if (this.fromDate.getDate() == 1)
      this.toDate = new Date(this.fromDate.getFullYear(), this.fromDate.getMonth() + 1, 0, 23, 59, 59, 99)
    else {
      this.toDate.setMonth(this.fromDate.getMonth() + 1)
      this.toDate = new Date(this.toDate.getFullYear(), this.toDate.getMonth(), this.toDate.getDate(), 23, 59, 59, 99)
      if (this.toDate.getMonth() - this.fromDate.getMonth() == 2) {
        this.toDate = new Date(this.toDate.getFullYear(), this.toDate.getMonth(), 0, 23, 59, 59, 99)
      }
    }

  }

  public toUTC(date: Date) {
    date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds()));
    return date.toDateString()
  }

  public addWeeks(weeks: number) {
    this.toDate = this.fromDate;
    this.toDate.setDate(this.toDate.getDate() + weeks * 7);
  }

  public setMinMaxDates() {
    // minDate y maxDate de dos años vista.
    let today = new Date();
    this.minDate = new Date(today.getFullYear() - 2, 0, 1);
    this.maxDate = new Date(today.getFullYear() + 2, 11, 31, 23, 59, 59, 999);

  }

  public setShortMinMaxDates() {
    // minDate y maxDate, reduïm els límits a:
    let today = new Date();
    // - primer dia del mes actual de dos anys arrere
    this.minDate = new Date(today.getFullYear() - 2, today.getMonth(), 1);
    // - últim dia del mes en sis mesos.
    // 2024/02/21 - Canviat a últim dia de l'any per a poder agarrar vacances.
    this.maxDate = new Date(today.getFullYear(), 12, 0, 23, 59, 59, 999);
    this.year = today.getFullYear();
    this.month = `${today.getMonth() + 1}`.padStart(2, '0');
  }

  public fromMonthAndYear(monthYear: string) {
    // Algunos meses no los parsea, de ahí missing, replaced...
    let missing = { ENE: 'JAN', ABR: 'APR', AGO: 'AUG', DIC: 'DEC' }
    let monthText = monthYear.slice(0, 3)
    let replaced = missing[monthText]
    if (replaced)
      monthYear = monthYear.replace(monthText, replaced);
    // FireFox no parsea 'JUN 2023' ni '1 JUN. 2023'
    let newMonthYear = `1 ${monthYear.replace('.', '')}` 
    let date = new Date(Date.parse(newMonthYear))
    this.month = `${date.getMonth() + 1}`.padStart(2, '0');
    this.year = date.getFullYear();
    this.parsedDate = date;
  }
}
