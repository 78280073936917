import { Injectable } from '@angular/core';
import { AccountProvider } from './user.service';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { PlatformLocation } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SelfAccountGuardService implements CanActivate {

  constructor(
    private _router: Router,
    private _accountProvider: AccountProvider,
    private _platformLocation: PlatformLocation
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // Damos acceso únicamente si el usuario es el de la id o es admin
    var account = this._accountProvider.getAccount();
    var host: string = this._platformLocation["location"].host;
    var subdominio = account && account.subdominio ? account.subdominio : '';

    if (subdominio !== '' && !host.startsWith(subdominio)) {
      let url = this._platformLocation["location"].href;
      window.location.href = url.replace('://', '://' + account.subdominio + '.');
      return false;
    }

    let accountId = route.paramMap.get('id')
 
    let allowed = account && (account.empleadoId == accountId || account.isAdminWeb);
    if (allowed)
      return true;
    this._router.navigateByUrl('')
    return allowed;
  }
}
