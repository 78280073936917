import { HttpInterceptor, HttpRequest, HttpHandler, HttpUserEvent, HttpEvent } from "@angular/common/http";
import { Observable } from "rxjs";
//import 'rxjs/add/operator/do';
import { Injectable, Inject } from "@angular/core";
import { Router } from "@angular/router";
import { TenantProvider } from "../_conf/tenant.service";
import { environment } from '../../environments/environment';
import { CookieManagerService } from "../_conf/cookie-manager.service";
import { AccountProvider } from "../users/services/user.service";
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private _tenantProvider: TenantProvider,
    private _cookieManagerService: CookieManagerService,
    private _accountProvider: AccountProvider) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (request.url.includes("api/Tenant/GetTenant")) {
        return next.handle(request);
      }

      // Llamadas del API con AllowAnonymous.
      let interceptorArr = ['token', 'get-mail', 'forgot-password', 'forgot-pinkey', 'reset-password', 'reset-pinkey']
      for (let tail of interceptorArr) {
        if (request.url.endsWith(`/${tail}`)) {
          if (this._tenantProvider.getTenant()) {
            request = request.clone({
              headers: request.headers.set("Tenant", this._tenantProvider.getTenant())
            });
          }
          return next.handle(request);
        }
      }

      // Si no hay cookie, el usuario debe iniciar sesión.
      var cookie = this._cookieManagerService.getCookie('userToken');
      if (cookie != null) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${cookie}`,
            Tenant: this._tenantProvider.getTenant()
          }
        });
        return next.handle(request);
      } else {
         this.router.navigateByUrl('/login');
      }

      //if (localStorage.getItem('userToken') != null) {
      //  request = request.clone({
      //    setHeaders: {
      //      Authorization: 'Bearer ' + localStorage.getItem('userToken'),
      //      Tenant: this._tenantProvider.getTenant()
      //    }
      //  });
      //  return next.handle(request);
      //}
      //else {
      //  this.router.navigateByUrl('/login');
      //}
    }
}
