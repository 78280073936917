import { Component, OnInit } from '@angular/core';
import { AsSpinnerService } from './as-spinner.service';

@Component({
  selector: 'app-as-spinner',
  templateUrl: './as-spinner.component.html',
  styleUrls: ['./as-spinner.component.css']
})
export class AsSpinnerComponent implements OnInit {

  constructor(private _spinnerService : AsSpinnerService) { }

  ngOnInit() {
  }

    get spinnerIsVisible(): boolean {
        return this._spinnerService.spinnerEnabled;
    }
}
