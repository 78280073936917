import { Component, OnInit, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'
import { TrabajadoresService } from '../trabajadores.service'
import { ActivatedRoute, Params, Router } from '@angular/router'
import { MatSnackBar, MatSnackBarConfig, MatDialogRef, MatDialog } from '@angular/material'
import { AsSpinnerService } from '../../_common/common-components/as-spinner/as-spinner.service'
import { AccountProvider } from '../../users/services/user.service'
import { AsConfirmComponent } from '../../_common/common-components/as-confirm/as-confirm.component'
import { CalendariosService } from '../../calendarios/calendarios.service'
import { DecimalPipe } from '@angular/common'


@Component({
  selector: 'app-trabajadores-ficha',
  templateUrl: './trabajadores-ficha.component.html',
  styleUrls: ['./trabajadores-ficha.component.scss']
})
export class TrabajadoresFichaComponent implements OnInit {
  private calendarios: any
  public trabajadorFormGroup: FormGroup
  public empleadoId
  public empleado
  public mensajeError
  public user
  public disableUsername = true
  public pwdIcon: string
  public pkIcon: string
  public pwdVisibility: boolean
  public pkVisibility: boolean
  public pwdVisibilityClicked: boolean = false
  public pkVisibilityClicked: boolean = false
  public fgtPwdIsDisabled: boolean
  private snackbarConfig: MatSnackBarConfig
  private pinKeyHasChanged: boolean
  private departamentos: any
  private centrosTrabajo: any
  private horasJornada: number
  private mensajeHorasJornada = 'Debe deseleccionar el botón Horario libre o insertar un valor a numérico superior a 0 en el campo Horas Jornada.'
  private newUserId; // Para mostrar el id que se generará
  private deleteWorkerBool: boolean
  private confirmDialogRef: MatDialogRef<AsConfirmComponent>
  private pattern = /(Arrow(Down|Up|Left|Right)|Backspace|(Caps|Num|Scroll)Lock|Control|Delete|Enter|End|F\d+|Home|Page(Up|Down)|Pause|PrintScreen)|Shift|Tab/
  public loading = false
  public hasOnlyDocNo = false

  constructor (
    private confirmDialog: MatDialog,
    private _formBuilder: FormBuilder,  
    private _activatedRoute: ActivatedRoute,
    private _trabajadoresService: TrabajadoresService,
    private _matSnackBar: MatSnackBar,
    private _router: Router,
    private _asSpinner: AsSpinnerService,
    private _accountProvider: AccountProvider,
    private _calendariosService: CalendariosService,
    private _decimaPipe: DecimalPipe
  ) {
    this.setSnackbarConfig()
    this.pinKeyHasChanged = false
    this.deleteWorkerBool = false
    this.fgtPwdIsDisabled = false
    this.hasOnlyDocNo = this._trabajadoresService.tenantUsesDocAsPass()
  }

  private setSnackbarConfig() {
    this.snackbarConfig = new MatSnackBarConfig()
    this.snackbarConfig.horizontalPosition = 'right'
    this.snackbarConfig.duration = 3000
    this.snackbarConfig.verticalPosition = 'top'
    this.snackbarConfig.panelClass = ['error-snackbar']
  }

  ngOnInit() {
    this.pwdIcon = 'visibility'
    this.pkIcon = 'visibility'
    this.pwdVisibility = false
    this.pkVisibility = false
    this.getCalendarios()
    this.getDepartamentos()
    this.getCentrosTrabajo()

    this._activatedRoute.params.subscribe((params: Params) => {
      this.empleadoId = params['id']
      if (this.empleadoId)
        this.getEmpleado(this.empleadoId)
    })

    // Obtenemos el PinId que se le asiganará al usuario al crearlo.
    this._trabajadoresService.getNewUserId().subscribe((data) => {
      this.newUserId = data
      if (!this.empleadoId)
        this.trabajadorFormGroup.patchValue({ calendarioId: 1, pinId: this.newUserId })
    })

    this.user = this._accountProvider.getAccount()

    if (this.hasOnlyDocNo) {
      this.trabajadorFormGroup = this._formBuilder.group(
        {
          id: new FormControl({ value: 0, disabled: true }, Validators.required),
          nombre: new FormControl({ value: null, disabled: false }, Validators.required),
          apellido1: new FormControl({ value: null, disabled: false }, Validators.required),
          apellido2: new FormControl({ value: null, disabled: false }),
          nif: new FormControl({ value: null, disabled: false }),
          mail: new FormControl({ value: null, disabled: false }),
          telefono: new FormControl({ value: null, disabled: false }),
          cargo: new FormControl({ value: null, disabled: false }),
          departamento: new FormControl({ value: null, disabled: false }),
          pinId: new FormControl({ value: null, disabled: true }),
          nombreUsuario: new FormControl({ value: null, disabled: false }),
          password: new FormControl({ value: null, disabled: false }),
          password2: new FormControl({ value: null, disabled: false }),
          pinKey: new FormControl({ value: null, disabled: false }),
          activo: new FormControl({ value: true, disabled: false }),
          centroTrabajo: new FormControl({ value: null, disabled: false }),
          ficharContraProyectos: new FormControl({ value: null, disabled: false }),
          calendarioId: new FormControl({ value: 1, disable: false }, Validators.required),
          horarioLibre: new FormControl({ value: null, disabled: false }),
          horasJornada: new FormControl({ value: null, disabled: false }),
          noCtaBancaria: new FormControl({ value: null, disable: false }, Validators.required),
          noSegSocial: new FormControl({ value: null, disabled: false }, Validators.required),
          importeAPagar: new FormControl({ value: null, disabled: false }, Validators.required)
        })

    } else {
      this.trabajadorFormGroup = this._formBuilder.group(
        {
          id: new FormControl({ value: 0, disabled: true }, Validators.required),
          nombre: new FormControl({ value: null, disabled: false }, Validators.required),
          apellido1: new FormControl({ value: null, disabled: false }, Validators.required),
          apellido2: new FormControl({ value: null, disabled: false }),
          nif: new FormControl({ value: null, disabled: false }),
          mail: new FormControl({ value: null, disabled: false }, Validators.required),
          telefono: new FormControl({ value: null, disabled: false }),
          cargo: new FormControl({ value: null, disabled: false }),
          departamento: new FormControl({ value: null, disabled: false }),
          pinId: new FormControl({ value: null, disabled: true }),
          nombreUsuario: new FormControl({ value: null, disabled: false }, Validators.required),
          password: new FormControl({ value: null, disabled: false }, Validators.required),
          password2: new FormControl({ value: null, disabled: false }, Validators.required),
          pinKey: new FormControl({ value: null, disabled: false }, Validators.required),
          activo: new FormControl({ value: true, disabled: false }),
          centroTrabajo: new FormControl({ value: null, disabled: false }),
          ficharContraProyectos: new FormControl({ value: null, disabled: false }),
          calendarioId: new FormControl({ value: 1, disable: false }, Validators.required),
          horarioLibre: new FormControl({ value: null, disabled: false }),
          horasJornada: new FormControl({ value: null, disabled: false }),
          noCtaBancaria: new FormControl({ value: null, disable: false }),
          noSegSocial: new FormControl({ value: null, disabled: false }),
          importeAPagar: new FormControl({ value: null, disabled: false })
        })
    }
  }

  getEmpleado(id) {
    this._trabajadoresService.getFicha(id).subscribe((data: any) => {
      this.empleado = data

      this.trabajadorFormGroup.setValue({
        id: data.id,
        nombre: data.nombre,
        apellido1: data.apellido1,
        apellido2: data.apellido2,
        nif: data.nif,
        mail: data.mail,
        telefono: data.telefono,
        cargo: data.cargo,
        centroTrabajo: data.centroTrabajo,
        departamento: data.departamento,
        pinId : data.pinId ? data.pinId : '',
        nombreUsuario : data.usuarioNombre ? data.usuarioNombre : '',
        password: '',
        password2: '',
        pinKey: '',
        activo: data.activo,
        ficharContraProyectos: data.ficharContraProyectos,
        calendarioId: data.calendarioId,
        horarioLibre: data.horarioLibre,
        horasJornada: data.horasJornada,
        noCtaBancaria: data.noCtaBancaria,
        noSegSocial: data.noSegSocial,
        importeAPagar: new Intl.NumberFormat('ca-AD').format(data.importeAPagar)
      })

      if (!this.hasOnlyDocNo) {
        this.trabajadorFormGroup.controls.password.clearValidators()
        this.trabajadorFormGroup.controls.password.updateValueAndValidity()
        this.trabajadorFormGroup.controls.password2.clearValidators()
        this.trabajadorFormGroup.controls.password2.updateValueAndValidity()
        this.trabajadorFormGroup.controls.pinKey.clearValidators()
        this.trabajadorFormGroup.controls.pinKey.updateValueAndValidity()
      }

      this.horasJornada = data.horasJornada
      if (data.horasJornada == 0) {
        this.trabajadorFormGroup.controls.horasJornada.disable()
      }
    })
  }

  private showError(message) {
    this._matSnackBar.open(message, 'Error', this.snackbarConfig)
  }

  private showSelectErrorSnackBar(topic) {
    this.showError(`Debe selecionar ${topic} del desplegable`)
  }

  private validateData() {
    if (this.deleteWorkerBool) {
      this.deleteWorkerBool = false
      return false
    }
    let ctrl = this.trabajadorFormGroup.controls

    // Las contraseñas no coinciden.
    if (ctrl.password.value != ctrl.password2.value) {
      this.showError('Las contraseñas no coinciden')
      return false
    }

    // El departamento es incorrecto, no existe. (Pero se permite no introducir ninguno.)
    if (ctrl.departamento.value && typeof ctrl.departamento.value != 'number' && ctrl.departamento.value.length > 0) {
      this.showSelectErrorSnackBar('un departamento')
      return false
    }

    // El centro de trabajo es incorrecto, no existe. (Pero se permite no introducir ninguno.)
    if (ctrl.centroTrabajo.value && typeof ctrl.centroTrabajo.value != 'number' && ctrl.centroTrabajo.value.length > 0) {
      this.showSelectErrorSnackBar('un centro de trabajo')
      return false
    }

    // El calendario es incorrecto, no existe.
    if (ctrl.calendarioId.value && typeof ctrl.calendarioId.value != 'number' && ctrl.calendarioId.value.length > 0) {
      this.showSelectErrorSnackBar('un calendario')
      return false
    }


    if (ctrl.horarioLibre.value && !ctrl.horasJornada.value) {
      // this.showError('El campo horas de jornada no puede estar vacío o ser 0.')
      this.mensajeError = this.mensajeHorasJornada
      return false
    }

    if (!this.empleado) {
      // Es una alta, se puede considerar como válido.
      this.pinKeyHasChanged = true
      return true
    }

    let changes = [
      this.empleado.nombre == ctrl.nombre.value,
      this.empleado.apellido1 == ctrl.apellido1.value,
      this.empleado.apellido2 == ctrl.apellido2.value,
      this.empleado.nif == ctrl.nif.value,
      this.empleado.mail == ctrl.mail.value,
      this.empleado.telefono == ctrl.telefono.value,
      this.empleado.cargo == ctrl.cargo.value,
      this.empleado.departamento == ctrl.departamento.value,
      this.empleado.usuarioNombre == ctrl.nombreUsuario.value,
      this.empleado.activo == ctrl.activo.value,
      ctrl.password.value == '' && ctrl.password2.value == '',
      ctrl.pinKey.value == '',
      this.empleado.centroTrabajo == ctrl.centroTrabajo.value,
      this.empleado.ficharContraProyectos == ctrl.ficharContraProyectos.value,
      this.empleado.horarioLibre == ctrl.horarioLibre.value,
      this.empleado.horasJornada == ctrl.horasJornada.value,
      this.empleado.noCtaBancario == ctrl.noCtaBancaria.value,
      this.empleado.noSegSocial == ctrl.noSegSocial.value,
      this.empleado.importeAPagar == ctrl.importeAPagar.value
    ]

    // El formulario no se ha modificado
    if (changes.every(Boolean)) {
      this._matSnackBar.open('No ha habido cambios', '')
      return false
    }

    // Esta comprobación es para no saltarse la verificación de las PinKeys
    // existentes si no se ha modificado ésta. La verificación se hace en
    // el back-end.
    if (ctrl.pinKey.value) {
      this.pinKeyHasChanged = true
    }
    
    return true
  }


  private toTitleCase(name: string): string {
    if (!name)
      return ''
    var nameToTitleCase = ''
    name.trim().split(' ').forEach(word => { if (word) nameToTitleCase += `${word[0].toUpperCase()}${word.toLowerCase().substr(1)} ` })
    return nameToTitleCase
  }

  private capitalizeNames(formData: any) {
    formData.nombre = this.toTitleCase(formData.nombre)
    formData.apellido1 = this.toTitleCase(formData.apellido1)
    formData.apellido2 = this.toTitleCase(formData.apellido2)
  }

  onSubmit() {
    /*
     * Comprobamos que los campos se hayan modificado.
     * Si es una alta y se tienen permisos vamos a editar el nuevo usuario para
     * que se nos muestre los roles y poder asignarle alguno.
     */
    if (!this.validateData())
      return
    this._asSpinner.enable()
    this.loading = true

    const formData = this.trabajadorFormGroup.getRawValue()
    formData.importeAPagar = parseFloat(formData.importeAPagar.replace(/[ .]/, '').replace(',', '.'))
    if (this.hasOnlyDocNo)
      formData.checkPinKey = false
    else
      formData.checkPinKey = this.pinKeyHasChanged
    formData.skipEmail = this.hasOnlyDocNo
    formData.idUsuario = this.empleado ? this.empleado.usuarioWeb : null
    this.capitalizeNames(formData)

    this._trabajadoresService.altaEmpleado(formData).subscribe((respData: string) => {
      this.loading = false
      this._asSpinner.disable()
      let json = JSON.parse(respData)
      let action = json.new ? 'dado de alta' : 'actualizado'
      this._matSnackBar.open(`Empleado ${action} correctamente`, '')
      if (this.user.isAdminWeb) {
        /*
          Si es un nuevo empleado al que acabamos de dar de alta,
          mostramos la página de modificación para que aparezcan
          esta vez los roles y se le pueda asignar alguno.
        */
        if (json.new)
          this._router.navigateByUrl(`/empleado/${json.workerId}`)
        else
          this._router.navigateByUrl("/empleados")
      }
    }, (error) => {
        this.loading = false
        this._asSpinner.disable()
        this.showError(error.error.message)
    })
  }

  public confirmDeleteWorker() {
    this.deleteWorkerBool = true
    let action = { title: 'Borrar Empleado', question: 'Confirme que desea borrar el empleado.' }
    let config = { data: { titulo: action.title, mensaje: action.question, } }
    this.confirmDialogRef = this.confirmDialog.open(AsConfirmComponent, config)
    this.confirmDialogRef.afterClosed().subscribe((resp) => {
      if (resp)
        this.deleteWorker()
    })

  }

  private deleteWorker() {
    // No permitimos que un empleado se borre.
    if (this.isOnlyEmployee()) {
      this.showError('No tiene permisos para realizar esta acción')
      return
    }
    
    this._asSpinner.enable()
    var data = this.trabajadorFormGroup.getRawValue();    
    this._trabajadoresService.borrarEmpleado(data).subscribe((data) => {
      this._asSpinner.disable()
      this._matSnackBar.open('Empleado borrado', '')
      // this._router.navigateByUrl("/empleados")
      this.volver()
  }, (error) => {
      this._asSpinner.disable()
      this.showError(error.error.message)
    })
  }

  cancelarEdicion() {
    this.volver()
  }

  volver() {
    this._router.navigateByUrl(this.user.isAdminWeb ? '/empleados' : '/')
  }

  public checkKeyDown(evt: any) {
    let key = evt.key
    if (!key) {
      return false
    } else if (this.pattern.test(key)) {
      return true
    } else if (key == 'Escape') {
      this.trabajadorFormGroup.patchValue({ pinKey: '' })
      return true
    } else if (key.toLocaleUpperCase() == 'V') {
      this.toggleVisibility(evt)
      return false
    } else if (/.*\D.*/.test(key)) {
      return false
    }
  }

  public userInput(evt) {
    const newValue = evt.target.value.replace(/[^\d,. ]/g, '').replace('.', ',')
    evt.target.value = newValue
  }

  // #region Visibilidad caracteres conbtraseña y clave de acceso rápido
  public toggleVisibility(evt) {
    let target = evt.target.attributes.name.nodeValue
    let visibility = target == 'pinkey' ? this.pkVisibility : this.pwdVisibility
    let visibilityClicked = target == 'pinkey' ? this.pkVisibilityClicked : this.pwdVisibilityClicked
    let mouseleave = evt.type == 'mouseleave'
    if (visibilityClicked || (!visibilityClicked && mouseleave && !visibility))
      return
    if (target == 'pinkey') {
      this.pkVisibility = !visibility
      this.pkIcon = this.pkVisibility ? 'visibility_off' : 'visibility'
    } else {
      this.pwdVisibility = !visibility
      this.pwdIcon = this.pwdVisibility ? 'visibility_off' : 'visibility'
    }
  }

  public pinKeyVisibilityClicked() {
    if (this.pkVisibilityClicked && this.pkVisibility) {
      this.pkIcon = 'visibility'
      this.pkVisibility = false
      this.pkVisibilityClicked = false
      return
    }
    this.pkVisibilityClicked = true
  }

  public passwordVisibilityClicked() {
    if (this.pwdVisibilityClicked && this.pwdVisibility) {
      this.pwdIcon = 'visibility'
      this.pwdVisibility = false
      this.pwdVisibilityClicked = false
      return
    }
    this.pwdVisibilityClicked = true
  }
  // #endregion

  public isOnlyEmployee() {
    return !this.user.isAdminWeb && !this.user.isResponsable
  }

  public isOnlyEmployeeOrCreate() {
    return !this.empleadoId || this.isOnlyEmployee()
  }

  /* CALENDARIOS */
  getCalendarios() {
    return this._calendariosService.getCalendarios().subscribe((data) => {
      this.calendarios = data
    })
  }

  displayFnCalendar(calendarioId) {
    if (this.calendarios) {
      var cal = this.calendarios.find(cal => cal.id == calendarioId)
      if (cal)
        return cal.nombre
    }
    else
      return ''
  }


  /* DEPARTAMENTOS */
  getDepartamentos() {
    this._trabajadoresService.getDepartamentos().subscribe((data) => {
      this.departamentos = data
    })
  }

  displayFnDept(dept: number) {
    var rtn = ''
    if (this.departamentos) {
      var depto = this.departamentos.find(dpt => dpt.id == dept)
      if (depto)
        rtn = depto.nombre
    }
    return rtn
  }

/* CENTROS DE TRABAJO */
  getCentrosTrabajo() {
    this._trabajadoresService.getCentrosTrabajo().subscribe((data) => {
      this.centrosTrabajo = data
    })
  }

  displayFnWorkSpace(workSpace: number) {
    var rtn = ''
    if (this.centrosTrabajo) {
      let centroTrabajo = this.centrosTrabajo.find(ct => ct.id == workSpace)
      if (centroTrabajo)
        rtn = centroTrabajo.nombre
    }
    return rtn
  }

  public toggleHours() {
    // Si tiene horario libre debe tener horas de jornada (lo que no tiene definido son horario de entrada y salida).
    let hLibre = this.trabajadorFormGroup.controls.horarioLibre
    let hJorn = this.trabajadorFormGroup.controls.horasJornada

    if (this.mensajeError)
      this.mensajeError = ''

    if (hLibre.value) {
      this.horasJornada = hJorn.value
      this.trabajadorFormGroup.patchValue({ horasJornada: 0 })
      hJorn.disable()
    } else {
      let value = this.horasJornada
      this.trabajadorFormGroup.patchValue({ horasJornada: value || 1 })
      hJorn.enable()
    }
  }

  public checkHours() {
    const hJorn = this.trabajadorFormGroup.controls.horasJornada
    const hLibre = this.trabajadorFormGroup.controls.horarioLibre
    this.horasJornada = hJorn.value
    if (this.mensajeError) {
      if (hJorn.value)
        this.mensajeError = ''
    }
    if ((hLibre.value && !hJorn.value) || hJorn.value > 16) {
      this.mensajeError = this.mensajeHorasJornada
    }
  }

  public forgotPassword() {
    this.fgtPwdIsDisabled = true
    this.mensajeError = `Se ha enviado un correo electrónico a ${this.empleado.mail}, diríjase a su correo y siga las instrucciones.`
    setInterval(() => { this.fgtPwdIsDisabled = false; this.mensajeError = '' }, 60000)
    this._accountProvider.forgotPassword(this.empleado.mail).subscribe((data) => {
      console.warn(data)
    })
    // Para que no haga un submit, devolvemos false.
    return false
  }

}
